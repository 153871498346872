import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import accountEndpoint from '../../../../config/service/endpoint/account';
import { Table } from '../../../../components/elements';
import { toRupiah } from '../../../../helpers';

const CardItem = ({
  title, value, variant, isLoading,
}) => (
  <div className="col-md-4">
    <div className={`card bg-${variant}`}>
      <div className="card-body">
        {isLoading ? (
          <>
            <Skeleton width={130} height={25} className="mb-2" />
            <Skeleton width={100} height={18} />
          </>
        ) : (
          <>
            <h4 className="text-light">{toRupiah(value)}</h4>
            <p className="text-light mb-0">{title}</p>
          </>
        )}
      </div>
    </div>
  </div>
);

CardItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  variant: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const CommissionPage = () => {
  const [searchParams] = useSearchParams();
  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { isFetching, data } = useQuery('profile', accountEndpoint.getProfile, { onError: (error) => toast.error(error.message) });
  const { isFetching: commissionLoading, data: commissionData } = useQuery('commissions', () => accountEndpoint.getCommissionAffiliate({ page: currentPage }), { onError: (error) => toast.error(error.message) });

  return (
    <>
      <div className="row mb-3">
        <CardItem
          title="Komisi Tertunda"
          value={data?.data?.unpaid_commission || 0}
          variant="danger"
          isLoading={isFetching}
        />
        <CardItem
          title="Komisi Terbayar"
          value={data?.data?.paid_commission || 0}
          variant="success"
          isLoading={isFetching}
        />
        <CardItem
          title="Semua Komisi"
          value={data?.data?.commission_amount || 0}
          variant="warning"
          isLoading={isFetching}
        />
      </div>
      <Table
        totalData={commissionData?.data?.meta?.total}
        isLoading={commissionLoading}
        headRender={(
          <tr>
            <th>Waktu</th>
            <th>Invoice</th>
            <th>Nama</th>
            <th>Produk</th>
            <th>Status</th>
            <th>Komisi</th>
          </tr>
        )}
        bodyRender={commissionData?.data?.commissions?.map((item) => (
          <tr key={item.id}>
            <td>{moment(item.date).format('DD MMM YYYY, H:m')}</td>
            <td>{item.invoice}</td>
            <td>{item.client_name}</td>
            <td>{item.product_name}</td>
            <td aria-label="status">
              <span className={`badge badge-${item.status === 'paid' ? 'success' : 'danger'}`}>
                {item.status === 'paid' ? 'Sudah Dibayar' : 'Belum Dibayar'}
              </span>
            </td>
            <td>{toRupiah(item.amount)}</td>
          </tr>
        ))}
      />
    </>
  );
};

export default CommissionPage;
