export const MENU_TEMPLATE_ORDER = [
  {
    type: 'welcome',
    name: 'Welcome',
    url: '/template-order/welcome',
  },
  {
    type: 'follow-up-1',
    name: 'Follow Up 1',
    url: '/template-order/follow-up-1',
  },
  {
    type: 'follow-up-2',
    name: 'Follow Up 2',
    url: '/template-order/follow-up-2',
  },
  {
    type: 'follow-up-3',
    name: 'Follow Up 3',
    url: '/template-order/follow-up-3',
  },
  {
    type: 'up-sell',
    name: 'Up Sell',
    url: '/template-order/up-sell',
  },
  {
    type: 'order-detail',
    name: 'Order Detail',
    url: '/template-order/order-detail',
  },
  {
    type: 'process',
    name: 'Process',
    url: '/template-order/process',
  },
  {
    type: 'complete',
    name: 'Complete',
    url: '/template-order/complete',
  },
  {
    type: 'cancel',
    name: 'Cancel',
    url: '/template-order/cancel',
  },
];
