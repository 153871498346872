import React from 'react';
import { Outlet } from 'react-router-dom';

import { getLocalStorage } from '../../helpers';

const PublicRoute = () => {
  const tokenStorage = getLocalStorage('token');
  if (tokenStorage) {
    const accessMenuStorage = getLocalStorage('token_access');
    const accessTypeStorage = getLocalStorage('token_type');
    const accessMenuList = accessMenuStorage ? JSON.parse(accessMenuStorage) : [];
    window.location.href = accessMenuList.includes('dashboard') ? '/dashboard' : `/${accessTypeStorage === 'general' ? accessMenuList.split(',')[0] : 'akun/profil'}`;
    return null;
  }
  return <Outlet />;
};

export default PublicRoute;
