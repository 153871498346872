import configApi from '../configApi';

const getAll = (params) => configApi({ endpoint: 'dashboard/order', params });
const getById = (id) => configApi({ endpoint: `dashboard/order/${id}` });
const insert = (payload) => configApi({ endpoint: 'dashboard/order', method: 'post', payload });
const followUp = (payload) => configApi({ endpoint: 'dashboard/order/follow-up', method: 'post', payload });
const changeStatus = (payload) => configApi({ endpoint: 'dashboard/order/status', method: 'put', payload });
const savePaymentProof = (payload) => configApi({ endpoint: 'dashboard/order/payment-proof', method: 'put', payload });
const deleteMultiple = (payload) => configApi({ endpoint: 'dashboard/order', method: 'delete', payload });

const endpoint = {
  getAll,
  getById,
  insert,
  followUp,
  changeStatus,
  savePaymentProof,
  deleteMultiple,
};

export default endpoint;
