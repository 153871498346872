export const TEMPLATE_NAME = {
  greeting: 'Greeting',
  welcome: 'Welcome',
  follow_up_1: 'Follow Up 1',
  follow_up_2: 'Follow Up 2',
  follow_up_3: 'Follow Up 3',
  complete: 'Complete',
  cancel: 'Cancel',
  up_sell: 'Up Sell',
  cross_sell: 'Cross Sell',
  rules_cod: 'Rules COD',
  form_order: 'Form Order',
  account_info: 'Info Rekening',
};

export const FONNTE_STATUS = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  SENT: 'sent',
  DELIVERED: 'delivered',
  READ: 'read',
};

export const ORDER_STATUS = {
  PENDING: 'pending',
  PROCESS: 'process',
  COMPLETE: 'complete',
  CANCEL: 'cancel',
  RETURN: 'return',
  UNPAID: 'unpaid',
  PAID: 'paid',
};

export const CLIENT_INVOICE_STATUS = {
  NEW: 'new',
  UNPAID: 'unpaid',
  PAID: 'paid',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
};

export const MENU_LIST = [
  {
    name: 'dashboard',
    label: 'Dashboard',
    icon: 'dashboard',
    link: '/dashboard',
  },
  {
    name: 'product',
    label: 'Product',
    icon: 'box',
    link: '/product',
  },
  {
    name: 'form',
    label: 'Form',
    icon: 'align-center-alt',
    link: '/form',
  },
  {
    name: 'order',
    label: 'Order',
    icon: 'shopping-basket',
    link: '/order',
  },
  {
    name: 'contact',
    label: 'Contact',
    icon: 'user-circle',
    link: '/contact',
  },
  {
    name: 'label',
    label: 'Label',
    icon: 'folder-check',
    link: '/label',
  },
  {
    name: 'template',
    label: 'Template',
    icon: 'clipboard',
    link: '/template',
  },
  {
    name: 'campaign',
    label: 'Campaign',
    icon: 'navigator',
    link: '/campaign',
  },
  // {
  //   name: 'auto-reply',
  //   label: 'Auto Reply',
  //   icon: 'comment-dots',
  //   link: '/auto-reply',
  // },
  {
    name: 'team-cs',
    label: 'Team CS',
    icon: 'link-alt',
    link: '/team-cs',
  },
  {
    name: 'report',
    label: 'Report',
    icon: 'chart',
    link: '/report',
  },
];

export const TOP_BAR_MENU = [
  {
    name: 'profile',
    label: 'Profil',
    icon: 'user',
    link: '/akun/profil',
  },
  {
    name: 'template-order',
    label: 'Template Order',
    icon: 'file',
    link: '/template-order/welcome',
  },
  {
    name: 'invoice',
    label: 'Invoice',
    icon: 'notes',
    link: '/akun/invoice',
  },
  {
    name: 'affiliate',
    label: 'Affiliate',
    icon: 'link',
    link: '/akun/affiliate',
  },
  {
    name: 'bonus',
    label: 'Bonus',
    icon: 'gift',
    link: '/akun/bonus',
  },
];
