import React, { useEffect } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import StatusBadge from '../../components/StatusBadge';
import accountEndpoint from '../../../../config/service/endpoint/account';
import { Table } from '../../../../components/elements';
import { toRupiah } from '../../../../helpers';

const OrderPage = () => {
  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { data, isFetching, refetch } = useQuery(`order-affiliate-${currentPage}`, () => accountEndpoint.getOrderAffiliate({ page: currentPage }), {
    onError: (error) => toast.error(error.message),
  });

  useEffect(() => {
    refetch();
  }, [searchParams]);

  return (
    <Table
      totalData={data?.data?.meta?.total}
      isLoading={isFetching}
      headRender={(
        <tr>
          <th>Waktu</th>
          <th>Invoice</th>
          <th>Nama</th>
          <th>Email</th>
          <th>No WhatsApp</th>
          <th>Produk</th>
          <th>Status</th>
          <th>Nominal</th>
        </tr>
      )}
      bodyRender={data?.data?.orders.map((item) => (
        <tr key={item.id}>
          <td>{moment(item.date).format('DD MMM YYYY')}</td>
          <td>{item.invoice}</td>
          <td>{item.client_name}</td>
          <td>{item.client_email}</td>
          <td>{item.client_whatsapp}</td>
          <td>{item.product_name}</td>
          <td aria-label="status"><StatusBadge status={item.status} /></td>
          <td>{toRupiah(item.total_bill)}</td>
        </tr>
      ))}
    />
  );
};

export default OrderPage;
