import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import FormModal from './components/FormModal';
import labelEndpoint from '../../config/service/endpoint/label';
import { Button, Checkbox } from '../../components/core';
import { AlertDialog, Table } from '../../components/elements';

const LabelPage = () => {
  const [openedConfirmDelete, setOpenedConfirmDelete] = useState(false);
  const [openedFormModal, setOpenedFormModal] = useState(false);
  const [labelDelete, setLabelDelete] = useState(0);
  const [selectedData, setSelectedData] = useState([]);
  const [isCheckAll, setCheckAll] = useState(false);
  const [labelEditData, setLabelEditData] = useState({
    id: '',
    name: '',
    color: '#000000',
  });

  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { isFetching, data: labels, refetch } = useQuery('labels', () => labelEndpoint.getAll({ page: currentPage }), {
    onError: (error) => toast.error(error.message),
  });

  const { mutate: deleteMutate, isLoading: isLoadingDelete } = useMutation(labelEndpoint.deleteMultiple, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Label berhasil dihapus.');
      setLabelDelete(0);
      setSelectedData([]);
      setOpenedConfirmDelete(false);
      refetch();
    },
  });

  useEffect(() => {
    refetch();
    setCheckAll(false);
    setSelectedData([]);
  }, [searchParams]);

  const handleCheck = (id) => {
    const isExist = selectedData.some((selected) => selected === id);
    if (isExist) {
      setSelectedData((prevState) => prevState.filter((selected) => selected !== id));
    } else {
      setSelectedData((prevState) => ([
        ...prevState,
        id,
      ]));
    }
  };

  useEffect(() => {
    setCheckAll(selectedData.length === labels?.data?.labels?.length);
  }, [selectedData]);

  const handleCheckAll = () => {
    if (isCheckAll) {
      setCheckAll(false);
      setSelectedData([]);
    } else {
      const newLabels = labels?.data?.labels?.map((label) => label.id);
      setSelectedData(newLabels);
    }
  };

  const handleClickDeleteButton = () => {
    if (selectedData.length < 1) {
      toast.error('Pilih minimal 1 data yang akan dihapus.');
      return;
    }
    setOpenedConfirmDelete(true);
  };

  return (
    <>
      <h4 className="mb-4">Label</h4>
      <div className="card">
        <div className="card-header">
          <Button
            className="mr-2 px-4"
            onClick={() => {
              setOpenedFormModal(true);
              setLabelEditData({
                id: '',
                name: '',
                color: '#000000',
              });
            }}
          >
            Tambah
          </Button>
          <Button variant="danger" onClick={handleClickDeleteButton}>
            <i className="uil-trash-alt" />
          </Button>
        </div>
        <div className="card-body">
          <Table
            totalData={labels?.data?.meta?.total}
            isLoading={isFetching}
            headRender={(
              <tr>
                <th aria-label="row" width="30px">
                  <Checkbox
                    id="checkAll"
                    checked={isCheckAll}
                    onChange={handleCheckAll}
                  />
                </th>
                <th>Nama Label</th>
                <th>Warna</th>
                <th>Jumlah Kontak</th>
                <th>Action</th>
              </tr>
            )}
            bodyRender={labels?.data?.labels?.map((label) => (
              <tr key={label.id}>
                <td aria-label="row">
                  <Checkbox
                    id={`checkItem-${label.id}`}
                    checked={selectedData.includes(label.id)}
                    onChange={() => handleCheck(label.id)}
                  />
                </td>
                <td>{label.name}</td>
                <td aria-label="color">
                  <div
                    style={{
                      width: 25,
                      height: 25,
                      borderRadius: '50%',
                      backgroundColor: `${label.color}`,
                    }}
                  />
                </td>
                <td>{label.total_contact}</td>
                <td width={100} aria-label="action">
                  <Button
                    variant="success"
                    size="sm"
                    className="mr-1 mb-1"
                    onClick={() => {
                      setOpenedFormModal(true);
                      setLabelEditData({
                        id: label.id,
                        name: label.name,
                        color: label.color,
                      });
                    }}
                  >
                    <i className="uil-pen" />
                  </Button>
                  <Button variant="danger" size="sm" className="mb-1" onClick={() => setLabelDelete(label.id)}>
                    <i className="uil-trash-alt" />
                  </Button>
                </td>
              </tr>
            ))}
          />
        </div>
      </div>
      <AlertDialog
        isDanger
        show={!!labelDelete || openedConfirmDelete}
        title="Hapus Label?"
        description={labelDelete ? 'Apakah kamu yakin ingin menghapus label ini?' : 'Apakah kamu yakin ingin menghapus label yang dipilih?'}
        labelCancel="Batal"
        labelConfirm="Ya, Hapus"
        isLoadingConfirm={isLoadingDelete}
        onClose={() => {
          setLabelDelete(0);
          setOpenedConfirmDelete(false);
        }}
        onConfirm={() => {
          deleteMutate({ ids: labelDelete ? [labelDelete] : selectedData });
        }}
      />
      <FormModal
        initialValues={labelEditData}
        show={openedFormModal}
        onClose={() => setOpenedFormModal(false)}
        onFetch={refetch}
      />
    </>
  );
};

export default LabelPage;
