import configApi from '../configApi';

const getAll = () => configApi({ endpoint: 'dashboard/template-order' });
const getByType = (type) => configApi({ endpoint: `dashboard/template-order/${type}` });
const update = (payload) => configApi({ endpoint: 'dashboard/template-order', method: 'put', payload });

const endpoint = {
  getAll,
  getByType,
  update,
};

export default endpoint;
