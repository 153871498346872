import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import PublicRoute from './PublicRoute';
import ProtectedRoute from './ProtectedRoute';
import ActiveAccountRoute from './ActiveAccountRoute';
import AccessMenu from './AccessMenu';
import {
  AccountAffiliatePage,
  AccountChangePasswordPage,
  AccountGiftPage,
  AccountInvoicePage,
  AccountProfilePage,
  AddFormPage,
  AddProductPage,
  AddTeamCSPage,
  AutoReplyPage,
  CampaignPage,
  ContactPage,
  CreateCampaignPage,
  CreateOrderPage,
  CreateTemplatePage,
  DashboardPage,
  DetailContactPage,
  DetailTemplatePage,
  EditFormPage,
  EditProductPage,
  EditTeamCSPage,
  EditTemplatePage,
  Error404,
  ForgotPasswordPage,
  FormPage,
  LabelPage,
  LoginPage,
  OrderDetailPage,
  OrderPage,
  ProductPage,
  RedirectToPortalWithAffiliate,
  RegisterAffiliatePage,
  ReportPage,
  TeamCSPage,
  TemplateOrderPage,
  TemplatePage,
} from '../../views';

const router = createBrowserRouter([
  {
    element: <PublicRoute />,
    children: [
      {
        path: '/',
        element: <LoginPage />,
      },
      {
        path: '/login/cs',
        element: <LoginPage isCS />,
      },
      {
        path: '/forgot-password',
        element: <ForgotPasswordPage />,
      },
      {
        path: '/register/affiliate',
        element: <RegisterAffiliatePage />,
      },
    ],
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        element: <ActiveAccountRoute />,
        children: [
          {
            path: '/dashboard',
            element: <AccessMenu pageName="dashboard" Component={DashboardPage} />,
          },
          {
            path: '/product',
            element: <AccessMenu pageName="product" Component={ProductPage} />,
          },
          {
            path: '/product/add',
            element: <AccessMenu pageName="product" Component={AddProductPage} />,
          },
          {
            path: '/product/:id',
            element: <AccessMenu pageName="product" Component={EditProductPage} />,
          },
          {
            path: '/form',
            element: <AccessMenu pageName="form" Component={FormPage} />,
          },
          {
            path: '/form/add',
            element: <AccessMenu pageName="form" Component={AddFormPage} />,
          },
          {
            path: '/form/:id',
            element: <AccessMenu pageName="form" Component={EditFormPage} />,
          },
          {
            path: '/order',
            element: <AccessMenu pageName="order" Component={OrderPage} />,
          },
          {
            path: '/order/create',
            element: <AccessMenu pageName="order" Component={CreateOrderPage} />,
          },
          {
            path: '/order/:id',
            element: <AccessMenu pageName="order" Component={OrderDetailPage} />,
          },
          {
            path: '/contact',
            element: <AccessMenu pageName="contact" Component={ContactPage} />,
          },
          {
            path: '/contact/:id',
            element: <AccessMenu pageName="contact" Component={DetailContactPage} />,
          },
          {
            path: '/label',
            element: <AccessMenu pageName="label" Component={LabelPage} />,
          },
          {
            path: '/template',
            element: <AccessMenu pageName="template" Component={TemplatePage} />,
          },
          {
            path: '/template/create',
            element: <AccessMenu pageName="template" Component={CreateTemplatePage} />,
          },
          {
            path: '/template/edit/:id',
            element: <AccessMenu pageName="template" Component={EditTemplatePage} />,
          },
          {
            path: '/template/:id',
            element: <AccessMenu pageName="template" Component={DetailTemplatePage} />,
          },
          {
            path: '/campaign',
            element: <AccessMenu pageName="campaign" Component={CampaignPage} />,
          },
          {
            path: '/campaign/create',
            element: <AccessMenu pageName="campaign" Component={CreateCampaignPage} />,
          },
          {
            path: '/auto-reply',
            element: <AccessMenu pageName="auto-reply" Component={AutoReplyPage} />,
          },
          {
            path: '/team-cs',
            element: <AccessMenu pageName="team-cs" Component={TeamCSPage} />,
          },
          {
            path: '/team-cs/add',
            element: <AccessMenu pageName="team-cs" Component={AddTeamCSPage} />,
          },
          {
            path: '/team-cs/:id',
            element: <AccessMenu pageName="team-cs" Component={EditTeamCSPage} />,
          },
          {
            path: '/report',
            element: <AccessMenu pageName="report" Component={ReportPage} />,
          },
          {
            path: '/template-order',
            element: <AccessMenu pageName="template-order" Component={TemplateOrderPage} />,
          },
          {
            path: '/template-order/:type',
            element: <AccessMenu pageName="template-order" Component={TemplateOrderPage} />,
          },
        ],
      },
      {
        path: '/akun/bonus',
        element: <AccessMenu pageName="bonus" Component={AccountGiftPage} />,
      },
      {
        path: '/akun/profil',
        element: <AccessMenu pageName="profile" Component={AccountProfilePage} />,
      },
      {
        path: '/akun/ubah-password',
        element: <AccountChangePasswordPage />,
      },
      {
        path: '/akun/invoice',
        element: <AccessMenu pageName="invoice" Component={AccountInvoicePage} />,
      },
      {
        path: '/akun/affiliate',
        element: <Navigate to="/akun/affiliate/link" />,
      },
      {
        path: '/akun/affiliate/:type',
        element: <AccessMenu pageName="affiliate" Component={AccountAffiliatePage} />,
      },
    ],
  },
  {
    path: '/aff/:code',
    element: <RedirectToPortalWithAffiliate />,
  },
  {
    path: '*',
    element: <Error404 />,
  },
]);

export default router;
