import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Link, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import Status from './component/Status';
import campaignEndpoint from '../../config/service/endpoint/campaign';
import { Button, Checkbox } from '../../components/core';
import { AlertDialog, BadgeLabel, Table } from '../../components/elements';

const CampaignPage = () => {
  const [openedConfirmDelete, setOpenedConfirmDelete] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [isCheckAll, setCheckAll] = useState(false);

  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { isFetching, data: campaigns, refetch } = useQuery('campaigns', () => campaignEndpoint.getAll({ page: currentPage }), {
    onError: (error) => toast.error(error.message),
  });

  const { mutate: deleteMutate, isLoading: isLoadingDelete } = useMutation(campaignEndpoint.deleteMultiple, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Campaign berhasil dihapus.');
      setSelectedData([]);
      setOpenedConfirmDelete(false);
      refetch();
    },
  });

  useEffect(() => {
    refetch();
    setCheckAll(false);
    setSelectedData([]);
  }, [searchParams]);

  const handleCheck = (id) => {
    const isExist = selectedData.some((selected) => selected === id);
    if (isExist) {
      setSelectedData((prevState) => prevState.filter((selected) => selected !== id));
    } else {
      setSelectedData((prevState) => ([
        ...prevState,
        id,
      ]));
    }
  };

  useEffect(() => {
    setCheckAll(selectedData.length === campaigns?.data?.campaigns?.length);
  }, [selectedData]);

  const handleCheckAll = () => {
    if (isCheckAll) {
      setCheckAll(false);
      setSelectedData([]);
    } else {
      const newCampaigns = campaigns?.data?.campaigns?.map((campaign) => campaign.id);
      setSelectedData(newCampaigns);
    }
  };

  const handleClickDeleteButton = () => {
    if (selectedData.length < 1) {
      toast.error('Pilih minimal 1 data yang akan dihapus.');
      return;
    }
    setOpenedConfirmDelete(true);
  };

  return (
    <>
      <h4 className="mb-4">Campaign</h4>
      <div className="card">
        <div className="card-header">
          <Link to="/campaign/create">
            <Button className="mr-2">Buat Kampanye</Button>
          </Link>
          <Button variant="danger" onClick={handleClickDeleteButton}>
            <i className="uil-trash-alt" />
          </Button>
        </div>
        <div className="card-body">
          <Table
            totalData={campaigns?.data?.meta?.total}
            isLoading={isFetching}
            headRender={(
              <tr>
                <th aria-label="row" width="30px">
                  <Checkbox
                    id="checkAll"
                    checked={isCheckAll}
                    onChange={handleCheckAll}
                  />
                </th>
                <th>Kampanye</th>
                <th>Waktu</th>
                <th>Device</th>
                <th>Label</th>
                <th>Jumlah Kontak</th>
                <th>Status</th>
              </tr>
            )}
            bodyRender={campaigns?.data?.campaigns.map((campaign) => (
              <tr key={campaign.id}>
                <td aria-label="row">
                  <Checkbox
                    id={`checkItem-${campaign.id}`}
                    checked={selectedData.includes(campaign.id)}
                    onChange={() => handleCheck(campaign.id)}
                  />
                </td>
                <td>{campaign.name}</td>
                <td>{moment(campaign.schedule).format('DD-MM-YYYY | HH:mm')}</td>
                <td>{campaign.cs_name}</td>
                <td>
                  {campaign.labels.map((label) => (
                    <BadgeLabel key={label.id} label={label.name} color={label.color} className="mr-1" />
                  ))}
                </td>
                <td>{campaign.total_contact}</td>
                <td width={100} aria-label="action">
                  <Status status={campaign.status} />
                </td>
              </tr>
            ))}
          />
        </div>
      </div>
      <AlertDialog
        isDanger
        show={openedConfirmDelete}
        title="Hapus Campaign?"
        description="Apakah kamu yakin ingin menghapus label yang dipilih?"
        labelCancel="Batal"
        labelConfirm="Ya, Hapus"
        isLoadingConfirm={isLoadingDelete}
        onClose={() => setOpenedConfirmDelete(false)}
        onConfirm={() => deleteMutate({ ids: selectedData })}
      />
    </>
  );
};

export default CampaignPage;
