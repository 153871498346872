import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useMutation } from 'react-query';
import {
  BtnBold, BtnItalic, BtnStrikeThrough, EditorProvider, Editor, Toolbar,
} from 'react-simple-wysiwyg';

import orderEndpoint from '../../../config/service/endpoint/order';
import { Modal } from '../../../components/elements';
import { Button, InputField } from '../../../components/core';

const validationSchema = yup.object({
  name: yup.string().required('Nama tidak boleh kosong'),
  whatsapp: yup.string().required('No WhatsApp tidak boleh kosong'),
  message: yup.string().required('Pesan tidak boleh kosong'),
});

const FollowUpModal = ({
  orderId, step, type, contactName, contactWhatsApp, show, templateOrders, onClose, onSend,
}) => {
  const { mutate: followUpMutation, isLoading } = useMutation(orderEndpoint.followUp, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil melakukan follow up.');
      onSend();
      onClose();
    },
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      whatsapp: '',
      message: '',
    },
    validationSchema,
    onSubmit: (values) => {
      followUpMutation({
        id: orderId,
        name: values.name,
        whatsapp: values.whatsapp,
        message: values.message,
        step,
      });
    },
  });

  useEffect(() => {
    if (show) {
      const selectedTemplate = templateOrders.find((template) => template.type === type);
      formik.resetForm();
      formik.setValues({
        name: contactName,
        whatsapp: contactWhatsApp,
        message: selectedTemplate ? selectedTemplate.content : '',
      });
    }
  }, [show]);

  return (
    <Modal
      show={show}
      title="Follow Up"
      onClose={onClose}
    >
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <InputField
                  name="name"
                  id="name"
                  placeholder="Name"
                  value={formik.values.name}
                  error={formik.touched.name && formik.errors.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <InputField
                  name="whatsapp"
                  id="whatsapp"
                  placeholder="No WhatsApp"
                  value={formik.values.whatsapp}
                  error={formik.touched.whatsapp && formik.errors.whatsapp}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <EditorProvider>
                  <Editor
                    name="message"
                    value={formik.values.message}
                    containerProps={{ style: { minHeight: 200 } }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                      <BtnStrikeThrough />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
                {formik.touched.message && formik.errors.message && (
                  <small className="text-danger d-block">{formik.errors.message}</small>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button isLoading={isLoading} type="submit" className="px-4">Kirim</Button>
          </div>
        </form>
      </FormikProvider>
    </Modal>
  );
};

FollowUpModal.propTypes = {
  orderId: PropTypes.string,
  step: PropTypes.number,
  type: PropTypes.string,
  contactName: PropTypes.string,
  contactWhatsApp: PropTypes.string,
  show: PropTypes.bool,
  templateOrders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    content: PropTypes.string,
  })),
  onClose: PropTypes.func,
  onSend: PropTypes.func,
};

FollowUpModal.defaultProps = {
  orderId: '',
  step: 0,
  type: '',
  contactName: '',
  contactWhatsApp: '',
  show: false,
  templateOrders: [],
  onClose: () => {},
  onSend: () => {},
};

export default FollowUpModal;
