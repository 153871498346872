import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Link, useSearchParams } from 'react-router-dom';
import { useDebounce } from '@uidotdev/usehooks';
import { Alert, OverlayTrigger } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { utils as XLSXUtils, writeFile as XLSXWriteFile } from 'xlsx';

import OrderStatus from './components/OrderStatus';
import FollowUpModal from './components/FollowUpModal';
import FilterDateDropdown from './components/FilterDateDropdown';
import orderEndpoint from '../../config/service/endpoint/order';
import templateOrderEndpoint from '../../config/service/endpoint/templateOrder';
import { Button, Checkbox, InputField } from '../../components/core';
import { AlertDialog, Dropdown, Table } from '../../components/elements';
import { toRupiah } from '../../helpers';
import { followUpPopoverInfo, statusPopoverInfo } from './components/PopoverInfo';

const statusOptions = [
  { value: 'pending', label: 'Pending' },
  { value: 'process', label: 'Process' },
  { value: 'complete', label: 'Complete' },
  { value: 'cancel', label: 'Cancel' },
  { value: 'return', label: 'Return' },
  { value: 'paid', label: 'Paid' },
  { value: 'unpaid', label: 'Unpaid' },
];

const otherFollowUpOptions = [
  { label: 'Up Sell', value: 'up-sell' },
  { label: 'Order Detail', value: 'order-detail' },
  { label: 'Process', value: 'process' },
  { label: 'Complete', value: 'complete' },
  { label: 'Cancel', value: 'cancel' },
];

const getButtonVariant = (followUpStep, step) => {
  if (followUpStep >= step) return 'success';
  if (followUpStep === step - 1) return 'warning';
  return 'secondary';
};

const OrderPage = () => {
  const [openedConfirmDelete, setOpenedConfirmDelete] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isCheckAll, setCheckAll] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [changeStatusType, setChangeStatusType] = useState(null);
  const [filterStatus, setFilterStatus] = useState('');
  const [followUpModal, setFollowUpModal] = useState(null);
  const [filterStatusExport, setFilterStatusExport] = useState('');
  const [dateFilter, setDateFilter] = useState({
    startDate: '',
    endDate: '',
  });
  const [dateFilterExport, setDateFilterExport] = useState({
    startDate: '',
    endDate: '',
  });

  const debouncedSearch = useDebounce(searchValue, 500);
  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { isFetching, data: orders, refetch } = useQuery('orders', () => orderEndpoint.getAll({
    page: currentPage, search: searchValue, status: filterStatus, start_date: dateFilter.startDate, end_date: dateFilter.endDate,
  }), {
    onError: (error) => toast.error(error.message),
    onSuccess: () => setLoadingSearch(false),
  });

  const { isFetching: exportLoading, refetch: exportRefetch } = useQuery('export-orders', () => orderEndpoint.getAll({
    is_export: true, status: filterStatusExport, start_date: dateFilterExport.startDate, end_date: dateFilterExport.endDate,
  }), {
    enabled: false,
    onError: (error) => toast.error(error.message),
    onSuccess: (_data) => {
      if (_data && _data.data.length < 1) {
        toast.error('Data order kosong.');
        return;
      }
      const workbook = XLSXUtils.book_new();
      const worksheet = XLSXUtils.json_to_sheet(_data.data);
      XLSXUtils.book_append_sheet(workbook, worksheet, 'Orders');
      XLSXWriteFile(workbook, 'orders.xlsx');
      toast.success('Data order berhasil diexport.');
    },
  });

  const { data: templateOrders } = useQuery('template-orders', templateOrderEndpoint.getAll, {
    onError: (error) => toast.error(error.message),
  });

  const { mutate: deleteMutate, isLoading: isLoadingDelete } = useMutation(orderEndpoint.deleteMultiple, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Order berhasil dihapus.');
      setSelectedData([]);
      setOpenedConfirmDelete(false);
      refetch();
    },
  });

  const { mutate: changeStatusMutate, isLoading: isLoadingChangeStatus } = useMutation(orderEndpoint.changeStatus, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil mengubah status order.');
      setSelectedData([]);
      setChangeStatusType(null);
      refetch();
    },
  });

  useEffect(() => {
    refetch();
    setCheckAll(false);
    setSelectedData([]);
  }, [searchParams, filterStatus]);

  useEffect(() => {
    refetch();
  }, [debouncedSearch, dateFilter]);

  useEffect(() => {
    setCheckAll((selectedData.length === orders?.data?.orders?.length));
  }, [selectedData]);

  const handleCheckAll = () => {
    if (isCheckAll) {
      setCheckAll(false);
      setSelectedData([]);
    } else {
      const newOrders = orders?.data?.orders.map((order) => order.id);
      setSelectedData(newOrders);
    }
  };

  const handleCheck = (id) => {
    const isExist = selectedData.some((selected) => selected === id);
    if (isExist) {
      setSelectedData((prevState) => prevState.filter((selected) => selected !== id));
    } else {
      setSelectedData((prevState) => ([
        ...prevState,
        id,
      ]));
    }
  };

  const handleClickDeleteButton = () => {
    if (selectedData.length < 1) {
      toast.error('Pilih minimal 1 data yang akan dihapus.');
      return;
    }
    setOpenedConfirmDelete(true);
  };

  const renderButton = (variant, text, order, step, type) => (
    <Button
      onClick={() => (order && (variant === 'warning' || step === 1) ? setFollowUpModal({ ...order, step, type }) : undefined)}
      variant={variant}
      size="sm"
      style={{ cursor: order && (variant === 'warning' || step === 1) ? 'pointer' : 'not-allowed' }}
    >
      {text}
    </Button>
  );

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h4 className="mb-0">Order</h4>
        <Link to="/order/create">
          <Button className="px-4">
            Tambah
          </Button>
        </Link>
      </div>
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center flex-action-button">
          <div className="d-flex flex-action-button" style={{ gap: 10 }}>
            <Dropdown
              label="Status Action"
              buttonVariant="info"
              buttonDisabled={selectedData.length <= 0}
              data={statusOptions}
              onChoose={setChangeStatusType}
            />
            <Button disabled={selectedData.length <= 0} variant="danger" onClick={handleClickDeleteButton}>
              <i className="uil-trash-alt" />
            </Button>
          </div>
          <div className="d-flex flex-action-button" style={{ gap: 10 }}>
            <InputField
              placeholder="Search"
              classNameContainer="mb-0"
              value={searchValue}
              onChange={(event) => {
                setSearchValue(event.target.value);
                setLoadingSearch(true);
              }}
            />
            <FilterDateDropdown
              onApply={(_date) => {
                setDateFilter(_date);
                setDateFilterExport(_date);
              }}
            />
            <Dropdown
              label="Filter Status"
              buttonVariant="info"
              data={statusOptions}
              onChoose={(value) => {
                setFilterStatus(value);
                setFilterStatusExport(value);
              }}
            />
            <Button
              variant="success"
              className="px-3"
              isLoading={exportLoading}
              onClick={() => exportRefetch()}
            >
              Export
            </Button>
          </div>
        </div>
        <div className="card-body">
          {filterStatus && (
            <Alert variant="info" className="d-flex align-items-center justify-content-between">
              <div>
                Filter Status:
                {' '}
                <strong>{filterStatus}</strong>
              </div>
              <Button
                variant="danger"
                onClick={() => {
                  setFilterStatus('');
                  setFilterStatusExport('');
                }}
              >
                Reset
              </Button>
            </Alert>
          )}
          <Table
            totalData={orders?.data?.meta?.total}
            isLoading={isFetching || loadingSearch}
            headRender={(
              <tr>
                <th aria-label="row" width="30px">
                  <Checkbox
                    id="checkAll"
                    checked={isCheckAll}
                    onChange={handleCheckAll}
                  />
                </th>
                <th>ID</th>
                <th>Nama</th>
                <th>Harga</th>
                <th>CS</th>
                <th>Pembayaran</th>
                <th>
                  <div className="d-flex align-items-center">
                    <span className="mr-1">Status</span>
                    <OverlayTrigger trigger="hover" placement="auto" overlay={statusPopoverInfo}>
                      <i className="bx bx-info-circle cursor-pointer" />
                    </OverlayTrigger>
                  </div>
                </th>
                <th>
                  <div className="d-flex align-items-center">
                    <span className="mr-1">Follow Up</span>
                    <OverlayTrigger trigger="hover" placement="auto" overlay={followUpPopoverInfo}>
                      <i className="bx bx-info-circle cursor-pointer" />
                    </OverlayTrigger>
                  </div>
                </th>
                <th>Action</th>
              </tr>
            )}
            bodyRender={orders?.data?.orders?.map((order) => (
              <tr key={order.id}>
                <td aria-label="row">
                  <Checkbox
                    id={`checkItem-${order.id}`}
                    checked={selectedData.includes(order.id)}
                    onChange={() => handleCheck(order.id)}
                  />
                </td>
                <td>
                  <p className="mb-1">{order.invoice_id}</p>
                  <small>{moment(order.date).format('DD MMM YYYY')}</small>
                </td>
                <td>
                  <p className="mb-1">{order.contact_name}</p>
                  <small>{order.contact_whatsapp}</small>
                </td>
                <td>{toRupiah(order.price)}</td>
                <td>{order.cs_name}</td>
                <td>{order.payment === 'transfer' ? 'Transfer' : 'COD'}</td>
                <td aria-label="status">
                  <OrderStatus status={order.order_status} />
                  <br />
                  <OrderStatus status={order.payment_status} />
                </td>
                <td className="d-flex" style={{ gap: 5 }}>
                  {renderButton(getButtonVariant(order.follow_up_step, 0), 'W', order, 0, 'welcome')}
                  {renderButton(getButtonVariant(order.follow_up_step, 1), '1', order, 1, 'follow-up-1')}
                  {renderButton(getButtonVariant(order.follow_up_step, 2), '2', order, 2, 'follow-up-2')}
                  {renderButton(getButtonVariant(order.follow_up_step, 3), '3', order, 3, 'follow-up-3')}
                  <Dropdown
                    buttonSize="sm"
                    buttonVariant="secondary"
                    label="..."
                    style={{ padding: '4px 8px' }}
                    onChoose={(value) => setFollowUpModal({ ...order, step: value, type: value })}
                    data={otherFollowUpOptions.map((item) => {
                      const isSelected = order.other_follow_up.includes(item.value);
                      return {
                        ...item,
                        selected: isSelected,
                      };
                    })}
                  />
                </td>
                <td width={100} aria-label="action">
                  <Link to={`/order/${order.id}`}>
                    <Button size="sm" className="px-3">
                      Detail
                    </Button>
                  </Link>
                </td>
              </tr>
            ))}
          />
        </div>
      </div>
      <AlertDialog
        isDanger
        show={openedConfirmDelete}
        title="Hapus Order?"
        description="Apakah kamu yakin ingin menghapus order yang dipilih?"
        labelCancel="Batal"
        labelConfirm="Ya, Hapus"
        isLoadingConfirm={isLoadingDelete}
        onClose={() => setOpenedConfirmDelete(false)}
        onConfirm={() => deleteMutate({ ids: selectedData })}
      />
      <AlertDialog
        isDanger
        show={!!changeStatusType}
        title="Proses Order?"
        description={`Apakah kamu yakin ingin mengubah status ${changeStatusType === 'paid' || changeStatusType === 'unpaid' ? 'pembayaran' : 'order'} menjadi ${changeStatusType}?`}
        labelCancel="Batal"
        labelConfirm="Ya, Ubah"
        isLoadingConfirm={isLoadingChangeStatus}
        onClose={() => setChangeStatusType(null)}
        onConfirm={() => changeStatusMutate({ status: changeStatusType, ids: selectedData })}
      />
      <FollowUpModal
        orderId={followUpModal?.id}
        step={followUpModal?.step}
        type={followUpModal?.type}
        contactName={followUpModal?.contact_name}
        contactWhatsApp={followUpModal?.contact_whatsapp}
        show={!!followUpModal}
        templateOrders={templateOrders?.data}
        onClose={() => setFollowUpModal(null)}
        onSend={refetch}
      />
    </>
  );
};

export default OrderPage;
