import React from 'react';
import Skeleton from 'react-loading-skeleton';

const DetailLoadingSkeleton = () => (
  <>
    <Skeleton width={100} height={30} className="mb-4" />
    <div className="row">
      <div className="col-md-8">
        <div className="card">
          <div className="card-body">
            <Skeleton width={120} height={25} />
            <Skeleton height={1} />
            <div className="d-flex align-items-center mb-3">
              <Skeleton width={70} height={20} className="mr-4" />
              <Skeleton width={120} height={20} />
            </div>
            <div className="d-flex align-items-center mb-3">
              <Skeleton width={70} height={20} className="mr-4" />
              <Skeleton width={120} height={20} />
            </div>
            <div className="d-flex align-items-center mb-3">
              <Skeleton width={70} height={20} className="mr-4" />
              <Skeleton width={120} height={20} />
            </div>
            <div className="d-flex align-items-center mb-3">
              <Skeleton width={70} height={20} className="mr-4" />
              <Skeleton width={120} height={20} />
            </div>
            <div className="d-flex justify-content-end">
              <Skeleton width={100} height={40} />
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <Skeleton width={120} height={25} />
            <Skeleton height={1} />
            <Skeleton count={3} />
            <div className="d-flex justify-content-end mt-3">
              <Skeleton width={100} height={40} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card">
          <div className="card-body">
            <Skeleton width={80} height={25} />
            <Skeleton height={1} />
            <Skeleton width={120} height={20} className="mb-1" />
            <Skeleton width={150} height={18} />
            <Skeleton width={120} height={20} className="mb-1 mt-3" />
            <Skeleton width={150} height={18} />
            <Skeleton width={120} height={20} className="mb-1 mt-3" />
            <Skeleton width={150} height={18} />
            <Skeleton width={120} height={20} className="mb-1 mt-3" />
            <Skeleton width={150} height={18} />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default DetailLoadingSkeleton;
