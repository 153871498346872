import React from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';

import Form from './components/Form';
import productEndpoint from '../../config/service/endpoint/product';
import { Button } from '../../components/core';

const AddProductPage = () => {
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(productEndpoint.insert, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil menambahkan produk.');
      navigate('/product');
    },
  });

  const handleInsert = (values) => {
    mutate({
      product_name: values.product_name,
      category_id: values.category_id,
      price: values.price,
      sku: values.sku,
    });
  };

  return (
    <>
      <h4 className="mb-4">Product | Tambah Produk</h4>
      <div className="card">
        <div className="card-header">
          <Link to="/product">
            <Button variant="secondary">
              <i className="uil-arrow-left mr-1" />
              Kembali
            </Button>
          </Link>
        </div>
        <div className="card-body">
          <Form
            onSubmit={handleInsert}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default AddProductPage;
