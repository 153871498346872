import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { Link } from 'react-router-dom';

import { MENU_LIST, TOP_BAR_MENU } from '../../../helpers/enums';
import {
  Button, Checkbox, InputField, Select,
} from '../../../components/core';

const Form = ({
  isLoading, initialValues, validationSchema, onSubmit,
}) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const newMenusList = [...MENU_LIST, ...TOP_BAR_MENU];

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <InputField
              id="name"
              name="name"
              label="Nama CS"
              value={formik.values.name}
              error={formik.touched.name && formik.errors.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-md-6">
            <InputField
              id="whatsapp"
              name="whatsapp"
              label="No WhatsApp"
              value={formik.values.whatsapp}
              error={formik.touched.whatsapp && formik.errors.whatsapp}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-md-6">
            <InputField
              id="email"
              name="email"
              readOnly={initialValues.email}
              label="Email"
              value={formik.values.email}
              error={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-md-6">
            <InputField
              id="password"
              name="password"
              label="Password"
              value={formik.values.password}
              placeholder={initialValues.name ? 'Kosongkan jika tidak ingin mengubah password' : ''}
              error={formik.touched.password && formik.errors.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-md-6">
            <Select
              id="wa_provider"
              name="wa_provider"
              label="WhatsApp Unofficial"
              placeholder="Pilih Layanan"
              data={[
                { value: 'fonnte', label: 'Fonnte - fonnte.com' },
              ]}
              value={formik.values.wa_provider}
              error={formik.touched.wa_provider && formik.errors.wa_provider}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-md-6">
            <InputField
              id="wa_api_key"
              name="wa_api_key"
              label="WhatsApp API"
              value={formik.values.wa_api_key}
              error={formik.touched.wa_api_key && formik.errors.wa_api_key}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className={`col-lg-6 ${formik.touched.access_menu && formik.errors.access_menu ? 'border border-danger' : ''}`}>
            <label>Akses Menu</label>
            <Checkbox
              id="menu_all"
              label="Pilih Semua"
              checked={formik.values.access_menu.length === newMenusList.length}
              onChange={(e) => {
                if (e.target.checked) {
                  formik.setFieldValue('access_menu', newMenusList.map((menu) => menu.name));
                } else {
                  formik.setFieldValue('access_menu', []);
                }
              }}
            />
            <div className="d-flex mt-2" style={{ flexWrap: 'wrap' }}>
              {newMenusList.map((menu) => (
                <Checkbox
                  key={menu.name}
                  id={menu.name}
                  label={menu.label}
                  className="mr-4 mb-2"
                  checked={formik.values.access_menu.includes(menu.name)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formik.setFieldValue('access_menu', [...formik.values.access_menu, menu.name]);
                    } else {
                      formik.setFieldValue('access_menu', formik.values.access_menu.filter((item) => item !== menu.name));
                    }
                  }}
                />
              ))}
            </div>
            {formik.touched.access_menu && formik.errors.access_menu && (
              <small className="text-danger">{formik.errors.access_menu}</small>
            )}
          </div>
        </div>
        <div className="d-flex mt-4">
          <Button isLoading={isLoading} type="submit" className="px-4 mr-2">SIMPAN</Button>
          <Link to="/team-cs">
            <Button variant="secondary" className="px-4 mr-2">BATAL</Button>
          </Link>
        </div>
      </form>
    </FormikProvider>
  );
};

Form.propTypes = {
  isLoading: PropTypes.bool,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    whatsapp: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
  }),
  validationSchema: PropTypes.instanceOf(yup.Schema),
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  isLoading: false,
  initialValues: {
    name: '',
    whatsapp: '',
    email: '',
    password: '',
  },
  validationSchema: yup.object().shape({}),
  onSubmit: () => {},
};

export default Form;
