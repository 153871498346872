import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import QuantityAction from './QuantityAction';
import productEndpoint from '../../../../config/service/endpoint/product';
import { Modal, Table } from '../../../../components/elements';
import { Button, InputField } from '../../../../components/core';
import { toRupiah } from '../../../../helpers';

const AddProductModal = ({
  initSelected, show, onClose, onAddToCart,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedProduct, setSelectedProduct] = useState([]);

  const { data: products, isFetching } = useQuery('products', () => productEndpoint.getAll({ limit: 1000 }), {
    onError: (error) => toast.error(error.message),
  });

  const newProducts = useMemo(() => products?.data?.products?.map((product) => {
    const selectedExist = selectedProduct.find((_product) => _product.id === product.id);
    return {
      id: product.id,
      product_name: product.product_name,
      sku: product.sku,
      price: product.price,
      qty: selectedExist ? selectedExist.qty : 0,
    };
  }), [selectedProduct, products]);

  useEffect(() => {
    setSelectedProduct(initSelected);
  }, [initSelected]);

  useEffect(() => {
    setSelectedProduct(show ? initSelected : []);
    if (show) setSearchValue('');
  }, [show]);

  const handleIncreaseQty = (product) => {
    const existProduct = selectedProduct.find((_product) => _product.id === product.id);
    if (existProduct) {
      const newSelected = selectedProduct.map((_product) => {
        if (_product.id === product.id) {
          return { ..._product, qty: _product.qty + 1 };
        }
        return _product;
      });
      setSelectedProduct(newSelected);
    } else {
      setSelectedProduct((prevState) => ([
        ...prevState,
        {
          id: product.id,
          product_name: product.product_name,
          price: product.price,
          qty: 1,
        },
      ]));
    }
  };

  const handleDecrease = (product) => {
    if (product.qty === 1) {
      const newSelected = selectedProduct.filter((_product) => _product.id !== product.id);
      setSelectedProduct(newSelected);
      return;
    }
    const newSelected = selectedProduct.map((_product) => {
      if (_product.id === product.id) {
        return {
          ..._product,
          qty: _product.qty - 1,
        };
      }
      return _product;
    });
    setSelectedProduct(newSelected);
  };

  const filteredProducts = newProducts ? newProducts.filter((product) => product.product_name.toLowerCase().includes(searchValue.toLowerCase()) || product.sku.toLowerCase().includes(searchValue.toLowerCase())) : [];

  const totalItemSelected = selectedProduct.reduce((a, product) => a + product.qty, 0);

  const priceItemSelected = selectedProduct.reduce((a, product) => a + (product.price * product.qty), 0);

  return (
    <Modal
      show={show}
      title="Pilih Produk"
      onClose={onClose}
      size="lg"
    >
      <InputField
        placeholder="Cari produk"
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
      />
      <div style={{ maxHeight: 400, overflow: 'auto' }}>
        <Table
          totalData={filteredProducts.length}
          perPage={filteredProducts.length}
          isLoading={isFetching}
          headRender={(
            <tr>
              <th>Produk</th>
              <th>Harga</th>
              <th style={{ width: 120, textAlign: 'center' }}>Qty</th>
            </tr>
          )}
          bodyRender={filteredProducts.map((product) => (
            <tr key={product.id}>
              <td>
                {product.product_name}
                <br />
                {`SKU: ${product.sku}`}
              </td>
              <td>{toRupiah(product.price)}</td>
              <td aria-label="qty">
                <QuantityAction
                  quantity={product.qty}
                  onDecrease={() => handleDecrease(product)}
                  onIncrease={() => handleIncreaseQty(product)}
                />
              </td>
            </tr>
          ))}
        />
      </div>
      <Button
        disabled={totalItemSelected < 1}
        size="lg"
        className="w-100 mt-3"
        innerClassName="justify-content-between"
        onClick={() => {
          onAddToCart(selectedProduct);
          onClose();
        }}
      >
        <strong>{`${totalItemSelected} Item`}</strong>
        <strong>{`Total ${toRupiah(priceItemSelected)}`}</strong>
      </Button>
    </Modal>
  );
};

AddProductModal.propTypes = {
  initSelected: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    product_name: PropTypes.string,
    price: PropTypes.number,
    qty: PropTypes.number,
  })),
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onAddToCart: PropTypes.func,
};

AddProductModal.defaultProps = {
  initSelected: [],
  show: false,
  onClose: () => {},
  onAddToCart: () => {},
};

export default AddProductModal;
