import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { FieldArray, FormikProvider, useFormik } from 'formik';

import productEndpoint from '../../../config/service/endpoint/product';
import teamCSEndpoint from '../../../config/service/endpoint/teamCS';
import labelEndpoint from '../../../config/service/endpoint/label';
import {
  Button, InputField, Radio, Select, Textarea,
} from '../../../components/core';

const trackingOptions = [
  { value: 'meta', label: 'Meta Ads' },
  // { value: 'tiktok', label: 'Tiktok Ads' },
  { value: 'google', label: 'Google Ads' },
];

const checkForDuplicates = (array) => {
  const seen = {};
  const duplicates = [];
  array.forEach((item) => {
    const { cs_id: cs } = item;
    if (cs !== '' && seen[cs]) duplicates.push(cs);
    else seen[cs] = true;
  });
  return duplicates;
};

const validationSchema = yup.object({
  product_id: yup.string().required('Pilih produk terlebih dahulu.'),
  payment: yup.string().trim().required('Pilih pembayaran terlebih dahulu.'),
  message: yup.string().trim().required('Pesan harus diisi.'),
  label_id: yup.string().trim().required('Pilih label terlebih dahulu.'),
});

const Form = ({ initialValues, isLoading, onSubmit }) => {
  const { data: products, isLoading: productsLoading } = useQuery('products', () => productEndpoint.getAll({ limit: 1000 }), { onError: (error) => toast.error(error.message) });
  const { data: teamCS, isLoading: teamCSLoading } = useQuery('team-cs', () => teamCSEndpoint.getAll({ limit: 1000 }), { onError: (error) => toast.error(error.message) });
  const { data: labels, isLoading: labelLoading } = useQuery('labels', () => labelEndpoint.getAll({ limit: 1000 }), { onError: (error) => toast.error(error.message) });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const duplicateCs = checkForDuplicates(values.team_cs);
      if (duplicateCs.length > 0) {
        formik.setFieldError('team_cs', 'Terdapat customer service yang sama, silakan hapus salah satu.');
        return;
      }
      if (values.team_cs.some((cs) => !cs.cs_id)) {
        formik.setFieldError('team_cs', 'Customer service harus diisi.');
        return;
      }
      const payload = {
        product_id: values.product_id,
        payment: values.payment,
        message: values.message,
        tracking_type: values.tracking_type,
        tracking_id: values.tracking_id,
        type: values.type,
        distribution: values.distribution,
        team_cs: values.team_cs.filter((cs) => cs.cs_id !== '').map((cs) => ({
          cs_id: cs.cs_id,
          percentage: cs.percentage === '' ? 0 : cs.percentage,
        })),
        label_id: values.label_id,
      };
      onSubmit(payload);
    },
  });

  useEffect(() => {
    formik.setValues(initialValues);
  }, [initialValues]);

  const handleSetTypeDirect = () => {
    formik.setFieldValue('team_cs', [formik.values.team_cs[0]]);
    formik.setFieldValue('type', 'direct');
    formik.setFieldValue('distribution', 'equal');
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <Select
                  name="product_id"
                  id="product_id"
                  label="Pilih Produk"
                  placeholder={productsLoading ? 'Loading...' : '-- Pilih salah satu --'}
                  data={products?.data?.products?.map((product) => ({ label: product.product_name, value: product.id }))}
                  error={formik.touched.product_id && formik.errors.product_id}
                  value={formik.values.product_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className="form-group">
                  <label>Pembayaran</label>
                  <div className="d-flex" style={{ gap: 16 }}>
                    <Radio
                      name="payment"
                      id="paymentCOD"
                      label="COD"
                      checked={formik.values.payment === 'cod'}
                      onChange={() => formik.setFieldValue('payment', 'cod')}
                    />
                    <Radio
                      name="payment"
                      id="paymentTransfer"
                      label="Transfer"
                      checked={formik.values.payment === 'transfer'}
                      onChange={() => formik.setFieldValue('payment', 'transfer')}
                    />
                  </div>
                  {formik.touched.payment && formik.errors.payment && <small className="text-danger d-block mt-1">{formik.errors.payment}</small>}
                </div>
                <Textarea
                  name="message"
                  id="message"
                  label="Isi Pesan"
                  error={formik.touched.message && formik.errors.message}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <Select
                  name="tracking_type"
                  id="tracking_type"
                  label="Pilih Tracking"
                  placeholder="-- Pilih salah satu --"
                  data={trackingOptions}
                  error={formik.touched.tracking_type && formik.errors.tracking_type}
                  value={formik.values.tracking_type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <InputField
                  name="tracking_id"
                  id="tracking_id"
                  label="ID"
                  error={formik.touched.tracking_id && formik.errors.tracking_id}
                  value={formik.values.tracking_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <label>Tipe</label>
                  <div className="d-flex" style={{ gap: 16 }}>
                    <Radio
                      name="type"
                      id="typeDirect"
                      label="Direct"
                      checked={formik.values.type === 'direct'}
                      onChange={handleSetTypeDirect}
                    />
                    <Radio
                      name="type"
                      id="typeRotator"
                      label="Rotator"
                      checked={formik.values.type === 'rotator'}
                      onChange={() => formik.setFieldValue('type', 'rotator')}
                    />
                  </div>
                </div>
                {formik.values.type === 'rotator' && (
                  <div className="form-group">
                    <label>Distribusi</label>
                    <div className="d-flex" style={{ gap: 16 }}>
                      <Radio
                        name="distribution"
                        id="equalDistribution"
                        label="Sama rata"
                        checked={formik.values.distribution === 'equal'}
                        onChange={() => formik.setFieldValue('distribution', 'equal')}
                      />
                      <Radio
                        name="distribution"
                        id="percentageDistribution"
                        label="Persentase"
                        checked={formik.values.distribution === 'percentage'}
                        onChange={() => formik.setFieldValue('distribution', 'percentage')}
                      />
                    </div>
                  </div>
                )}
                <div className={`form-group pb-0 ${formik.touched.team_cs && formik.errors.team_cs ? 'border border-danger' : ''}`}>
                  <label>Customer Service</label>
                  {formik.touched.team_cs && formik.errors.team_cs ? <small className="text-danger d-block mb-2">{formik.errors.team_cs}</small> : ''}
                  <FieldArray
                    name="team_cs"
                    render={(arrayHelpers) => (
                      <>
                        {formik.values.team_cs.map((cs, index) => (
                          <div key={cs.id} className="d-flex align-items-center mb-2" style={{ gap: 16 }}>
                            <Select
                              name={`team_cs[${index}].cs_id`}
                              id={`team_cs[${index}].cs_id`}
                              placeholder={teamCSLoading ? 'Loading...' : 'Pilih CS'}
                              classNameContainer="w-100 mb-0"
                              data={teamCS?.data?.teamcs?.map((_cs) => ({ label: _cs.name, value: _cs.id }))}
                              value={cs.cs_id}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.values.distribution === 'percentage' && (
                              <div className="input-group" style={{ width: 150 }}>
                                <input
                                  name={`team_cs[${index}].percentage`}
                                  id={`team_cs[${index}].percentage`}
                                  type="number"
                                  className="form-control"
                                  value={cs.percentage}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                <div className="input-group-text">%</div>
                              </div>
                            )}
                            {formik.values.type === 'rotator' && formik.values.team_cs.length > 1 && (
                              <Button variant="danger" onClick={() => arrayHelpers.remove(index)}>
                                <i className="uil-trash-alt" />
                              </Button>
                            )}
                          </div>
                        ))}
                        {formik.values.type === 'rotator' && (
                          <Button
                            variant="secondary"
                            className="mt-2"
                            onClick={() => arrayHelpers.push({
                              id: Date.now(),
                              cs_id: '',
                              percentage: 0,
                            })}
                          >
                            <i className="uil-plus mr-1" />
                            Tambah CS
                          </Button>
                        )}
                      </>
                    )}
                  />
                </div>
                <Select
                  name="label_id"
                  id="label_id"
                  label="Pilih Label"
                  placeholder={labelLoading ? 'Loading...' : '-- Pilih salah satu --'}
                  data={labels?.data?.labels?.map((label) => ({ label: label.name, value: label.id }))}
                  error={formik.touched.label_id && formik.errors.label_id}
                  value={formik.values.label_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  classNameContainer="mb-0"
                />
                <Button isLoading={isLoading} className="px-4 mt-4 mr-2" type="submit">
                  Simpan
                </Button>
                <Link to="/form">
                  <Button variant="light" className="px-4 mt-4">
                    Batal
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

Form.propTypes = {
  initialValues: PropTypes.shape({
    product_id: PropTypes.string,
    payment: PropTypes.string,
    message: PropTypes.string,
    tracking_type: PropTypes.string,
    tracking_id: PropTypes.string,
    type: PropTypes.string,
    distribution: PropTypes.string,
    team_cs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      cs_id: PropTypes.string,
      percentage: PropTypes.number,
    })),
    label_id: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  initialValues: {
    product_id: '',
    payment: '',
    message: '',
    tracking_type: '',
    tracking_id: '',
    type: 'direct',
    distribution: 'equal',
    team_cs: [{
      id: Date.now(),
      cs_id: '',
      percentage: 0,
    }],
    label_id: '',
  },
  isLoading: false,
  onSubmit: () => {},
};

export default Form;
