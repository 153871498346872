import React from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

import { MENU_LIST, getLocalStorage } from '../../../../helpers';

const accessMenuStorage = getLocalStorage('token_access');
const tokenTypeStorage = getLocalStorage('token_type');
const accessMenuList = accessMenuStorage ? JSON.parse(accessMenuStorage) : [];
const newMenuList = tokenTypeStorage === 'general' ? MENU_LIST.filter((menu) => accessMenuList.includes(menu.name)) : MENU_LIST;

const SidebarMenu = () => {
  const handleNotifAffiliate = () => {
    toast.error('Akun affiliate tidak diizinkan untuk mengakses menu ini.');
  };

  return (
    <div className="vertical-menu">
      <div className="navbar-brand-box">
        <a href="/" className="logo">
          <span className="logo-sm">
            <img
              src={`${process.env.REACT_APP_SERVICE_URL_API}uploads/special-icon.png`}
              alt="logo"
              height={30}
            />
          </span>
          <span className="logo-lg">
            <img
              src={`${process.env.REACT_APP_SERVICE_URL_API}uploads/special-logo.png`}
              alt="logo"
              height={30}
            />
          </span>
        </a>
      </div>
      <button
        aria-label="menu"
        type="button"
        className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
      >
        <i className="fa fa-fw fa-bars" />
      </button>
      <div data-simplebar="" className="sidebar-menu-scroll">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {newMenuList.map((menu) => (
              <li key={menu.name}>
                {tokenTypeStorage === 'general' ? (
                  <Link to={menu.link} className="waves-effect">
                    <i className={`uil-${menu.icon}`} />
                    <span>{menu.label}</span>
                  </Link>
                ) : (
                  <a href="#" className="waves-effect" role="button" tabIndex={0} onKeyDown={handleNotifAffiliate} onClick={handleNotifAffiliate}>
                    <i className={`uil-${menu.icon}`} />
                    <span>{menu.label}</span>
                  </a>
                )}
              </li>
            ))}
          </ul>
          <a
            href={`${process.env.REACT_APP_PORTAL_URL}tutorial`}
            target="_blank"
            rel="noreferrer"
            className="btn join-support-button"
          >
            <i className="uil-play-circle mr-1" />
            Tutorial
          </a>
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;
