import React from 'react';
import Skeleton from 'react-loading-skeleton';

const FormLoadingSkeleton = () => (
  <div className="row">
    <div className="col-md-6">
      <div className="form-group">
        <Skeleton width={100} height={22} className="mb-2" />
        <Skeleton height={40} />
      </div>
      <div className="form-group">
        <Skeleton width={100} height={22} className="mb-2" />
        <Skeleton height={40} />
      </div>
      <div className="form-group">
        <Skeleton width={100} height={22} className="mb-2" />
        <Skeleton height={40} />
      </div>
      <div className="form-group">
        <Skeleton width={100} height={22} className="mb-2" />
        <Skeleton height={40} />
      </div>
      <Skeleton width={100} height={40} />
    </div>
    <div className="col-md-6 d-flex align-items-center justify-content-center">
      <Skeleton width={250} height={200} />
    </div>
  </div>
);

export default FormLoadingSkeleton;
