import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useSearchParams } from 'react-router-dom';
import { useDebounce } from '@uidotdev/usehooks';
import { useMutation, useQuery } from 'react-query';

import EmbedCodeModal from './components/EmbedCodeModal';
import formEndpoint from '../../config/service/endpoint/form';
import { AlertDialog, Table } from '../../components/elements';
import { Button, Checkbox, InputField } from '../../components/core';

const FormPage = () => {
  const [openedEmbedCode, setOpenedEmbedCode] = useState(null);
  const [formDelete, setFormDelete] = useState(0);
  const [openedConfirmDelete, setOpenedConfirmDelete] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isCheckAll, setCheckAll] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  const debouncedSearch = useDebounce(searchValue, 500);
  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { isFetching, data: forms, refetch } = useQuery('forms', () => formEndpoint.getAll({ page: currentPage, search: searchValue }), {
    onError: (error) => toast.error(error.message),
    onSuccess: () => setLoadingSearch(false),
  });

  const { mutate: deleteMutate, isLoading: isLoadingDelete } = useMutation(formEndpoint.deleteMultiple, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Form berhasil dihapus.');
      setFormDelete(0);
      setSelectedData([]);
      setOpenedConfirmDelete(false);
      refetch();
    },
  });

  useEffect(() => {
    refetch();
    setCheckAll(false);
    setSelectedData([]);
  }, [searchParams]);

  useEffect(() => {
    refetch();
  }, [debouncedSearch]);

  useEffect(() => {
    setCheckAll((selectedData.length === forms?.data?.forms?.length));
  }, [selectedData]);

  const handleCheckAll = () => {
    if (isCheckAll) {
      setCheckAll(false);
      setSelectedData([]);
    } else {
      const newForms = forms?.data?.forms.map((form) => form.id);
      setSelectedData(newForms);
    }
  };

  const handleCheck = (id) => {
    const isExist = selectedData.some((selected) => selected === id);
    if (isExist) {
      setSelectedData((prevState) => prevState.filter((selected) => selected !== id));
    } else {
      setSelectedData((prevState) => ([
        ...prevState,
        id,
      ]));
    }
  };

  const handleClickDeleteButton = () => {
    if (selectedData.length < 1) {
      toast.error('Pilih minimal 1 data yang akan dihapus.');
      return;
    }
    setOpenedConfirmDelete(true);
  };

  return (
    <>
      <h4 className="mb-4">Form</h4>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between flex-action-button">
          <div className="flex-action-button">
            <Link to="/form/add">
              <Button className="mr-2 px-4">
                Tambah
              </Button>
            </Link>
            <Button variant="danger" onClick={handleClickDeleteButton}>
              <i className="uil-trash-alt" />
            </Button>
          </div>
          <form>
            <InputField
              placeholder="Search"
              classNameContainer="mb-0"
              value={searchValue}
              onChange={(event) => {
                setSearchValue(event.target.value);
                setLoadingSearch(true);
              }}
            />
          </form>
        </div>
        <div className="card-body">
          <Table
            totalData={forms?.data?.meta?.total}
            isLoading={isFetching || loadingSearch}
            headRender={(
              <tr>
                <th aria-label="row" width="30px">
                  <Checkbox
                    id="checkAll"
                    checked={isCheckAll}
                    onChange={handleCheckAll}
                  />
                </th>
                <th>Produk</th>
                <th>Klik</th>
                <th>Code</th>
                <th>Action</th>
              </tr>
            )}
            bodyRender={forms?.data?.forms.map((form) => (
              <tr key={form.id}>
                <td aria-label="row">
                  <Checkbox
                    id={`checkItem-${form.id}`}
                    checked={selectedData.includes(form.id)}
                    onChange={() => handleCheck(form.id)}
                  />
                </td>
                <td>{form.product_name}</td>
                <td>{form.click}</td>
                <td>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => setOpenedEmbedCode(form)}
                  >
                    Embed Code
                  </Button>
                </td>
                <td width={100} aria-label="action">
                  <Link to={`/form/${form.id}`}>
                    <Button variant="success" size="sm" className="mr-1 mb-1">
                      <i className="uil-pen" />
                    </Button>
                  </Link>
                  <Button variant="danger" size="sm" className="mb-1" onClick={() => setFormDelete(form.id)}>
                    <i className="uil-trash-alt" />
                  </Button>
                </td>
              </tr>
            ))}
          />
        </div>
      </div>
      <AlertDialog
        isDanger
        show={!!formDelete || openedConfirmDelete}
        title="Hapus Form?"
        description={formDelete ? 'Apakah kamu yakin ingin menghapus form ini?' : 'Apakah kamu yakin ingin menghapus form yang dipilih?'}
        labelCancel="Batal"
        labelConfirm="Ya, Hapus"
        isLoadingConfirm={isLoadingDelete}
        onClose={() => {
          setFormDelete(0);
          setOpenedConfirmDelete(false);
        }}
        onConfirm={() => {
          deleteMutate({ ids: formDelete ? [formDelete] : selectedData });
        }}
      />
      <EmbedCodeModal
        id={openedEmbedCode?.id}
        token={openedEmbedCode?.token}
        trackingType={openedEmbedCode?.tracking_type}
        trackingId={openedEmbedCode?.tracking_id}
        show={!!openedEmbedCode}
        onClose={() => setOpenedEmbedCode(null)}
      />
    </>
  );
};

export default FormPage;
