import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import templateEndpoint from '../../config/service/endpoint/template';
import { Button, Checkbox } from '../../components/core';
import { AlertDialog, Table } from '../../components/elements';

const TemplatePage = () => {
  const [openedConfirmDelete, setOpenedConfirmDelete] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [isCheckAll, setCheckAll] = useState(false);

  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { isFetching, data: templates, refetch } = useQuery('templates', () => templateEndpoint.getAll({ page: currentPage }), {
    onError: (error) => toast.error(error.message),
  });

  const { mutate: deleteMutate, isLoading: isLoadingDelete } = useMutation(templateEndpoint.deleteMultiple, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Template berhasil dihapus.');
      setSelectedData([]);
      setOpenedConfirmDelete(false);
      refetch();
    },
  });

  useEffect(() => {
    refetch();
    setCheckAll(false);
    setSelectedData([]);
  }, [searchParams]);

  const handleCheck = (id) => {
    const isExist = selectedData.some((selected) => selected === id);
    if (isExist) {
      setSelectedData((prevState) => prevState.filter((selected) => selected !== id));
    } else {
      setSelectedData((prevState) => ([
        ...prevState,
        id,
      ]));
    }
  };

  useEffect(() => {
    setCheckAll(selectedData.length === templates?.data?.templates?.length);
  }, [selectedData]);

  const handleCheckAll = () => {
    if (isCheckAll) {
      setCheckAll(false);
      setSelectedData([]);
    } else {
      const newTemplate = templates?.data?.templates?.map((template) => template.id);
      setSelectedData(newTemplate);
    }
  };

  const handleClickDeleteButton = () => {
    if (selectedData.length < 1) {
      toast.error('Pilih minimal 1 data yang akan dihapus.');
      return;
    }
    setOpenedConfirmDelete(true);
  };

  return (
    <>
      <h4 className="mb-4">Template</h4>
      <div className="card">
        <div className="card-header">
          <Link to="/template/create">
            <Button className="mr-2 px-4">Tambah</Button>
          </Link>
          <Button variant="danger" onClick={handleClickDeleteButton}>
            <i className="uil-trash-alt" />
          </Button>
        </div>
        <div className="card-body">
          <Table
            totalData={templates?.data?.meta?.total}
            isLoading={isFetching}
            headRender={(
              <tr>
                <th aria-label="row" width="30px">
                  <Checkbox
                    id="checkAll"
                    checked={isCheckAll}
                    onChange={handleCheckAll}
                  />
                </th>
                <th>Nama Template</th>
                <th>Kategori</th>
                <th>Produk</th>
                <th>CS</th>
                <th>Action</th>
              </tr>
            )}
            bodyRender={templates?.data?.templates?.map((template) => (
              <tr key={template.id}>
                <td aria-label="row">
                  <Checkbox
                    id={`checkItem-${template.id}`}
                    checked={selectedData.includes(template.id)}
                    onChange={() => handleCheck(template.id)}
                  />
                </td>
                <td>
                  <Link to={`/template/${template.id}`}>
                    <Button variant="info" className="px-3 mr-2">{template.name}</Button>
                  </Link>
                </td>
                <td>{template.category_name}</td>
                <td>{template.product_name}</td>
                <td>{template.cs_name}</td>
                <td width={170} aria-label="action">
                  <Link to={`/template/edit/${template.id}`}>
                    <Button className="px-3">Edit</Button>
                  </Link>
                </td>
              </tr>
            ))}
          />
        </div>
      </div>
      <AlertDialog
        isDanger
        show={openedConfirmDelete}
        title="Hapus Template?"
        description="Apakah kamu yakin ingin menghapus template yang dipilih?"
        labelCancel="Batal"
        labelConfirm="Ya, Hapus"
        isLoadingConfirm={isLoadingDelete}
        onClose={() => setOpenedConfirmDelete(false)}
        onConfirm={() => deleteMutate({ ids: selectedData })}
      />
    </>
  );
};

export default TemplatePage;
