import React, { useState } from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import Form from './components/Form';
import teamCSEndpoint from '../../config/service/endpoint/teamCS';
import FormLoadingSkeleton from './components/FormLoadingSkeleton';

const validationSchema = yup.object({
  name: yup.string().trim().required('Nama CS harus diisi.').max(50, 'Nama CS maksimal 50 karakter.'),
  whatsapp: yup.number().typeError('No WhatsApp harus berupa angka.').required('No WhatsApp harus diisi.').test('len', 'No WhatsApp maksimal 15 karakter', (val) => val.toString().length < 15),
  password: yup.string().trim().optional().min(5, 'Password minimal 5 karakter.'),
  access_menu: yup.array().min(1, 'Minimal ada 1 menu yang dipilih.'),
});

const EditTeamCSPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    name: '',
    whatsapp: '',
    email: '',
    password: '',
    wa_provider: '',
    wa_api_key: '',
    access_menu: [],
  });

  const { isFetching } = useQuery(`team-cs-${params.id}`, () => teamCSEndpoint.getById(params.id), {
    onError: (error) => {
      toast.error(error.message);
      navigate('/team-cs');
    },
    onSuccess: (data) => {
      const { data: teamData } = data;
      setInitialValues({
        name: teamData.name,
        whatsapp: teamData.whatsapp,
        email: teamData.email,
        password: '',
        wa_provider: teamData.wa_provider,
        wa_api_key: teamData.wa_api_key,
        access_menu: teamData.access_menu,
      });
    },
  });

  const { mutate, isLoading } = useMutation(teamCSEndpoint.update, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil mengubah team CS.');
      navigate('/team-cs');
    },
  });

  const handleUpdate = (values) => {
    const payload = {
      name: values.name,
      whatsapp: values.whatsapp,
      password: values.password,
      wa_provider: values.wa_provider,
      wa_api_key: values.wa_api_key,
      access_menu: values.access_menu,
    };
    mutate({
      id: params.id,
      payload,
    });
  };

  return (
    <>
      <h4 className="mb-4">Team CS | Edit</h4>
      <div className="card">
        <div className="card-body">
          {isFetching ? <FormLoadingSkeleton /> : (
            <Form
              isLoading={isLoading}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleUpdate}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EditTeamCSPage;
