import React from 'react';
import { useParams } from 'react-router-dom';

import Menu from './components/Menu';
import LinkPage from './components/LinkPage';
import OrderPage from './components/OrderPage';
import CommissionPage from './components/CommissionPage';

const AccountAffiliatePage = () => {
  const params = useParams();
  const typeParam = params.type || 'link';

  return (
    <>
      <h4 className="mb-4">
        Akun |
        {' '}
        <span className="text-primary">Affiliate</span>
      </h4>
      <div className="card">
        <div className="card-body border-bottom">
          <Menu activeMenu={typeParam} />
        </div>
        <div className="card-body">
          {typeParam === 'link' && <LinkPage />}
          {typeParam === 'order' && <OrderPage />}
          {typeParam === 'komisi' && <CommissionPage />}
        </div>
      </div>
    </>
  );
};

export default AccountAffiliatePage;
