import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import AlertDialog from '../../../components/elements/AlertDialog';
import { Button, InputField, Select } from '../../../components/core';
import { createTemplateImage } from '../../../assets';
import { formikInjectedDefaultProps, formikInjectedPropsTypes } from '../../../helpers';

const CreateForm = ({
  formik, categoriesData, productsData, teamCS,
}) => {
  const navigate = useNavigate();

  const [openedConfirmCancel, setOpenedConfirmCancel] = useState(false);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <InputField
                id="name"
                name="name"
                label="Nama Template"
                value={formik.values.name}
                error={formik.touched.name && formik.errors.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Select
                id="category_id"
                name="category_id"
                label="Pilih Kategori Produk"
                placeholder="Pilih salah satu"
                data={categoriesData.map((category) => ({ label: category.category_name, value: category.id }))}
                value={formik.values.category_id}
                error={formik.touched.category_id && formik.errors.category_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Select
                id="product_id"
                name="product_id"
                label="Pilih Produk"
                placeholder="Pilih salah satu"
                data={productsData.map((product) => ({ label: product.product_name, value: product.id }))}
                value={formik.values.product_id}
                error={formik.touched.product_id && formik.errors.product_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Select
                id="cs_id"
                name="cs_id"
                label="Pilih CS"
                placeholder="Pilih salah satu"
                data={teamCS.map((cs) => ({ label: cs.name, value: cs.id }))}
                value={formik.values.cs_id}
                error={formik.touched.cs_id && formik.errors.cs_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="d-flex mt-3">
                <Button type="submit" className="px-4 mr-2">GENERATE</Button>
                <Button onClick={() => setOpenedConfirmCancel(true)} variant="secondary" className="px-4">BATAL</Button>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <img src={createTemplateImage} alt="workspace office" style={{ height: '50%' }} />
            </div>
          </div>
        </div>
      </div>
      <AlertDialog
        isDanger
        show={openedConfirmCancel}
        title="Batal Buat Template?"
        description="Apakah kamu yakin ingin membatalkan proses buat template? Semua perubahan yang sudah kamu lakukan akan hilang"
        labelCancel="Tutup"
        labelConfirm="Ya, Batalkan"
        onClose={() => setOpenedConfirmCancel(false)}
        onConfirm={() => navigate('/template')}
      />
    </>
  );
};

CreateForm.propTypes = {
  formik: formikInjectedPropsTypes,
  categoriesData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    category_name: PropTypes.string,
  })),
  productsData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    product_name: PropTypes.string,
  })),
  teamCS: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
};

CreateForm.defaultProps = {
  formik: formikInjectedDefaultProps,
  categoriesData: [],
  productsData: [],
  teamCS: [],
};

export default CreateForm;
