import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import teamCSEndpoint from '../../config/service/endpoint/teamCS';
import { Button, Checkbox, Toggle } from '../../components/core';
import { AlertDialog, Table } from '../../components/elements';

const TeamCSPage = () => {
  const [productDelete, setProductDelete] = useState(0);
  const [openedConfirmDelete, setOpenedConfirmDelete] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [isCheckAll, setCheckAll] = useState(false);
  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { isFetching, data: teamCS, refetch } = useQuery('team-cs', () => teamCSEndpoint.getAll({ page: currentPage }), {
    onError: (error) => toast.error(error.message),
  });

  const { mutate: deleteMutate, isLoading: isLoadingDelete } = useMutation(teamCSEndpoint.deleteMultiple, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Team CS berhasil dihapus.');
      setProductDelete(0);
      setSelectedData([]);
      setOpenedConfirmDelete(false);
      refetch();
    },
  });

  const { mutate: changeStatusMutate, isLoading: changeStatusLoading } = useMutation(teamCSEndpoint.changeStatus, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Status team cs berhasil diubah.');
      refetch();
    },
  });

  useEffect(() => {
    refetch();
    setCheckAll(false);
    setSelectedData([]);
  }, [searchParams]);

  const handleCheck = (id) => {
    const isExist = selectedData.some((selected) => selected === id);
    if (isExist) {
      setSelectedData((prevState) => prevState.filter((selected) => selected !== id));
    } else {
      setSelectedData((prevState) => ([
        ...prevState,
        id,
      ]));
    }
  };

  useEffect(() => {
    setCheckAll(selectedData.length === teamCS?.data?.teamcs?.length);
  }, [selectedData]);

  const handleCheckAll = () => {
    if (isCheckAll) {
      setCheckAll(false);
      setSelectedData([]);
    } else {
      const newTeamCS = teamCS?.data?.teamcs?.map((team) => team.id);
      setSelectedData(newTeamCS);
    }
  };

  const handleClickDeleteButton = () => {
    if (selectedData.length < 1) {
      toast.error('Pilih minimal 1 data yang akan dihapus.');
      return;
    }
    setOpenedConfirmDelete(true);
  };

  return (
    <>
      <h4 className="mb-4">Team CS</h4>
      <div className="card">
        <div className="card-header">
          <Link to="/team-cs/add">
            <Button className="mr-2 px-3">Tambah</Button>
          </Link>
          <Button variant="danger" onClick={handleClickDeleteButton}>
            <i className="uil-trash-alt" />
          </Button>
        </div>
        <div className="card-body">
          <Table
            totalData={teamCS?.data?.meta?.total}
            isLoading={isFetching || changeStatusLoading}
            headRender={(
              <tr>
                <th aria-label="row" width="30px">
                  <Checkbox
                    id="checkAll"
                    checked={isCheckAll}
                    onChange={handleCheckAll}
                  />
                </th>
                <th>Nama</th>
                <th>No WhatsApp</th>
                <th>Email</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            )}
            bodyRender={teamCS?.data?.teamcs?.map((team) => (
              <tr key={team.id}>
                <td aria-label="row">
                  <Checkbox
                    id={`checkItem-${team.id}`}
                    checked={selectedData.includes(team.id)}
                    onChange={() => handleCheck(team.id)}
                  />
                </td>
                <td>{team.name}</td>
                <td>{team.whatsapp}</td>
                <td>{team.email}</td>
                <td aria-label="status">
                  <Toggle
                    checked={team.is_active}
                    label={team.is_active ? 'Aktif' : 'Tidak Aktif'}
                    onChange={() => changeStatusMutate(team.id)}
                  />
                </td>
                <td width={130} aria-label="action">
                  <CopyToClipboard text={`${process.env.REACT_APP_SERVICE_URL_API}fonnte/webhook-auto-reply?cs-id=${team.id}`} onCopy={() => toast.success('Berhasil menyalin url webhook.')}>
                    <Button variant="info" size="sm" className="mr-1 mb-1">
                      <i className="uil-copy" />
                    </Button>
                  </CopyToClipboard>
                  <Link to={`/team-cs/${team.id}`}>
                    <Button variant="success" size="sm" className="mr-1 mb-1">
                      <i className="uil-pen" />
                    </Button>
                  </Link>
                  <Button variant="danger" size="sm" className="mb-1" onClick={() => setProductDelete(team.id)}>
                    <i className="uil-trash-alt" />
                  </Button>
                </td>
              </tr>
            ))}
          />
        </div>
      </div>
      <AlertDialog
        isDanger
        show={!!productDelete || openedConfirmDelete}
        title="Hapus Team CS?"
        description={productDelete ? 'Apakah kamu yakin ingin menghapus team cs ini?' : 'Apakah kamu yakin ingin menghapus team cs yang dipilih?'}
        labelCancel="Batal"
        labelConfirm="Ya, Hapus"
        isLoadingConfirm={isLoadingDelete}
        onClose={() => {
          setProductDelete(0);
          setOpenedConfirmDelete(false);
        }}
        onConfirm={() => {
          deleteMutate({ ids: productDelete ? [productDelete] : selectedData });
        }}
      />
    </>
  );
};

export default TeamCSPage;
