import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';

import router from './config/router';
import AppProvider from './context/appContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <AppProvider>
      <Toaster toastOptions={{ duration: 5000 }} />
      <RouterProvider router={router} />
    </AppProvider>
  </QueryClientProvider>
);

export default App;
