import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Skeleton from 'react-loading-skeleton';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import accountEndpoint from '../../../../config/service/endpoint/account';

const AFFILIATE_URL = `${process.env.REACT_APP_BASE_URL}aff/`;

const LinkPage = () => {
  const { isFetching, data } = useQuery('profile', accountEndpoint.getProfile, { onError: (error) => toast.error(error.message) });

  const affiliateCode = data?.data?.affiliate_code;

  return (
    <>
      <h6 className="text-primary">Link Affiliate Anda</h6>
      <div className="p-3 bg-light d-flex justify-content-between rounded-lg border border-secondary">
        {isFetching ? <Skeleton width={180} height={23} /> : (
          <>
            <span>{`${AFFILIATE_URL}${affiliateCode}`}</span>
            <CopyToClipboard text={`${AFFILIATE_URL}${affiliateCode}`} onCopy={() => toast.success('Link berhasil di salin.')}>
              <i className="uil-copy-alt copy-clipboard-button" style={{ fontSize: 20, lineHeight: 0, cursor: 'pointer' }} />
            </CopyToClipboard>
          </>
        )}
      </div>
      <h6 className="text-primary mb-2 mt-5">Syarat dan Ketentuan</h6>
      <p className="text-muted mb-4">Mohon diperhatikan cara promosi agar komisi tetap bisa anda dapatkan.</p>
      <div className="p-4 bg-soft-warning rounded-lg border border-secondary">
        <h6>Dilarang Memberikan Bonus Ilegal</h6>
        <p className="text-muted mb-4">Menawarkan bonus berupa produk yang didapat dengan cara tidak etis seperti: bajakan, crack, odong odong, dll</p>
        <h6>Dilarang Menggunakan Missleading</h6>
        <p className="text-muted mb-4">Menggunakan nama (Fanpage, email, website, WA dll) &quot;Mesin CS&quot; atau &quot;Ilham Ramadhan&quot;</p>
        <h6>Dilarang Membeli Menggunakan Link Affiliate Sendiri</h6>
        <p className="text-muted mb-4">Membeli menggunakan link affiliasi sendiri membeli produk melalui link affiliate sendiri atau link affiliate nama saudara / kerabatnya (khusus dibuat untuk itu)</p>
        <h6>Dilarang Memberikan Bonus Product Back</h6>
        <p className="text-muted mb-4">Memberikan bonus berupa &quot;product back&quot; dengan model apapun. Contoh: &quot;saya belikan produk A jika membeli produk B&quot;</p>
      </div>
    </>
  );
};

export default LinkPage;
