import React from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Form from './components/Form';
import teamCSEndpoint from '../../config/service/endpoint/teamCS';

const initialValues = {
  name: '',
  whatsapp: '',
  email: '',
  password: '',
  wa_provider: '',
  wa_api_key: '',
  access_menu: [],
};

const validationSchema = yup.object({
  name: yup.string().trim().required('Nama CS harus diisi.').max(50, 'Nama CS maksimal 50 karakter.'),
  whatsapp: yup.number().typeError('No WhatsApp harus berupa angka.').required('No WhatsApp harus diisi.').test('len', 'No WhatsApp maksimal 15 karakter', (val) => val.toString().length < 15),
  email: yup.string().trim().required('Email harus diisi.').max(50, 'Email maksimal 50 karakter.'),
  password: yup.string().trim().required('Password harus diisi.').min(5, 'Password minimal 5 karakter.'),
  access_menu: yup.array().min(1, 'Minimal ada 1 menu yang dipilih.'),
});

const AddTeamCSPage = () => {
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(teamCSEndpoint.insert, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil menambahkan team CS.');
      navigate('/team-cs');
    },
  });

  const handleInsert = (values) => {
    mutate({
      name: values.name,
      whatsapp: values.whatsapp,
      email: values.email,
      password: values.password,
      wa_provider: values.wa_provider,
      wa_api_key: values.wa_api_key,
      access_menu: values.access_menu,
    });
  };

  return (
    <>
      <h4 className="mb-4">Team CS | Tambah</h4>
      <div className="card">
        <div className="card-body">
          <Form
            isLoading={isLoading}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleInsert}
          />
        </div>
      </div>
    </>
  );
};

export default AddTeamCSPage;
