import React, { useState } from 'react';
import * as yup from 'yup';
import { Alert } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { FormikProvider, useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';

import AlertDialog from '../../components/elements/AlertDialog';
import authEndpoint from '../../config/service/endpoint/auth';
import { Button, InputField } from '../../components/core';
import { useAppContext } from '../../hooks';

const RegisterAffiliatePage = () => {
  const [showSuccessInfo, setShowSuccessInfo] = useState(false);

  const navigate = useNavigate();
  const { setting } = useAppContext();

  const { mutate, error, isLoading } = useMutation(authEndpoint.registerAffiliate, {
    onSuccess: () => setShowSuccessInfo(true),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      whatsapp: '',
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      name: yup.string().required('Nama harus diisi.'),
      whatsapp: yup.string().required('WhatsApp harus diisi.'),
      email: yup.string().required('Email harus diisi.'),
      password: yup.string().required('Password harus diisi.'),
    }),
    onSubmit: (values) => {
      mutate({
        name: values.name,
        whatsapp: values.whatsapp,
        email: values.email,
        password: values.password,
      });
    },
  });

  return (
    <>
      <Helmet>
        <title>{`Daftar Affiliate - ${setting.businessName}`}</title>
      </Helmet>
      <div className="account-pages mb-0 pt-5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="text-center mb-4">
                <img src={`${process.env.REACT_APP_SERVICE_URL_API}uploads/special-logo.png`} width="150" alt="Logo" />
              </div>
              <div className="card">
                <div className="card-body p-4">
                  <div className="p-2">
                    {error && (
                      <Alert variant="danger">
                        {error.message}
                      </Alert>
                    )}
                    <FormikProvider value={formik}>
                      <form onSubmit={formik.handleSubmit}>
                        <InputField
                          name="name"
                          id="name"
                          label="Nama"
                          placeholder="Nama Lengkap"
                          error={formik.touched.name && formik.errors.name}
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <InputField
                          name="whatsapp"
                          id="whatsapp"
                          label="No Whatsapp"
                          placeholder="No Whatsapp Aktif"
                          error={formik.touched.whatsapp && formik.errors.whatsapp}
                          value={formik.values.whatsapp}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <InputField
                          name="email"
                          id="email"
                          label="Email"
                          placeholder="Masukkan Email"
                          error={formik.touched.email && formik.errors.email}
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <InputField
                          name="password"
                          id="password"
                          label="Password"
                          type="password"
                          placeholder="Masukkan Password"
                          error={formik.touched.password && formik.errors.password}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <Button
                          type="submit"
                          className="w-100 mt-2"
                          size="lg"
                          isLoading={isLoading}
                        >
                          Daftar
                        </Button>
                        <div className="d-flex justify-content-end mt-3 align-items-center">
                          <p className="mb-0 mr-1">Sudah punya akun?</p>
                          <Link to="/">
                            Login
                          </Link>
                        </div>
                      </form>
                    </FormikProvider>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <p>© Mesin CS.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertDialog
        show={showSuccessInfo}
        title="Pendaftaran Berhasil"
        description="Berhasil melakukan pendaftaran affiliate, silakan login menggunakan email dan password yang Anda buat."
        labelConfirm="Login Sekarang"
        onConfirm={() => navigate('/')}
      />
    </>
  );
};

export default RegisterAffiliatePage;
