import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { FormikProvider, useFormik } from 'formik';

import FormLoadingSkeleton from './FormLoadingSkeleton';
import labelEndpoint from '../../../config/service/endpoint/label';
import teamCSEndpoint from '../../../config/service/endpoint/teamCS';
import contactEndpoint from '../../../config/service/endpoint/contact';
import { Modal } from '../../../components/elements';
import {
  Button, InputField, Select, Textarea,
} from '../../../components/core';

const validationSchema = yup.object({
  name: yup.string().trim().required('Nama harus diisi.').max(50, 'Nama maksimal 50 karakter.'),
  whatsapp: yup.string().trim().required('No WhatsApp harus diisi.').max(15, 'No WhatsApp maksimal 15 karakter.'),
  labelId: yup.string().trim().required('Pilih label terlebih dahulu.'),
  csId: yup.string().trim().required('Pilih CS terlebih dahulu.'),
  email: yup.string().trim().max(50, 'Email maksimal 50 karakter.'),
  address: yup.string().trim().min(10, 'Alamat minimal 10 karakter.'),
  subdistrict: yup.string().trim().max(100, 'Kecamatan maksimal 100 karakter.'),
  district: yup.string().trim().max(100, 'Kab/Kota maksimal 100 karakter.'),
  province: yup.string().trim().max(100, 'Provinsi maksimal 100 karakter.'),
  postalCode: yup.number().typeError('Kode pos harus berupa angka.').min(11111, 'Kode pos minimal 5 angka')
    .max(99999, 'Kode pos maksimal 5 angka.'),
});

const FormContactModal = ({
  contactId, show, onClose, onFetch,
}) => {
  const { isLoading: labelLoading, data: labelData, error: labelError } = useQuery('labels', labelEndpoint.getAll);
  const { isLoading: teamcsLoading, data: teamcsData, error: teamcsError } = useQuery('teamcs', teamCSEndpoint.getAll);

  const { mutate, isLoading } = useMutation(contactId ? contactEndpoint.update : contactEndpoint.insert, {
    onError: (error) => toast.error(error.message),
    onSuccess: (response) => {
      toast.success(contactId ? 'Berhasil mengubah contact.' : 'Berhasil menambah contact.');
      onFetch(response.data.id);
      onClose();
    },
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      whatsapp: '',
      labelId: '',
      csId: '',
      email: '',
      address: '',
      subdistrict: '',
      district: '',
      province: '',
      postalCode: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const payload = {
        name: values.name,
        whatsapp: values.whatsapp,
        label_id: values.labelId,
        cs_id: values.csId,
        email: values.email,
        address: values.address,
        subdistrict: values.subdistrict,
        district: values.district,
        province: values.province,
        postal_code: values.postalCode,
      };
      if (contactId) mutate({ id: contactId, payload });
      else mutate(payload);
    },
  });

  useEffect(() => {
    if (!show) formik.resetForm();
  }, [show]);

  const { isFetching: contactLoading, refetch } = useQuery(`contact-${contactId}`, () => contactEndpoint.getById(contactId), {
    enabled: false,
    onError: (error) => toast.error(error.message),
    onSuccess: (data) => {
      const contactData = data?.data;
      if (!contactData) return;
      formik.setFieldValue('name', contactData.name);
      formik.setFieldValue('whatsapp', contactData.whatsapp);
      formik.setFieldValue('labelId', contactData.label.id);
      formik.setFieldValue('csId', contactData.cs_id);
      formik.setFieldValue('email', contactData.email);
      formik.setFieldValue('address', contactData.address);
      formik.setFieldValue('subdistrict', contactData.subdistrict);
      formik.setFieldValue('district', contactData.district);
      formik.setFieldValue('province', contactData.province);
      formik.setFieldValue('postalCode', contactData.postal_code);
    },
  });

  useEffect(() => {
    if (contactId && show) refetch();
  }, [contactId, show]);

  return (
    <Modal
      show={show}
      title="Tambah Kontak"
      onClose={onClose}
      size="lg"
    >
      {contactLoading ? <FormLoadingSkeleton /> : (
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <InputField
                  id="name"
                  name="name"
                  label="Nama"
                  placeholder="Nama Lengkap"
                  error={formik.touched.name && formik.errors.name}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-md-6">
                <InputField
                  id="whatsapp"
                  name="whatsapp"
                  label="No WhatsApp"
                  placeholder="08.."
                  error={formik.touched.whatsapp && formik.errors.whatsapp}
                  value={formik.values.whatsapp}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-md-6">
                <Select
                  id="labelId"
                  name="labelId"
                  label="Label"
                  placeholder={labelLoading ? 'Loading...' : 'Pilih Label'}
                  data={labelData?.data?.labels?.map((_label) => ({ value: _label.id, label: _label.name }))}
                  error={(formik.touched.labelId && formik.errors.labelId) || labelError?.message}
                  value={formik.values.labelId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-md-6">
                <Select
                  id="csId"
                  name="csId"
                  label="CS"
                  placeholder={teamcsLoading ? 'Loading...' : 'Pilih CS'}
                  data={teamcsData?.data?.teamcs?.map((_cs) => ({ value: _cs.id, label: _cs.name }))}
                  error={(formik.touched.csId && formik.errors.csId) || teamcsError?.message}
                  value={formik.values.csId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-12">
                <InputField
                  id="email"
                  name="email"
                  label="Email"
                  placeholder="Alamat email"
                  error={formik.touched.email && formik.errors.email}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-12">
                <Textarea
                  id="address"
                  name="address"
                  label="Alamat Rumah"
                  placeholder="Alamat"
                  error={formik.touched.address && formik.errors.address}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-md-6">
                <InputField
                  id="subdistrict"
                  name="subdistrict"
                  label="Kecamatan"
                  error={formik.touched.subdistrict && formik.errors.subdistrict}
                  value={formik.values.subdistrict}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-md-6">
                <InputField
                  id="district"
                  name="district"
                  label="Kab/Kota"
                  error={formik.touched.district && formik.errors.district}
                  value={formik.values.district}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-md-6">
                <InputField
                  id="province"
                  name="province"
                  label="Province"
                  error={formik.touched.province && formik.errors.province}
                  value={formik.values.province}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="col-md-6">
                <InputField
                  id="postalCode"
                  name="postalCode"
                  label="Kode Pos"
                  error={formik.touched.postalCode && formik.errors.postalCode}
                  value={formik.values.postalCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>
            <Button type="submit" isLoading={isLoading} className="px-4">SIMPAN</Button>
          </form>
        </FormikProvider>
      )}
    </Modal>
  );
};

FormContactModal.propTypes = {
  contactId: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onFetch: PropTypes.func,
};

FormContactModal.defaultProps = {
  contactId: '',
  show: false,
  onClose: () => {},
  onFetch: () => {},
};

export default FormContactModal;
