import configApi from '../configApi';

const getTopCS = (params) => configApi({ endpoint: 'dashboard/report/top-cs', params });
const getTopProduct = (params) => configApi({ endpoint: 'dashboard/report/top-product', params });
const getMonthlyOrder = (params) => configApi({ endpoint: 'dashboard/report/monthly-order', params });

const endpoint = {
  getTopCS,
  getTopProduct,
  getMonthlyOrder,
};

export default endpoint;
