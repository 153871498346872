import configApi from '../configApi';

const getProfile = () => configApi({ endpoint: 'dashboard/account/profile' });
const updateProfile = (payload) => configApi({ endpoint: 'dashboard/account/profile', method: 'put', payload });
const changePassword = (payload) => configApi({ endpoint: 'dashboard/account/change-password', method: 'put', payload });
const productSelected = () => configApi({ endpoint: 'dashboard/account/product-selected' });
const getInvoice = () => configApi({ endpoint: 'dashboard/account/invoice' });
const getAllProduct = () => configApi({ endpoint: 'dashboard/account/products' });
const getOrderAffiliate = (params) => configApi({ endpoint: 'dashboard/account/affiliate/order', params });
const getCommissionAffiliate = () => configApi({ endpoint: 'dashboard/account/affiliate/commission' });

const endpoint = {
  getProfile,
  updateProfile,
  changePassword,
  productSelected,
  getInvoice,
  getAllProduct,
  getOrderAffiliate,
  getCommissionAffiliate,
};

export default endpoint;
