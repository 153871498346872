import React, { useEffect } from 'react';
import ReactSelect from 'react-select';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';

import teamCSEndpoint from '../../config/service/endpoint/teamCS';
import labelEndpoint from '../../config/service/endpoint/label';
import campaignEndpoint from '../../config/service/endpoint/campaign';
import uploadEndpoint from '../../config/service/endpoint/upload';
import {
  Button, Checkbox, InputField, Select, Textarea,
} from '../../components/core';

const validationSchema = yup.object({
  name: yup.string().trim().required('Nama campaign harus diisi.').max(100, 'Nama campaign maksimal 100 karakter.'),
  cs_id: yup.string().trim().required('Pilih CS terlebih dahulu.'),
  label_ids: yup.array().min(1, 'Masukkan minimal 1 label'),
  message: yup.string().trim().required('Pesan harus diisi'),
  is_send_now: yup.boolean(),
  schedule: yup.string().when('is_send_now', {
    is: false,
    then: () => yup.string().trim().required('Jadwal harus diisi'),
  }),
  delay: yup.number().typeError('Delay harus berupa angka.').min(0, 'Delay minimal 0 detik'),
});

const CreateCampaignPage = () => {
  const navigate = useNavigate();

  const { data: teamCSData, isLoading: teamCSLoading } = useQuery('team-cs', teamCSEndpoint.getAll, {
    onError: (error) => toast.error(error.message),
  });

  const { data: labelData, isLoading: labelLoading } = useQuery('labels', labelEndpoint.getAll, {
    onError: (error) => toast.error(error.message),
  });

  const { mutateAsync: mutateUpload, isLoading: isLoadingUpload } = useMutation(uploadEndpoint.uploadFile, {
    onError: (error) => toast.error(error.message),
  });

  const { mutate, isLoading } = useMutation(campaignEndpoint.insert, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil mengirimkan campaign.');
      navigate('/campaign');
    },
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      cs_id: '',
      label_ids: [],
      message: '',
      file: '',
      is_send_now: false,
      schedule: '',
      delay: 0,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append('file', values.file);
        let response;
        if (values.file) {
          response = await mutateUpload(formData);
          if (!response.success) throw new Error(response.message);
        }
        mutate({
          name: values.name,
          cs_id: values.cs_id,
          label_ids: values.label_ids.map((label) => label.value),
          message: values.message,
          file: response ? response.data.file_name : '',
          is_send_now: values.is_send_now,
          schedule: values.schedule,
          delay: values.delay,
        });
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  useEffect(() => {
    if (formik.values.is_send_now) {
      formik.setFieldValue('schedule', '');
    }
  }, [formik.values.is_send_now]);

  return (
    <>
      <h4 className="mb-4">Campaign | Broadcast</h4>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                  <InputField
                    id="name"
                    name="name"
                    label="Nama Campaign"
                    error={formik.touched.name && formik.errors.name}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <Select
                    id="cs_id"
                    name="cs_id"
                    label="Pilih CS"
                    placeholder={teamCSLoading ? 'Loading...' : 'Pilih salah satu'}
                    data={teamCSData?.data?.teamcs?.map((teamcs) => ({ label: teamcs.name, value: teamcs.id }))}
                    error={formik.touched.cs_id && formik.errors.cs_id}
                    value={formik.values.cs_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={(
                      <small className="text-secondary">
                        Wajib menggunakan API Whatsapp unofficial
                        {' '}
                        <a target="_blank" rel="noreferrer" href="https://fonnte.com/">KLIK DISINI</a>
                      </small>
                    )}
                  />
                  <div className="form-group">
                    <label>Pilih Label</label>
                    <ReactSelect
                      isMulti
                      id="label_ids"
                      name="label_ids"
                      placeholder={labelLoading ? 'Loading...' : 'Pilih minimal satu'}
                      value={formik.values.label_ids}
                      options={labelData?.data?.labels?.map((label) => ({ label: `${label.name} (${label.total_contact} contact)`, value: label.id, total: label.total_contact }))}
                      onBlur={formik.handleBlur}
                      onChange={(assiged) => formik.setFieldValue('label_ids', assiged)}
                    />
                    <small className="text-secondary">{`Total ${formik.values.label_ids.reduce((a, b) => a + b.total, 0)} kontak`}</small>
                    {formik.touched.label_ids && formik.errors.label_ids && (
                      <small className="text-danger d-block">{formik.errors.label_ids}</small>
                    )}
                  </div>
                  <Textarea
                    id="message"
                    name="message"
                    label="Isi Pesan"
                    rows={5}
                    placeholder="Halo kak {name}"
                    error={formik.touched.message && formik.errors.message}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={(
                      <small className="text-secondary">
                        Untuk memanggil nama
                        {' {name}'}
                      </small>
                    )}
                  />
                  <InputField
                    id="file"
                    name="file"
                    label="File"
                    type="file"
                    accept="application/pdf,image/jpeg,image/png"
                    helperText="Media (pdf, jpg, png)"
                    error={formik.touched.file && formik.errors.file}
                    value={undefined}
                    onChange={(event) => formik.setFieldValue('file', event.currentTarget.files[0])}
                    onBlur={formik.handleBlur}
                  />
                  <Checkbox
                    id="is_send_now"
                    name="is_send_now"
                    label="Kirim Sekarang"
                    checked={formik.values.is_send_now}
                    onChange={formik.handleChange}
                    className={formik.errors.is_send_now ? '' : 'mb-3'}
                    onBlur={formik.handleBlur}
                  />
                  {formik.values.is_send_now === false && (
                    <InputField
                      id="schedule"
                      name="schedule"
                      label="Jadwalkan"
                      type="datetime-local"
                      classNameContainer="mt-3"
                      helperText="Pilih waktu jika ingin schedule kirim pesan"
                      error={formik.touched.schedule && formik.errors.schedule}
                      value={formik.values.schedule}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  )}
                  <div className="form-group">
                    <label>Delay (per detik)</label>
                    <div className="row">
                      <div className="col-md-6">
                        <InputField
                          id="delay"
                          name="delay"
                          error={formik.touched.delay && formik.errors.delay}
                          value={formik.values.delay}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <Button type="submit" isLoading={isLoading || isLoadingUpload} variant="success" className="px-4 mr-2">KIRIM</Button>
                    <Link to="/campaign">
                      <Button variant="secondary" className="px-4">BATAL</Button>
                    </Link>
                  </div>
                </form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCampaignPage;
