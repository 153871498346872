import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import DetailLoadingSkeleton from './components/DetailLoadingSkeleton';
import FormContactModal from '../Contact/components/FormContactModal';
import ProductInfo from './components/detail/ProductInfo';
import ContactInfo from './components/detail/ContactInfo';
import Histories from './components/detail/Histories';
import orderEndpoint from '../../config/service/endpoint/order';

const OrderDetailPage = () => {
  const params = useParams();

  const [editContactId, setEditContactId] = useState(null);

  const { isLoading, data, refetch } = useQuery(`order-${params.id}`, () => orderEndpoint.getById(params.id), {
    onError: (error) => toast.error(error.message),
  });

  if (isLoading) return <DetailLoadingSkeleton />;

  const orderData = data.data;

  return (
    <>
      <h4 className="mb-4">Order | Detail</h4>
      <div className="row">
        <div className="col-md-8">
          <ContactInfo
            contact={orderData.contact}
            onEdit={setEditContactId}
          />
          <ProductInfo
            products={orderData.products}
            subtotal={orderData.subtotal}
            discount={orderData.discount}
            totalBill={orderData.total_bill}
            orderId={orderData.id}
            initPaymentProof={orderData.payment_proof}
            onRefetch={refetch}
          />
        </div>
        <div className="col-md-4">
          <Histories histories={orderData.histories} />
        </div>
      </div>
      <FormContactModal
        show={!!editContactId}
        contactId={editContactId}
        onClose={() => setEditContactId(false)}
        onFetch={refetch}
      />
    </>
  );
};

export default OrderDetailPage;
