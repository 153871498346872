import React from 'react';
import PropTypes from 'prop-types';

import { getLocalStorage } from '../../helpers';
import { Error403 } from '../../views';

const AccessMenu = ({ pageName, Component }) => {
  const accessMenuStorage = getLocalStorage('token_access');
  const accessMenuList = accessMenuStorage ? JSON.parse(accessMenuStorage).split(',') : [];

  if (!accessMenuStorage) return <Error403 />;

  const isAllowed = accessMenuList.some((_menu) => _menu === pageName);

  if (!isAllowed) return <Error403 />;

  return <Component />;
};

AccessMenu.propTypes = {
  pageName: PropTypes.string.isRequired,
  Component: PropTypes.elementType.isRequired,
};

export default AccessMenu;
