import React from 'react';
import PropTypes from 'prop-types';

import { Button, InputField } from '../../../../components/core';

const QuantityAction = ({ quantity, onIncrease, onDecrease }) => (
  <div className="d-flex" style={{ gap: 4 }}>
    <div style={{ width: 30 }}>
      <Button size="sm w-100" onClick={onDecrease}>-</Button>
    </div>
    <div style={{ width: 40 }}>
      <InputField readOnly classNameInput="form-control-sm text-center" value={quantity} classNameContainer="mb-0 w-1" />
    </div>
    <div style={{ width: 30 }}>
      <Button size="sm w-100" onClick={onIncrease}>+</Button>
    </div>
  </div>
);

QuantityAction.propTypes = {
  quantity: PropTypes.number.isRequired,
  onIncrease: PropTypes.func.isRequired,
  onDecrease: PropTypes.func.isRequired,
};

export default QuantityAction;
