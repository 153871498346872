import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';

import { Button, InputField } from '../../../components/core';

const getStartDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
const getEndDate = moment().format('YYYY-MM-DD');

const FilterDateDropdown = ({ onApply }) => {
  const [startDate, setStartDate] = useState(getStartDate);
  const [endDate, setEndDate] = useState(getEndDate);

  const handleApply = () => onApply({ startDate, endDate });

  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" className="px-4">
        FILTER
      </Dropdown.Toggle>
      <Dropdown.Menu className="px-3 py-3">
        <form style={{ width: 250 }}>
          <InputField
            label="Tanggal Awal"
            type="date"
            value={startDate}
            onChange={(event) => setStartDate(event.target.value)}
          />
          <InputField
            label="Tanggal Akhir"
            type="date"
            value={endDate}
            onChange={(event) => setEndDate(event.target.value)}
          />
          <Button onClick={handleApply}>Terapkan</Button>
        </form>
      </Dropdown.Menu>
    </Dropdown>
  );
};

FilterDateDropdown.propTypes = {
  onApply: PropTypes.func.isRequired,
};

export default FilterDateDropdown;
