import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { FormikProvider, useFormik } from 'formik';

import contactEndpoint from '../../../../config/service/endpoint/contact';
import { Button, Textarea } from '../../../../components/core';

const Form = ({ contactId, onFetch }) => {
  const { isLoading, mutateAsync } = useMutation(contactEndpoint.insertActivity, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil menambahkan aktivitas.');
      onFetch();
    },
  });

  const formik = useFormik({
    initialValues: { type: 'note', content: '' },
    validationSchema: yup.object({
      content: yup.string().trim().required('Tidak boleh kosong.'),
    }),
    onSubmit: async (values) => {
      const res = await mutateAsync({
        contactId,
        payload: {
          type: values.type,
          content: values.content,
        },
      });
      if (res && res.success) formik.resetForm();
    },
  });

  return (
    <div className="card">
      <div className="card-header">
        <Button
          variant={formik.values.type === 'note' ? 'primary' : 'outline-primary'}
          className="px-4 mr-2"
          onClick={() => formik.setFieldValue('type', 'note')}
        >
          Note
        </Button>
        <Button
          variant={formik.values.type === 'whatsapp' ? 'primary' : 'outline-primary'}
          className="px-4"
          onClick={() => formik.setFieldValue('type', 'whatsapp')}
        >
          WhatsApp
        </Button>
      </div>
      <div className="card-body">
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Textarea
              id="content"
              name="content"
              placeholder="Tulis disini"
              rows={10}
              value={formik.values.content}
              error={formik.touched.content && formik.errors.content}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className="d-flex justify-content-end">
              <Button
                className="px-4"
                type="submit"
                variant="success"
                isLoading={isLoading}
              >
                {formik.values.type === 'note' ? 'SUBMIT' : 'KIRIM'}
              </Button>
            </div>
          </form>
        </FormikProvider>
      </div>
    </div>
  );
};

Form.propTypes = {
  contactId: PropTypes.string,
  onFetch: PropTypes.func,
};

Form.defaultProps = {
  contactId: '',
  onFetch: () => {},
};

export default Form;
