import configApi from '../configApi';

const login = (payload) => configApi({ endpoint: 'dashboard/auth/login', method: 'post', payload });
const registerAffiliate = (payload) => configApi({ endpoint: 'dashboard/auth/register-affiliate', method: 'post', payload });

const endpoint = {
  login,
  registerAffiliate,
};

export default endpoint;
