import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const Histories = ({ histories }) => (
  <div className="card">
    <div className="card-body order-detail-history">
      <h5>Riwayat</h5>
      <hr />
      <ul className="timeline">
        {histories.map((history) => (
          <li key={history.id}>
            <p className="mb-0">{history.message}</p>
            <small className="text-secondary">{moment(history.date).format('DD MMM YYYY | HH:mm')}</small>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

Histories.propTypes = {
  histories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string,
    date: PropTypes.string,
  })),
};

Histories.defaultProps = {
  histories: [],
};

export default Histories;
