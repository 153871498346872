import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../../components/core';
import { toRupiah } from '../../../../helpers';

const MainProfile = ({
  name, totalPurchase, onEdit, onOpenHistory,
}) => (
  <div className="card">
    <div className="card-body">
      <div className="media">
        <div className="align-self-center mr-3 bg-secondary rounded-pill text-light d-flex justify-content-center align-items-center" style={{ width: 50, height: 50 }}>
          <span style={{ fontSize: 22 }}>{Array.from(name)[0]}</span>
        </div>
        <div className="media-body">
          <h5 className="mt-0 mb-1">{name}</h5>
          <p className="mb-0">
            Total Pembelian :
            {' '}
            <span className="text-success">{toRupiah(totalPurchase)}</span>
          </p>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-6">
          <Button variant="outline-primary" className="mb-2 w-100" onClick={onEdit}>Edit</Button>
        </div>
        <div className="col-md-6">
          <Button variant="outline-primary" className="mb-2 w-100" onClick={onOpenHistory}>Riwayat</Button>
        </div>
      </div>
    </div>
  </div>
);

MainProfile.propTypes = {
  name: PropTypes.string,
  totalPurchase: PropTypes.number,
  onEdit: PropTypes.func,
  onOpenHistory: PropTypes.func,
};

MainProfile.defaultProps = {
  name: '',
  totalPurchase: 0,
  onEdit: () => {},
  onOpenHistory: () => {},
};

export default MainProfile;
