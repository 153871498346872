import React from 'react';
import PropTypes from 'prop-types';

import { BadgeLabel } from '../../../../components/elements';
import { Button } from '../../../../components/core';

const ContactInfo = ({ contact, onEdit }) => (
  <div className="card">
    <div className="card-body">
      <h5>Detail Penerima</h5>
      <hr />
      <table className="table table-borderless table-sm">
        <tr>
          <td style={{ width: 130 }}>Nama</td>
          <td>{`: ${contact.name}`}</td>
        </tr>
        <tr>
          <td>No WhatsApp</td>
          <td>{`: ${contact.whatsapp}`}</td>
        </tr>
        <tr>
          <td>Alamat</td>
          <td>{`: ${contact.address} ${contact.subdistrict} ${contact.district} ${contact.province}`}</td>
        </tr>
        <tr>
          <td>Label</td>
          <td>
            :
            {' '}
            <BadgeLabel label={contact.label.name} color={contact.label.color} />
          </td>
        </tr>
      </table>
      <div className="d-flex justify-content-end">
        <Button variant="outline-primary px-4" onClick={() => onEdit(contact.id)}>EDIT</Button>
      </div>
    </div>
  </div>
);

ContactInfo.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    whatsapp: PropTypes.string,
    address: PropTypes.string,
    subdistrict: PropTypes.string,
    district: PropTypes.string,
    province: PropTypes.string,
    label: PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string,
    }),
  }),
  onEdit: PropTypes.func,
};

ContactInfo.defaultProps = {
  contact: {
    id: '',
    name: '',
    whatsapp: '',
    address: '',
    subdistrict: '',
    district: '',
    province: '',
    label: {
      name: '',
      color: '',
    },
  },
  onEdit: () => {},
};

export default ContactInfo;
