import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';

import orderEndpoint from '../../../../config/service/endpoint/order';
import { Table } from '../../../../components/elements';
import { toRupiah } from '../../../../helpers';
import { Button, InputField } from '../../../../components/core';

const ProductInfo = ({
  products, subtotal, discount, totalBill, orderId, initPaymentProof, onRefetch,
}) => {
  const [paymentProof, setPaymentProof] = useState('');

  const { mutate: savePaymentProof, isLoading: loadingPaymentProof } = useMutation(orderEndpoint.savePaymentProof, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Link bukti transfer berhasil disimpan.');
      onRefetch();
    },
  });

  const handleSavePaymentProof = () => {
    savePaymentProof({ id: orderId, payment_proof: paymentProof });
  };

  return (
    <div className="card">
      <div className="card-body">
        <h5>Detail Order</h5>
        <hr />
        <Table
          totalData={products.length}
          headRender={(
            <tr>
              <th>Produk</th>
              <th>Harga</th>
              <th>Qty</th>
              <th>Total Harga</th>
            </tr>
          )}
          bodyRender={(
            <>
              {products.map((product) => (
                <tr key={product.id}>
                  <td>{product.product_name}</td>
                  <td>{toRupiah(product.price)}</td>
                  <td>{[product.quantity]}</td>
                  <td>{toRupiah(product.price * product.quantity)}</td>
                </tr>
              ))}
              <tr>
                <td aria-label="empty" rowSpan={3} colSpan={2} />
                <td>Sub Total</td>
                <td>{toRupiah(subtotal)}</td>
              </tr>
              <tr>
                <td>Diskon</td>
                <td>{toRupiah(discount)}</td>
              </tr>
              <tr>
                <th>Total</th>
                <th>{toRupiah(totalBill)}</th>
              </tr>
            </>
          )}
        />
        <div className="d-flex align-items-center mt-2">
          {initPaymentProof ? (
            <div className="mr-2">
              <a
                href={initPaymentProof}
                className="btn btn-outline-primary"
                target="_blank"
                rel="noreferrer"
              >
                LIHAT BUKTI TRANSFER
              </a>
            </div>
          ) : (
            <>
              <InputField
                placeholder="Masukkan link bukti transfer"
                classNameContainer="mb-0 w-100 mr-2"
                value={paymentProof}
                onChange={(e) => setPaymentProof(e.target.value)}
              />
              <Button
                variant="outline-primary px-4"
                style={{ flexShrink: 0, cursor: paymentProof ? 'pointer' : 'not-allowed' }}
                disabled={!paymentProof}
                isLoading={loadingPaymentProof}
                onClick={handleSavePaymentProof}
              >
                SIMPAN
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

ProductInfo.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    product_name: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number,
  })),
  subtotal: PropTypes.number,
  discount: PropTypes.number,
  totalBill: PropTypes.number,
  orderId: PropTypes.string,
  initPaymentProof: PropTypes.string,
  onRefetch: PropTypes.func,
};

ProductInfo.defaultProps = {
  products: [],
  subtotal: 0,
  discount: 0,
  totalBill: 0,
  orderId: '',
  initPaymentProof: '',
  onRefetch: () => {},
};

export default ProductInfo;
