import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import OrderStatus from '../../Order/components/OrderStatus';
import contactEndpoint from '../../../config/service/endpoint/contact';
import { Modal, Table } from '../../../components/elements';
import { Button } from '../../../components/core';
import { toRupiah } from '../../../helpers';

const HistoryOrderModal = ({ contactId, show, onClose }) => {
  const { isFetching, data: orders } = useQuery(`order-histories-${contactId}`, () => contactEndpoint.getOrderHistory(contactId), {
    onError: (error) => toast.error(error.message),
  });

  const handleOpenDetail = (id) => {
    window.open(`/order/${id}`, '_blank');
  };

  return (
    <Modal
      show={show}
      size="lg"
      title="Riwayat Pembelian"
      onClose={onClose}
    >
      <Table
        totalData={orders?.data?.length || 0}
        perPage={orders?.data?.length || 0}
        isLoading={isFetching}
        headRender={(
          <tr>
            <th>ID</th>
            <th>Nominal</th>
            <th>Status</th>
            <th>Detail</th>
          </tr>
        )}
        bodyRender={orders?.data?.map((order) => (
          <tr key={order.id}>
            <td>{order.invoice_id}</td>
            <td>{toRupiah(order.price)}</td>
            <td aria-label="status">
              <OrderStatus status={order.order_status} />
              <br />
              <OrderStatus status={order.payment_status} />
            </td>
            <td width={50} aria-label="action">
              <Button size="sm" variant="success" onClick={() => handleOpenDetail(order.id)}>
                <i className="uil-eye" />
              </Button>
            </td>
          </tr>
        ))}
      />
    </Modal>
  );
};

HistoryOrderModal.propTypes = {
  contactId: PropTypes.number,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

HistoryOrderModal.defaultProps = {
  contactId: 0,
  show: false,
  onClose: () => {},
};

export default HistoryOrderModal;
