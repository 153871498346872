import configApi from '../configApi';

const getAll = (params) => configApi({ endpoint: 'dashboard/team-cs', params });
const getById = (id) => configApi({ endpoint: `dashboard/team-cs/${id}` });
const insert = (payload) => configApi({ endpoint: 'dashboard/team-cs', method: 'post', payload });
const update = ({ id, payload }) => configApi({ endpoint: `dashboard/team-cs/${id}`, method: 'put', payload });
const changeStatus = (id) => configApi({ endpoint: `dashboard/team-cs/status/${id}`, method: 'put' });
const deleteMultiple = (payload) => configApi({ endpoint: 'dashboard/team-cs', method: 'delete', payload });

const endpoint = {
  getAll,
  getById,
  insert,
  update,
  changeStatus,
  deleteMultiple,
};

export default endpoint;
