import React from 'react';
import Skeleton from 'react-loading-skeleton';

const FormLoadingSkeleton = () => (
  <>
    <div className="row">
      <div className="col-md-6">
        <div className="form-group">
          <Skeleton width={100} height={22} className="mb-2" />
          <Skeleton height={40} />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <Skeleton width={100} height={22} className="mb-2" />
          <Skeleton height={40} />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <Skeleton width={100} height={22} className="mb-2" />
          <Skeleton height={40} />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <Skeleton width={100} height={22} className="mb-2" />
          <Skeleton height={40} />
        </div>
      </div>
    </div>
    <div className="d-flex mt-2">
      <Skeleton width={100} height={40} className="mr-2" />
      <Skeleton width={80} height={40} />
    </div>
  </>
);

export default FormLoadingSkeleton;
