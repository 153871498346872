import React, { useState } from 'react';
import moment from 'moment';

import TopCS from './components/TopCS';
import TopProduct from './components/TopProduct';
import MonthlyOrder from './components/MonthlyOrder';
import FilterDateDropdown from './components/FilterDateDropdown';

const getStartDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
const getEndDate = moment().format('YYYY-MM-DD');

const ReportPage = () => {
  const [dateFilter, setDateFilter] = useState({
    startDate: getStartDate,
    endDate: getEndDate,
  });

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h4>Report</h4>
        <FilterDateDropdown onApply={setDateFilter} />
      </div>
      <div className="row">
        <TopCS dateFilter={dateFilter} />
        <TopProduct dateFilter={dateFilter} />
      </div>
      <MonthlyOrder />
    </>
  );
};

export default ReportPage;
