import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import FormModal from './components/FormModal';
import autoReplyEndpoint from '../../config/service/endpoint/autoReply';
import { Button, Checkbox } from '../../components/core';
import { AlertDialog, Table } from '../../components/elements';

const AutoReplyPage = () => {
  const [openedConfirmDelete, setOpenedConfirmDelete] = useState(false);
  const [openedFormModal, setOpenedFormModal] = useState(false);
  const [autoReplyDelete, setAutoReplyDelete] = useState(0);
  const [selectedData, setSelectedData] = useState([]);
  const [isCheckAll, setCheckAll] = useState(false);
  const [autoReplyEditData, setAutoReplyEditData] = useState({
    id: '',
    cs_id: '',
    keyword: '',
    reply: '',
    is_sensitive: false,
  });

  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { isFetching, data: autoReplies, refetch } = useQuery('auto-reply', () => autoReplyEndpoint.getAll({ page: currentPage }), {
    onError: (error) => toast.error(error.message),
  });

  const { mutate: deleteMutate, isLoading: isLoadingDelete } = useMutation(autoReplyEndpoint.deleteMultiple, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Auto reply berhasil dihapus.');
      setAutoReplyDelete(0);
      setSelectedData([]);
      setOpenedConfirmDelete(false);
      refetch();
    },
  });

  useEffect(() => {
    refetch();
    setCheckAll(false);
    setSelectedData([]);
  }, [searchParams]);

  const handleCheck = (id) => {
    const isExist = selectedData.some((selected) => selected === id);
    if (isExist) {
      setSelectedData((prevState) => prevState.filter((selected) => selected !== id));
    } else {
      setSelectedData((prevState) => ([
        ...prevState,
        id,
      ]));
    }
  };

  useEffect(() => {
    setCheckAll(selectedData.length === autoReplies?.data?.autoReplies?.length);
  }, [selectedData]);

  const handleCheckAll = () => {
    if (isCheckAll) {
      setCheckAll(false);
      setSelectedData([]);
    } else {
      const newAutoReplies = autoReplies?.data?.autoReplies?.map((autoReply) => autoReply.id);
      setSelectedData(newAutoReplies);
    }
  };

  const handleClickDeleteButton = () => {
    if (selectedData.length < 1) {
      toast.error('Pilih minimal 1 data yang akan dihapus.');
      return;
    }
    setOpenedConfirmDelete(true);
  };

  return (
    <>
      <h4 className="mb-4">Auto Reply</h4>
      <div className="card">
        <div className="card-header">
          <Button
            className="mr-2 px-3"
            onClick={() => {
              setOpenedFormModal(true);
              setAutoReplyEditData({
                id: '',
                keyword: '',
                reply: '',
                is_sensitive: false,
              });
            }}
          >
            Tambah
          </Button>
          <Button variant="danger" onClick={handleClickDeleteButton}>
            <i className="uil-trash-alt" />
          </Button>
        </div>
        <div className="card-body">
          <Table
            totalData={autoReplies?.data?.meta?.total}
            isLoading={isFetching}
            headRender={(
              <tr>
                <th aria-label="row" width="30px">
                  <Checkbox
                    id="checkAll"
                    checked={isCheckAll}
                    onChange={handleCheckAll}
                  />
                </th>
                <th>Customer Service</th>
                <th>Keyword</th>
                <th>Reply</th>
                <th>Case</th>
                <th>Action</th>
              </tr>
            )}
            bodyRender={autoReplies?.data?.autoReplies?.map((autoReply) => (
              <tr key={autoReply.id}>
                <td aria-label="row">
                  <Checkbox
                    id={`checkItem-${autoReply.id}`}
                    checked={selectedData.includes(autoReply.id)}
                    onChange={() => handleCheck(autoReply.id)}
                  />
                </td>
                <td>{autoReply.cs_name}</td>
                <td>{autoReply.keyword}</td>
                <td>{autoReply.reply}</td>
                <td>
                  <span className={autoReply.is_sensitive ? 'text-danger' : 'text-success'}>{autoReply.is_sensitive ? 'Sensitive' : 'Not Sensitive'}</span>
                </td>
                <td width={100} aria-label="action">
                  <Button
                    variant="success"
                    size="sm"
                    className="mr-1 mb-1"
                    onClick={() => {
                      setOpenedFormModal(true);
                      setAutoReplyEditData({
                        id: autoReply.id,
                        cs_id: autoReply.cs_id,
                        reply: autoReply.reply,
                        keyword: autoReply.keyword,
                        is_sensitive: autoReply.is_sensitive,
                      });
                    }}
                  >
                    <i className="uil-pen" />
                  </Button>
                  <Button variant="danger" size="sm" className="mb-1" onClick={() => setAutoReplyDelete(autoReply.id)}>
                    <i className="uil-trash-alt" />
                  </Button>
                </td>
              </tr>
            ))}
          />
        </div>
      </div>
      <AlertDialog
        isDanger
        show={!!autoReplyDelete || openedConfirmDelete}
        title="Hapus Auto Reply?"
        description={autoReplyDelete ? 'Apakah kamu yakin ingin menghapus auto reply ini?' : 'Apakah kamu yakin ingin menghapus auto reply yang dipilih?'}
        labelCancel="Batal"
        labelConfirm="Ya, Hapus"
        isLoadingConfirm={isLoadingDelete}
        onClose={() => {
          setAutoReplyDelete(0);
          setOpenedConfirmDelete(false);
        }}
        onConfirm={() => {
          deleteMutate({ ids: autoReplyDelete ? [autoReplyDelete] : selectedData });
        }}
      />
      <FormModal
        initialValues={autoReplyEditData}
        show={openedFormModal}
        onClose={() => setOpenedFormModal(false)}
        onFetch={refetch}
      />
    </>
  );
};

export default AutoReplyPage;
