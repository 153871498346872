import React from 'react';
import PropTypes from 'prop-types';
import { FONNTE_STATUS } from '../../../helpers';

const Status = ({ status }) => {
  let label;
  let color;
  let backgroundColor;
  switch (status) {
    case FONNTE_STATUS.PENDING:
    case FONNTE_STATUS.PROCESSING:
    case FONNTE_STATUS.SENT:
      label = 'Running';
      color = '#60481e';
      backgroundColor = '#fcf0db';
      break;
    case FONNTE_STATUS.DELIVERED:
    case FONNTE_STATUS.READ:
      label = 'Success';
      color = '#154e39';
      backgroundColor = '#d6f3e9';
      break;
    default:
      label = 'Unknown';
      color = '#000';
      backgroundColor = '#fff';
  }

  return (
    <p className="px-3 mb-0 rounded py-1 d-inline" style={{ color, backgroundColor }}>
      {label}
    </p>
  );
};

Status.propTypes = {
  status: PropTypes.oneOf([FONNTE_STATUS.PENDING, FONNTE_STATUS.PROCESSING, FONNTE_STATUS.SENT, FONNTE_STATUS.DELIVERED, FONNTE_STATUS.READ]).isRequired,
};

export default Status;
