import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ItemSkeleton = () => (
  <div className="col-md-4">
    <div className="card">
      <div className="card-body">
        <Skeleton width={100} height={20} />
        <Skeleton height={100} className="mt-3" />
      </div>
    </div>
  </div>
);

const DetailLoadingSkeleton = () => (
  <>
    <Skeleton width={180} height={25} className="mb-4" />
    <div className="row">
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
    </div>
  </>
);

export default DetailLoadingSkeleton;
