import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import reportEndpoint from '../../../config/service/endpoint/report';
import { Table } from '../../../components/elements';
import { toRupiah } from '../../../helpers';

const TopCS = ({ dateFilter }) => {
  const { isFetching, data, refetch } = useQuery('report-top-cs', () => reportEndpoint.getTopCS({ start_date: dateFilter.startDate, end_date: dateFilter.endDate }), {
    onError: (error) => toast.error(error.message),
  });

  useEffect(() => {
    refetch();
  }, [dateFilter]);

  return (
    <div className="col-md-6">
      <div className="card">
        <div className="card-body">
          <strong className="mb-3 d-block">Top CS</strong>
          <Table
            isLoading={isFetching}
            totalData={data?.data?.length}
            perPage={data?.data?.length}
            headRender={(
              <tr>
                <th>Nama CS</th>
                <th>Closing Rate</th>
                <th>Omset</th>
              </tr>
            )}
            bodyRender={data?.data?.map((cs) => (
              <tr key={cs.cs_id}>
                <td>{cs.cs_name}</td>
                <td>{cs.closing_rate}</td>
                <td>{toRupiah(cs.omset)}</td>
              </tr>
            ))}
          />
        </div>
      </div>
    </div>
  );
};

TopCS.propTypes = {
  dateFilter: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
};

export default TopCS;
