import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';

import { Button, Checkbox } from '../../../components/core';

export const templateMenus = [
  { label: 'Greeting', value: 'greeting' },
  { label: 'Welcome', value: 'welcome' },
  { label: 'Follow Up 1', value: 'follow_up_1' },
  { label: 'Follow Up 2', value: 'follow_up_2' },
  { label: 'Follow Up 3', value: 'follow_up_3' },
  { label: 'Complete', value: 'complete' },
  { label: 'Cancel', value: 'cancel' },
  { label: 'Up Sell', value: 'up_sell' },
  { label: 'Cross Sell', value: 'cross_sell' },
  { label: 'Rules COD', value: 'rules_cod' },
  { label: 'Form Order', value: 'form_order' },
  { label: 'Info Rekening', value: 'account_info' },
];

const CreateChooseTemplate = ({
  contents, formikContents, isLoadingSave, onSetContent, onSetVisibility, onBack, onSave,
}) => {
  const [tabMenu, setTabMenu] = useState(templateMenus[0].value);

  const contentSelected = contents[tabMenu];

  const formikContentSelected = formikContents.find((content) => content.name === tabMenu);

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-md-3">
            {templateMenus.map((menu) => (
              <Button
                key={menu.value}
                onClick={() => setTabMenu(menu.value)}
                variant={tabMenu === menu.value ? 'primary' : 'outline-secondary'}
                className="w-100 mb-3"
              >
                {menu.label}
              </Button>
            ))}
          </div>
          <div className="col-md-9">
            {contentSelected.length > 0 ? (
              <>
                {contentSelected.map((content, index) => (
                  <div key={content} className="d-flex justify-content-between border border-secondary rounded-lg p-3 mb-3" style={{ backgroundColor: '#FFF3CF' }}>
                    {content}
                    <Checkbox
                      name="content"
                      id={`content-${index + 1}`}
                      checked={formikContentSelected?.content === content}
                      onChange={() => onSetContent(tabMenu, content)}
                    />
                  </div>
                ))}
                {!!formikContentSelected && (
                  <Checkbox
                    name="is_show"
                    id="is_show"
                    label="Tampilkan"
                    checked={formikContentSelected.is_show}
                    onChange={() => onSetVisibility(tabMenu)}
                  />
                )}
              </>
            ) : (
              <Alert variant="warning">Tidak ada template untuk menu ini.</Alert>
            )}
            <div className="d-flex mt-3">
              <Button isLoading={isLoadingSave} variant="success" onClick={onSave} className="px-4 mr-2">SIMPAN</Button>
              <Button onClick={onBack} variant="secondary" className="px-4">KEMBALI</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateChooseTemplate.propTypes = {
  contents: PropTypes.shape({
    greeting: PropTypes.arrayOf(PropTypes.string),
    welcome: PropTypes.arrayOf(PropTypes.string),
    follow_up_1: PropTypes.arrayOf(PropTypes.string),
    follow_up_2: PropTypes.arrayOf(PropTypes.string),
    follow_up_3: PropTypes.arrayOf(PropTypes.string),
    complete: PropTypes.arrayOf(PropTypes.string),
    cancel: PropTypes.arrayOf(PropTypes.string),
    up_sell: PropTypes.arrayOf(PropTypes.string),
    cross_sell: PropTypes.arrayOf(PropTypes.string),
    rules_cod: PropTypes.arrayOf(PropTypes.string),
    form_order: PropTypes.arrayOf(PropTypes.string),
    account_info: PropTypes.arrayOf(PropTypes.string),
  }),
  formikContents: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.string,
    is_show: PropTypes.bool,
  })),
  isLoadingSave: PropTypes.bool,
  onSetContent: PropTypes.func,
  onSetVisibility: PropTypes.func,
  onBack: PropTypes.func,
  onSave: PropTypes.func,
};

CreateChooseTemplate.defaultProps = {
  contents: {
    greeting: [],
    welcome: [],
    follow_up_1: [],
    follow_up_2: [],
    follow_up_3: [],
    complete: [],
    cancel: [],
    up_sell: [],
    cross_sell: [],
    rules_cod: [],
    form_order: [],
    account_info: [],
  },
  formikContents: [],
  isLoadingSave: false,
  onSetContent: () => {},
  onSetVisibility: () => {},
  onBack: () => {},
  onSave: () => {},
};

export default CreateChooseTemplate;
