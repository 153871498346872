import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const RedirectToPortalWithAffiliate = () => {
  const params = useParams();

  useEffect(() => {
    if (!params.code) return;
    window.open(`${process.env.REACT_APP_PORTAL_URL}?aff=${params.code}`, '_self');
  }, [params.code]);

  return null;
};

export default RedirectToPortalWithAffiliate;
