import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import OrderSummary from './components/OrderSummary';
import Subscription from './components/Subscription';
import Chart from './components/Chart';
import { Dropdown } from '../../components/elements';
import { lastUpdateImage } from '../../assets';
import { useAppContext } from '../../hooks';

const filterOptions = [
  { value: 'all', label: 'Semua' },
  { value: 'today', label: 'Hari Ini' },
  { value: 'month', label: 'Bulan Ini' },
  { value: 'year', label: 'Tahun Ini' },
];

const DashboardPage = () => {
  const [filter, setFilter] = useState('all');
  const { setting } = useAppContext();

  return (
    <>
      <Helmet>
        <title>{`Dashboard - ${setting.businessName}`}</title>
      </Helmet>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h4 className="mb-0">Dashboard</h4>
        <Dropdown
          label={`Filter${filter === 'all' ? '' : `: ${filterOptions.find((option) => option.value === filter).label}`}`}
          data={filterOptions}
          onChoose={setFilter}
        />
      </div>
      <OrderSummary filter={filter} />
      <Chart />
      <div className="row">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <img src={lastUpdateImage} alt="last update" style={{ width: 30 }} />
                <h6 className="mb-0 ml-2">Last Update</h6>
              </div>
              <div className="d-flex mt-3">
                <div style={{ width: 30 }} />
                <div className="ml-2">
                  <h6 className="mb-1">V 1.6.0</h6>
                  <p className="text-muted mb-0">Changelog di grup telegram</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Subscription />
      </div>
    </>
  );
};

export default DashboardPage;
