import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { FormikProvider, useFormik } from 'formik';

import autoReplyEndpoint from '../../../config/service/endpoint/autoReply';
import teamCSEndpoint from '../../../config/service/endpoint/teamCS';
import { Modal } from '../../../components/elements';
import {
  Button, Checkbox, InputField, Select, Textarea,
} from '../../../components/core';

const FormModal = ({
  initialValues, show, onClose, onFetch,
}) => {
  const { data: teamCS, isLoading: teamCSLoading } = useQuery('team-cs', () => teamCSEndpoint.getAll({ limit: 1000 }), { onError: (error) => toast.error(error.message) });

  const { mutate, isLoading } = useMutation(initialValues.id ? autoReplyEndpoint.update : autoReplyEndpoint.insert, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success(initialValues.id ? 'Berhasil mengubah auto reply.' : 'Berhasil menambah auto reply.');
      onClose();
      onFetch();
    },
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      cs_id: '',
      keyword: '',
      reply: '',
      is_sensitive: false,
    },
    validationSchema: yup.object().shape({
      cs_id: yup.string().trim().required('CS harus dipilih.'),
      keyword: yup.string().trim().required('Nama label harus diisi.').max(50, 'Nama label maksimal 50 karakter.'),
      reply: yup.string().trim().required('Nama label harus diisi.'),
    }),
    onSubmit: (values) => {
      const payload = {
        cs_id: values.cs_id,
        keyword: values.keyword,
        reply: values.reply,
        is_sensitive: values.is_sensitive,
      };
      if (initialValues.id) {
        mutate({
          id: values.id,
          payload,
        });
      } else {
        mutate(payload);
      }
    },
  });

  useEffect(() => {
    formik.setValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (!show) formik.resetForm();
  }, [show]);

  return (
    <Modal
      show={show}
      title={`${initialValues.id ? 'Ubah' : 'Tambah'} Auto Reply`}
      onClose={onClose}
    >
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Select
            id="cs_id"
            name="cs_id"
            label="Pilih CS"
            placeholder={teamCSLoading ? 'Loading...' : 'Pilih salah satu'}
            data={teamCS?.data?.teamcs?.map((cs) => ({ label: cs.name, value: cs.id }))}
            value={formik.values.cs_id}
            error={formik.touched.cs_id && formik.errors.cs_id}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <InputField
            id="keyword"
            name="keyword"
            label="Keyword"
            placeholder="Halo"
            value={formik.values.keyword}
            error={formik.touched.keyword && formik.errors.keyword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Textarea
            id="reply"
            name="reply"
            label="Reply"
            placeholder="Halo juga, ada yang bisa kami bantu?"
            value={formik.values.reply}
            error={formik.touched.reply && formik.errors.reply}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Checkbox
            id="is_sensitive"
            name="is_sensitive"
            label="Case Sensitive?"
            error={formik.touched.is_sensitive && formik.errors.is_sensitive}
            checked={formik.values.is_sensitive}
            onChange={formik.handleChange}
          />
          <div className="d-flex justify-content-end mt-4">
            <Button isLoading={isLoading} type="submit" className="px-4">SIMPAN</Button>
          </div>
        </form>
      </FormikProvider>
    </Modal>
  );
};

FormModal.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    cs_id: PropTypes.string,
    keyword: PropTypes.string,
    reply: PropTypes.string,
    is_sensitive: PropTypes.bool,
  }),
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onFetch: PropTypes.func,
};

FormModal.defaultProps = {
  initialValues: {
    id: '',
    cs_id: '',
    keyword: '',
    reply: '',
    is_sensitive: false,
  },
  show: false,
  onClose: () => {},
  onFetch: () => {},
};

export default FormModal;
