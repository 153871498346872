import configApi from '../configApi';

const getAll = (params) => configApi({ endpoint: 'dashboard/template', params });
const getSource = (params) => configApi({ endpoint: 'dashboard/template/source', params });
const getById = (id, isEdit) => configApi({ endpoint: `dashboard/template/${id}${isEdit ? '?is_edit=true' : ''}` });
const insert = (payload) => configApi({ endpoint: 'dashboard/template', method: 'post', payload });
const update = (payload) => configApi({ endpoint: `dashboard/template/${payload.id}`, method: 'put', payload: { contents: payload.contents } });
const deleteMultiple = (payload) => configApi({ endpoint: 'dashboard/template', method: 'delete', payload });

const endpoint = {
  getAll,
  getSource,
  getById,
  insert,
  update,
  deleteMultiple,
};

export default endpoint;
