import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { utils as XLSXUtils, writeFile as XLSXWriteFile } from 'xlsx';
import { useMutation, useQuery } from 'react-query';

import labelEndpoint from '../../../config/service/endpoint/label';
import teamCSEndpoint from '../../../config/service/endpoint/teamCS';
import uploadEndpoint from '../../../config/service/endpoint/upload';
import contactEndpoint from '../../../config/service/endpoint/contact';
import { Modal } from '../../../components/elements';
import { Button, InputField } from '../../../components/core';

const ImportModal = ({ show, onClose, onRefetch }) => {
  const [fileExcel, setFileExcel] = useState();

  const { data: labelData } = useQuery('labels', labelEndpoint.getAll);
  const { data: teamcsData } = useQuery('teamcs', teamCSEndpoint.getAll);

  const { mutateAsync: mutateUpload, isLoading: isLoadingUpload } = useMutation(uploadEndpoint.uploadFile, {
    onError: (error) => toast.error(error.message),
  });

  const { mutate: mutateImport, isLoading: importLoading } = useMutation(contactEndpoint.importContact, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Import kontak berhasil');
      onClose();
      onRefetch();
    },
  });

  const handleDownloadHelper = () => {
    const workbook = XLSXUtils.book_new();
    const worksheetLabel = XLSXUtils.json_to_sheet(labelData.data.labels.map((label) => ({
      ID: label.id,
      Nama: label.name,
      'Jumlah Kontak': label.total_contact,
    })));
    const worksheetCS = XLSXUtils.json_to_sheet(teamcsData.data.teamcs.map((cs) => ({
      ID: cs.id,
      Nama: cs.name,
      WhatsApp: cs.whatsapp,
      Email: cs.email,
    })));
    XLSXUtils.book_append_sheet(workbook, worksheetLabel, 'Label');
    XLSXUtils.book_append_sheet(workbook, worksheetCS, 'CS');
    XLSXWriteFile(workbook, 'import_contact_helper.xlsx');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append('file', fileExcel);

      const response = await mutateUpload(formData);
      if (!response.success) throw new Error(response.message);
      mutateImport({ filepath: response.data.file_path });
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Modal
      show={show}
      title="Import Kontak"
      onClose={onClose}
    >
      <p className="mt-0 mb-2">Klik link dibawah untuk mengunduh template pengisian</p>
      <ul>
        <li>
          <a href="/assets/xlsx/template_import_contact.xlsx">template_import_contact.xlsx</a>
        </li>
        <li>
          <span
            role="button"
            className="link text-primary"
            tabIndex={0}
            onKeyDown={handleDownloadHelper}
            onClick={handleDownloadHelper}
          >
            import_contact_helper.xlsx
          </span>
        </li>
      </ul>
      <hr />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          {fileExcel && (
            <div className="mb-2">
              <span className="text-success">{`Filename: ${fileExcel?.name}`}</span>
            </div>
          )}
          <InputField
            type="file"
            name="file"
            id="file"
            value={undefined}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={(event) => setFileExcel(event.currentTarget.files[0])}
          />
          <Button type="submit" isLoading={isLoadingUpload || importLoading}>Upload File</Button>
        </div>
      </form>
    </Modal>
  );
};

ImportModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onRefetch: PropTypes.func,
};

ImportModal.defaultProps = {
  show: false,
  onClose: () => {},
  onRefetch: () => {},
};

export default ImportModal;
