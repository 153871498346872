import React from 'react';
import Skeleton from 'react-loading-skeleton';

const DetailLoadingSkeleton = () => (
  <div className="row">
    <div className="col-md-5">
      <div className="card">
        <div className="card-body">
          <div className="media">
            <Skeleton width={50} height={50} circle />
            <div className="media-body ml-3">
              <Skeleton width={120} height={25} className="mb-1" />
              <Skeleton width={150} height={20} />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6">
              <Skeleton height={40} />
            </div>
            <div className="col-md-6">
              <Skeleton height={40} />
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <Skeleton width={100} height={20} />
        </div>
        <div className="card-body">
          <Skeleton width={80} height={20} className="mb-1" />
          <Skeleton width={100} height={20} className="mb-3" />
          <Skeleton width={70} height={20} className="mb-1" />
          <Skeleton width={150} height={20} className="mb-3" />
          <Skeleton width={80} height={20} className="mb-1" />
          <Skeleton width={120} height={20} className="mb-3" />
          <Skeleton width={100} height={20} className="mb-1" />
          <Skeleton width={170} height={20} className="mb-3" />
          <Skeleton width={70} height={20} className="mb-1" />
          <Skeleton width={120} height={20} className="mb-3" />
        </div>
      </div>
    </div>
    <div className="col-md-7">
      <div className="card">
        <div className="card-header d-flex">
          <Skeleton width={80} height={40} className="mr-2" />
          <Skeleton width={120} height={40} />
        </div>
        <div className="card-body">
          <Skeleton height={150} />
          <div className="d-flex justify-content-end mt-3">
            <Skeleton width={100} height={40} />
          </div>
        </div>
      </div>
      <Skeleton width={100} height={20} />
      <div className="card shadow-none mt-3 mb-3">
        <div className="card-body">
          <div className="media">
            <div className="media-body">
              <Skeleton width={100} height={23} />
              <Skeleton width={200} height={20} className="mt-2" />
            </div>
            <Skeleton width={45} height={45} circle />
          </div>
        </div>
      </div>
      <div className="card shadow-none">
        <div className="card-body">
          <div className="media">
            <div className="media-body">
              <Skeleton width={100} height={23} />
              <Skeleton width={200} height={20} className="mt-2" />
            </div>
            <Skeleton width={45} height={45} circle />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default DetailLoadingSkeleton;
