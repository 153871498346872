import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { MENU_TEMPLATE_ORDER } from '../utils';

const Menu = ({ selectedMenu }) => (
  <>
    <ul className="nav">
      {MENU_TEMPLATE_ORDER.map((menu) => (
        <li className="nav-item" key={menu.type}>
          <Link to={menu.url} className={`nav-link ${menu.type === selectedMenu ? 'text-primary' : 'text-secondary'}`}>
            {menu.name}
          </Link>
        </li>
      ))}
    </ul>
    <hr />
  </>
);

Menu.propTypes = {
  selectedMenu: PropTypes.string.isRequired,
};

export default Menu;
