import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useDebounce } from '@uidotdev/usehooks';

// import FilterDateDropdown from './components/FilterDateDropdown';
import productEndpoint from '../../config/service/endpoint/product';
import { AlertDialog, Table } from '../../components/elements';
import { Button, Checkbox, InputField } from '../../components/core';
import { toRupiah } from '../../helpers';

const ProductPage = () => {
  const [productDelete, setProductDelete] = useState(0);
  const [openedConfirmDelete, setOpenedConfirmDelete] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isCheckAll, setCheckAll] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [dateFilter] = useState({
    startDate: '',
    endDate: '',
  });

  const debouncedSearch = useDebounce(searchValue, 500);
  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { isFetching, data: products, refetch } = useQuery('products', () => productEndpoint.getAll({
    page: currentPage, search: searchValue, start_date: dateFilter.startDate, end_date: dateFilter.endDate,
  }), {
    onError: (error) => toast.error(error.message),
    onSuccess: () => setLoadingSearch(false),
  });

  const { mutate: deleteMutate, isLoading: isLoadingDelete } = useMutation(productEndpoint.deleteMultiple, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Produk berhasil dihapus.');
      setProductDelete(0);
      setSelectedData([]);
      setOpenedConfirmDelete(false);
      refetch();
    },
  });

  useEffect(() => {
    refetch();
    setCheckAll(false);
    setSelectedData([]);
  }, [searchParams]);

  useEffect(() => {
    refetch();
  }, [debouncedSearch, dateFilter]);

  useEffect(() => {
    setCheckAll((selectedData.length === products?.data?.products?.length));
  }, [selectedData]);

  const handleCheckAll = () => {
    if (isCheckAll) {
      setCheckAll(false);
      setSelectedData([]);
    } else {
      const newProducts = products?.data?.products.map((product) => product.id);
      setSelectedData(newProducts);
    }
  };

  const handleCheck = (id) => {
    const isExist = selectedData.some((selected) => selected === id);
    if (isExist) {
      setSelectedData((prevState) => prevState.filter((selected) => selected !== id));
    } else {
      setSelectedData((prevState) => ([
        ...prevState,
        id,
      ]));
    }
  };

  const handleClickDeleteButton = () => {
    if (selectedData.length < 1) {
      toast.error('Pilih minimal 1 data yang akan dihapus.');
      return;
    }
    setOpenedConfirmDelete(true);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h4>Product</h4>
        {/* <FilterDateDropdown onApply={setDateFilter} /> */}
      </div>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between flex-action-button">
          <div className="flex-action-button">
            <Link to="/product/add">
              <Button className="mr-2 px-4">
                Tambah
              </Button>
            </Link>
            <Button variant="danger" onClick={handleClickDeleteButton}>
              <i className="uil-trash-alt" />
            </Button>
          </div>
          <form>
            <InputField
              placeholder="Search"
              classNameContainer="mb-0"
              value={searchValue}
              onChange={(event) => {
                setSearchValue(event.target.value);
                setLoadingSearch(true);
              }}
            />
          </form>
        </div>
        <div className="card-body">
          <Table
            totalData={products?.data?.meta?.total}
            isLoading={isFetching || loadingSearch}
            headRender={(
              <tr>
                <th aria-label="row" width="30px">
                  <Checkbox
                    id="checkAll"
                    checked={isCheckAll}
                    onChange={handleCheckAll}
                  />
                </th>
                <th>Nama Produk</th>
                <th>Harga</th>
                <th>Order</th>
                <th>Paid</th>
                <th>Paid Ratio</th>
                <th>Revenue</th>
                <th>Action</th>
              </tr>
            )}
            bodyRender={products?.data?.products?.map((product) => (
              <tr key={product.id}>
                <td aria-label="row">
                  <Checkbox
                    id={`checkItem-${product.id}`}
                    checked={selectedData.includes(product.id)}
                    onChange={() => handleCheck(product.id)}
                  />
                </td>
                <td>
                  {product.product_name}
                  <p className="mb-0 text-secondary">{`SKU : ${product.sku}`}</p>
                </td>
                <td>{toRupiah(product.price)}</td>
                <td>{product.total_order}</td>
                <td>{product.total_paid}</td>
                <td>{product.paid_ratio}</td>
                <td>{toRupiah(product.revenue)}</td>
                <td width={100} aria-label="action">
                  <Link to={`/product/${product.id}`}>
                    <Button variant="success" size="sm" className="mr-1 mb-1">
                      <i className="uil-pen" />
                    </Button>
                  </Link>
                  <Button variant="danger" size="sm" className="mb-1" onClick={() => setProductDelete(product.id)}>
                    <i className="uil-trash-alt" />
                  </Button>
                </td>
              </tr>
            ))}
          />
        </div>
      </div>
      <AlertDialog
        isDanger
        show={!!productDelete || openedConfirmDelete}
        title="Hapus Produk?"
        description={productDelete ? 'Apakah kamu yakin ingin menghapus produk ini?' : 'Apakah kamu yakin ingin menghapus produk yang dipilih?'}
        labelCancel="Batal"
        labelConfirm="Ya, Hapus"
        isLoadingConfirm={isLoadingDelete}
        onClose={() => {
          setProductDelete(0);
          setOpenedConfirmDelete(false);
        }}
        onConfirm={() => {
          deleteMutate({ ids: productDelete ? [productDelete] : selectedData });
        }}
      />
    </>
  );
};

export default ProductPage;
