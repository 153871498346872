import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import accountEndpoint from '../../../config/service/endpoint/account';
import { Modal, Table } from '../../../components/elements';
import { getLocalStorage, toRupiah } from '../../../helpers';
import { Button } from '../../../components/core';

const ProductsModal = ({ show, onClose }) => {
  const { data, isFetching } = useQuery('root-products', accountEndpoint.getAllProduct, {
    onError: (error) => toast.error(error.message),
  });

  const handleChoosePackage = (item) => {
    const tokenStorage = getLocalStorage('token');
    window.open(`${process.env.REACT_APP_CHECKOUT_URL}${item.slug}?client_id=${item.client_id}&token=${tokenStorage}`, '_blank');
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      title="Pilih Produk"
      size="lg"
    >
      <Table
        totalData={data?.data.length}
        isLoading={isFetching}
        perPage={data?.data.length}
        headRender={(
          <tr>
            <th>Foto</th>
            <th>Judul Produk</th>
            <th>Harga</th>
            <th>Aksi</th>
          </tr>
        )}
        bodyRender={data?.data?.map((item) => (
          <tr key={item.id}>
            <td>
              <img src={`${process.env.REACT_APP_SERVICE_URL_API}uploads/${item.image}`} alt={item.product_name} style={{ width: 70 }} />
            </td>
            <td>{item.product_name}</td>
            <td>{toRupiah(item.price)}</td>
            <td width={120} aria-label="action">
              <Button variant="success" onClick={() => handleChoosePackage(item)}>
                Pilih Paket
              </Button>
            </td>
          </tr>
        ))}
      />
    </Modal>
  );
};

ProductsModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

ProductsModal.defaultProps = {
  show: false,
  onClose: () => {},
};

export default ProductsModal;
