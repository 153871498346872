import React from 'react';
import Skeleton from 'react-loading-skeleton';

const FormGroupSkeleton = () => (
  <div className="form-group">
    <Skeleton width={100} height={22} className="mb-2" />
    <Skeleton height={40} />
  </div>
);

const LoadingSkeleton = () => (
  <>
    <Skeleton width={200} height={30} className="mb-4" />
    <div className="card">
      <div className="card-body">
        <Skeleton height={45} />
        <div className="row mt-3">
          <div className="col-md-6">
            <div className="p-4 rounded-lg border">
              <FormGroupSkeleton />
              <FormGroupSkeleton />
              <FormGroupSkeleton />
            </div>
          </div>
          <div className="col-md-6">
            <div className="p-4 rounded-lg bg-light">
              <FormGroupSkeleton />
              <FormGroupSkeleton />
              <FormGroupSkeleton />
            </div>
          </div>
        </div>
        <Skeleton width={120} height={40} className="mt-3" />
      </div>
    </div>
  </>
);

export default LoadingSkeleton;
