import React from 'react';

import { error404Image } from '../../assets';

const Error404 = () => (
  <div className="my-5 pt-sm-5">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="text-center">
            <div>
              <div className="row justify-content-center">
                <div className="col-sm-4">
                  <div className="error-img">
                    <img src={error404Image} alt="404 error" className="img-fluid mx-auto d-block" />
                  </div>
                </div>
              </div>
            </div>
            <h4 className="text-uppercase mt-4">
              Upps, akses ditolak
            </h4>
            <p className="text-muted">
              Kamu tidak diizinkan untuk mengakses halaman ini.
            </p>
            <div className="mt-5">
              <a className="btn btn-primary waves-effect waves-light" href="/">Kembali ke Dashboard</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Error404;
