import React, { useEffect } from 'react';
import EmojiPicker from 'emoji-picker-react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { Dropdown as DropdownBootstrap } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import {
  BtnBold, BtnItalic, BtnStrikeThrough, EditorProvider, Editor, Toolbar,
} from 'react-simple-wysiwyg';

import Menu from './components/Menu';
import LoadingSkeleton from './components/LoadingSkeleton';
import templateOrderEndpoint from '../../config/service/endpoint/templateOrder';
import { Button } from '../../components/core';
import { MENU_TEMPLATE_ORDER } from './utils';

const TemplateOrderPage = () => {
  const params = useParams();

  const selectedType = MENU_TEMPLATE_ORDER.find((menu) => menu.type === params.type) || MENU_TEMPLATE_ORDER[0];

  const { data, isFetching, refetch } = useQuery(`template-order-${selectedType.type}`, () => templateOrderEndpoint.getByType(selectedType.type), {
    onError: (error) => toast.error(error.message),
  });

  const { mutate: updateMutate, isLoading: loadingMutate } = useMutation(templateOrderEndpoint.update, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil menyimpan template order.');
      refetch();
    },
  });

  const formik = useFormik({
    initialValues: { content: '' },
    validationSchema: yup.object({
      content: yup.string().trim().required('Template order harus diisi.'),
    }),
    onSubmit: (values) => {
      updateMutate({
        type: selectedType.type,
        content: values.content,
      });
    },
  });

  useEffect(() => {
    if (data?.data) {
      formik.resetForm();
      formik.setFieldValue('content', data?.data?.content);
    }
  }, [data]);

  const handleAddEmoji = ({ emoji }) => {
    formik.setFieldValue('content', `${formik.values.content}${emoji}`);
  };

  return (
    <>
      <h4 className="mb-4">Template Order</h4>
      <div className="card">
        <div className="card-body">
          <Menu selectedMenu={selectedType.type} />
          {isFetching ? <LoadingSkeleton /> : (
            <div className="row mt-4">
              <div className="col-md-6 mb-4">
                <DropdownBootstrap style={{ position: 'absolute', right: 12, top: 1 }}>
                  <DropdownBootstrap.Toggle size="sm" variant="light">
                    🙂
                  </DropdownBootstrap.Toggle>
                  <DropdownBootstrap.Menu>
                    <EmojiPicker
                      previewConfig={{ showPreview: false }}
                      onEmojiClick={handleAddEmoji}
                      emojiStyle="native"
                      height={350}
                    />
                  </DropdownBootstrap.Menu>
                </DropdownBootstrap>
                <FormikProvider value={formik}>
                  <form onSubmit={formik.handleSubmit}>
                    <EditorProvider>
                      <Editor
                        name="content"
                        value={formik.values.content}
                        containerProps={{ style: { minHeight: 300 } }}
                        onBlur={formik.handleBlur}
                        onChange={(event) => {
                          let newValue = event.target.value;
                          const tempDiv = document.createElement('div');
                          tempDiv.innerHTML = newValue;
                          const span = tempDiv.querySelector('span');
                          if (span) {
                            const spanContent = span.innerHTML.replace(/\n/g, '<br>');
                            span.parentNode.innerHTML = spanContent + span.parentNode.innerHTML.replace(span.outerHTML, '');
                          }
                          newValue = tempDiv.innerHTML;
                          formik.setFieldValue('content', newValue);
                        }}
                      >
                        <Toolbar>
                          <BtnBold />
                          <BtnItalic />
                          <BtnStrikeThrough />
                        </Toolbar>
                      </Editor>
                    </EditorProvider>
                    {formik.touched.content && formik.errors.content && (
                      <small className="text-danger d-block">{formik.errors.content}</small>
                    )}
                    <Button isLoading={loadingMutate} type="submit" className="px-4 mt-3">Simpan</Button>
                  </form>
                </FormikProvider>
              </div>
              <div className="col-md-6 mb-4">
                <p className="mb-2">Info Kode</p>
                <ul>
                  <li>{'{{name}}: Nama customer'}</li>
                  <li>{'{{phone}}: Nomor telepon customer'}</li>
                  <li>{'{{address}}: Alamat customer'}</li>
                  <li>{'{{product_name}}: Nama produk. Akan dipisahkan dengan koma jika lebih dari satu'}</li>
                  <li>{'{{product_qty}}: Quantity produk. Akan dipisahkan dengan koma jika lebih dari satu'}</li>
                  <li>{'{{product_price}}: Harga produk. Akan dipisahkan dengan koma jika lebih dari satu'}</li>
                  <li>{'{{total_price}}: Total harga'}</li>
                  <li>{'{{nama_cs}}: Nama CS'}</li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TemplateOrderPage;
