import React from 'react';
import PropTypes from 'prop-types';

import { BadgeLabel } from '../../../../components/elements';

const DetailItem = ({ label, value }) => (
  <>
    <p className="mb-1 text-secondary">{label}</p>
    {typeof value === 'string' ? <p className="font-weight-bold">{value}</p> : value}
  </>
);

DetailItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

const DetailProfile = ({
  name, whatsapp, email, address, subdistrict, district, province, postal_code: postalCode, label,
}) => (
  <div className="card">
    <div className="card-header">Detail Kontak</div>
    <div className="card-body">
      <DetailItem label="Nama" value={name} />
      <DetailItem label="No WhatsApp" value={whatsapp} />
      <DetailItem label="Email" value={email || '-'} />
      <DetailItem label="Alamat" value={address || '-'} />
      <DetailItem label="Kecamatan" value={subdistrict || '-'} />
      <DetailItem label="Kab./Kota - Provinsi" value={`${district} - ${province}`} />
      <DetailItem label="Kode Pos" value={postalCode || '-'} />
      <DetailItem label="List" value={<BadgeLabel label={label.name} color={label.color} />} />
    </div>
  </div>
);

DetailProfile.propTypes = {
  name: PropTypes.string,
  whatsapp: PropTypes.string,
  email: PropTypes.string,
  address: PropTypes.string,
  subdistrict: PropTypes.string,
  district: PropTypes.string,
  province: PropTypes.string,
  postal_code: PropTypes.string,
  label: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
  }),
};

DetailProfile.defaultProps = {
  name: '',
  whatsapp: '',
  email: '',
  address: '',
  subdistrict: '',
  district: '',
  province: '',
  postal_code: '',
  label: {
    name: '',
    color: '',
  },
};

export default DetailProfile;
