import React from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { Alert } from 'react-bootstrap';
import { FormikProvider, useFormik } from 'formik';
import { useMutation } from 'react-query';

import accountEndpoint from '../../../config/service/endpoint/account';
import { Button, InputField } from '../../../components/core';

const AccountChangePasswordPage = () => {
  const { mutateAsync: mutateChangePassword, isLoading } = useMutation(accountEndpoint.changePassword, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => toast.success('Password berhasil diubah'),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    validationSchema: yup.object({
      oldPassword: yup.string().trim().required('Password lama wajib diisi'),
      newPassword: yup.string().trim().required('Password baru wajib diisi').min(5, 'Password baru minimal 5 karakter'),
    }),
    onSubmit: async (values) => {
      await mutateChangePassword({
        old_password: values.oldPassword,
        new_password: values.newPassword,
      });
      formik.resetForm();
    },
  });

  return (
    <>
      <h4 className="mb-4">
        Akun |
        {' '}
        <span className="text-primary">Ubah Password</span>
      </h4>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <Alert variant="secondary">
                <i className="uil uil-info-circle mr-1" />
                Hubungi admin jika lupa password lama.
              </Alert>
              <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                  <InputField
                    id="oldPassword"
                    name="oldPassword"
                    label="Password Lama"
                    value={formik.values.oldPassword}
                    error={formik.touched.oldPassword && formik.errors.oldPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <InputField
                    id="newPassword"
                    name="newPassword"
                    label="Password Baru"
                    value={formik.values.newPassword}
                    error={formik.touched.newPassword && formik.errors.newPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <Button
                    type="submit"
                    variant="success"
                    className="px-3"
                    isLoading={isLoading}
                  >
                    Ubah Password
                  </Button>
                </form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountChangePasswordPage;
