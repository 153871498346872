import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Modal } from '../../../components/elements';
import { Button } from '../../../components/core';

const EmbedCodeModal = ({
  id, token, trackingType, trackingId, show, onClose,
}) => {
  const code = `<link href=${process.env.REACT_APP_BASE_URL}embed/style.css rel=stylesheet><div class=appsite-form><input id="appsite-id" value="${id}--${token}--${trackingType}--${trackingId}--${process.env.REACT_APP_SERVICE_URL_API}--#075E54--Beli Sekarang"></div><script src=${process.env.REACT_APP_BASE_URL}embed/script.js></script>`;

  const handleCopy = () => toast.success('Berhasil menyalin kode embed.');

  return (
    <Modal
      show={show}
      title="Embed Code"
      onClose={onClose}
    >
      <p className="mb-2">Salin kode di bawah ini dan tempelkan ke situs web Anda</p>
      <div className="bg-light rounded-lg p-3" style={{ wordBreak: 'break-all' }}>
        {code}
      </div>
      <div className="d-flex justify-content-end mt-3">
        <CopyToClipboard text={code} onCopy={handleCopy}>
          <Button className="px-4">Copy</Button>
        </CopyToClipboard>
      </div>
    </Modal>
  );
};

EmbedCodeModal.propTypes = {
  id: PropTypes.string,
  token: PropTypes.string,
  trackingType: PropTypes.string,
  trackingId: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

EmbedCodeModal.defaultProps = {
  id: '',
  token: '',
  trackingType: '',
  trackingId: '',
  show: false,
  onClose: () => {},
};

export default EmbedCodeModal;
