import React from 'react';
import PropTypes from 'prop-types';
import { Button as ButtonBootstrap, Spinner } from 'react-bootstrap';

const Button = ({
  type,
  variant,
  size,
  className,
  innerClassName,
  disabled,
  isLoading,
  style,
  children,
  onClick,
}) => (
  <ButtonBootstrap
    type={type}
    size={size}
    variant={variant}
    disabled={disabled || isLoading}
    className={className}
    style={style}
    onClick={onClick}
  >
    <div className={`d-flex align-items-center justify-content-center ${innerClassName}`}>
      {isLoading && <Spinner size="sm" className="mr-2" animation="border" role="status" />}
      {children}
    </div>
  </ButtonBootstrap>
);

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variant: PropTypes.oneOf([
    'primary', 'light', 'success', 'info', 'warning', 'danger', 'dark', 'link', 'secondary', 'outline-primary', 'outline-secondary', 'outline-danger',
  ]),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  size: 'md',
  className: '',
  innerClassName: '',
  disabled: false,
  isLoading: false,
  style: {},
  onClick: () => {},
};

export default Button;
