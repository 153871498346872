import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import * as yup from 'yup';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { FormikProvider, useFormik } from 'formik';

import LoadingSkeleton from './LoadingSkeleton';
import accountEndpoint from '../../../config/service/endpoint/account';
import { Button, InputField } from '../../../components/core';
import { getLocalStorage } from '../../../helpers';

const validationSchema = yup.object().shape({
  name: yup.string().trim().required('Nama lengkap harus diisi.').max(50, 'Nama lengkap maksimal 50 karakter.'),
  whatsapp: yup.string().trim().required('No WhatsApp harus diisi.').max(15, 'No WhatsApp maksimal 15 karakter.'),
  bankName: yup.string().trim().required('Nama bank harus diisi.').max(50, 'Nama bank maksimal 50 karakter.'),
  accountNumber: yup.string().trim().required('No rekening harus diisi.').max(20, 'No rekening maksimal 20 karakter.'),
  accountName: yup.string().trim().required('Nama pemilik rekening harus diisi.').max(50, 'Nama pemilik rekening maksimal 50 karakter.'),
});

const accessTypeStorage = getLocalStorage('token_type');

const AccountProfilePage = () => {
  const { isFetching, data, refetch } = useQuery('profile', accountEndpoint.getProfile, { onError: (error) => toast.error(error.message) });

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(accountEndpoint.updateProfile, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Profil berhasil diperbarui.');
      refetch();
    },
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      whatsapp: '',
      bankName: '',
      accountNumber: '',
      accountName: '',
    },
    validationSchema,
    onSubmit: (values) => {
      updateMutate({
        name: values.name,
        whatsapp: values.whatsapp,
        bank_name: values.bankName,
        account_number: values.accountNumber,
        account_name: values.accountName,
      });
    },
  });

  useEffect(() => {
    if (data) {
      const profile = data?.data;
      formik.setValues({
        name: profile?.name,
        email: profile?.email,
        whatsapp: profile?.whatsapp,
        bankName: profile?.bank_name,
        accountNumber: profile?.account_number,
        accountName: profile?.account_name,
      });
    }
  }, [data]);

  if (isFetching) return <LoadingSkeleton />;

  return (
    <>
      <h4 className="mb-4">
        Akun |
        {' '}
        <span className="text-primary">Profil</span>
      </h4>
      <div className="card">
        <div className="card-body">
          {accessTypeStorage === 'general' && (
            data?.data?.is_expired ? (
              <Alert variant="danger">
                Paket anda sudah berakhir. Silakan perpanjang pada halaman Invoice atau
                {' '}
                <Link to="/akun/invoice">
                  klik disini
                </Link>
              </Alert>
            ) : (
              <Alert variant="info">
                {`Paket anda akan berakhir pada : ${moment(data?.data?.expired_date).format('DD-MM-YYYY')}`}
              </Alert>
            )
          )}
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="p-4 rounded-lg border">
                    <InputField
                      id="name"
                      name="name"
                      label="Nama Lengkap"
                      value={formik.values.name}
                      error={formik.touched.name && formik.errors.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <InputField
                      readOnly
                      id="email"
                      name="email"
                      label="Alamat Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      classNameInput="bg-light"
                    />
                    <InputField
                      id="whatsapp"
                      name="whatsapp"
                      label="No WhatsApp"
                      classNameContainer="mb-0"
                      value={formik.values.whatsapp}
                      error={formik.touched.whatsapp && formik.errors.whatsapp}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="p-4 rounded-lg bg-light">
                    <InputField
                      id="bankName"
                      name="bankName"
                      label="Nama Bank"
                      value={formik.values.bankName}
                      error={formik.touched.bankName && formik.errors.bankName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <InputField
                      id="accountNumber"
                      name="accountNumber"
                      label="No Rekening"
                      value={formik.values.accountNumber}
                      error={formik.touched.accountNumber && formik.errors.accountNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <InputField
                      id="accountName"
                      name="accountName"
                      label="Nama Pemilik Rekening"
                      classNameContainer="mb-0"
                      value={formik.values.accountName}
                      error={formik.touched.accountName && formik.errors.accountName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
              </div>
              <Button
                type="submit"
                className="mt-3 px-3"
                isLoading={updateLoading}
              >
                SIMPAN
              </Button>
            </form>
          </FormikProvider>
        </div>
      </div>
    </>
  );
};

export default AccountProfilePage;
