import React from 'react';
import PropTypes from 'prop-types';

const Textarea = ({
  id, name, label, rows, placeholder, helperText, error, classNameContainer, value, onChange, onBlur,
}) => (
  <div className={`form-group ${classNameContainer}`}>
    {label && <label htmlFor={id}>{label}</label>}
    <textarea
      id={id}
      name={name}
      placeholder={placeholder}
      rows={rows}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      className={`form-control ${error ? 'is-invalid' : ''}`}
    />
    {typeof helperText === 'string' ? <small className="text-muted">{helperText}</small> : helperText}
    {error && <small className="text-danger mt-1 d-block">{error}</small>}
  </div>
);

Textarea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  rows: PropTypes.number,
  classNameContainer: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

Textarea.defaultProps = {
  id: '',
  name: '',
  label: '',
  rows: 3,
  classNameContainer: '',
  error: '',
  helperText: null,
  placeholder: '',
  value: '',
  onChange: () => {},
  onBlur: () => {},
};

export default Textarea;
