import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { utils as XLSXUtils, writeFile as XLSXWriteFile } from 'xlsx';
import { Link, useSearchParams } from 'react-router-dom';
import { useDebounce } from '@uidotdev/usehooks';
import { useMutation, useQuery } from 'react-query';

import FormContactModal from './components/FormContactModal';
import ImportModal from './components/ImportModal';
import contactEndpoint from '../../config/service/endpoint/contact';
import { Button, Checkbox, InputField } from '../../components/core';
import { AlertDialog, BadgeLabel, Table } from '../../components/elements';

const ContactPage = () => {
  const [openedFormModal, setOpenedFormModal] = useState(false);
  const [openedImportModal, setOpenedImportModal] = useState(false);
  const [openedConfirmDelete, setOpenedConfirmDelete] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isCheckAll, setCheckAll] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  const debouncedSearch = useDebounce(searchValue, 500);
  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { isFetching, data: contacts, refetch } = useQuery('contacts', () => contactEndpoint.getAll({ page: currentPage, search: searchValue }), {
    onError: (error) => toast.error(error.message),
    onSuccess: () => setLoadingSearch(false),
  });

  const { isFetching: downloadFetching, refetch: handleDownload } = useQuery('download-contacts', contactEndpoint.getDownload, {
    enabled: false,
    onError: (error) => toast.error(error.message),
    onSuccess: (data) => {
      const { data: contactsData } = data;
      if (contactsData.length < 1) {
        toast.error('Data contact kosong.');
        return;
      }
      const workbook = XLSXUtils.book_new();
      const worksheet = XLSXUtils.json_to_sheet(contactsData);
      XLSXUtils.book_append_sheet(workbook, worksheet, 'Contacts');
      XLSXWriteFile(workbook, 'contacts.xlsx');
      toast.success('Data contact berhasil diexport.');
    },
  });

  const { mutate: deleteMutate, isLoading: isLoadingDelete } = useMutation(contactEndpoint.deleteMultiple, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Contact berhasil dihapus.');
      setSelectedData([]);
      setOpenedConfirmDelete(false);
      refetch();
    },
  });

  useEffect(() => {
    refetch();
    setCheckAll(false);
    setSelectedData([]);
  }, [searchParams]);

  useEffect(() => {
    refetch();
  }, [debouncedSearch]);

  useEffect(() => {
    setCheckAll((selectedData.length === contacts?.data?.contacts?.length));
  }, [selectedData]);

  const handleCheckAll = () => {
    if (isCheckAll) {
      setCheckAll(false);
      setSelectedData([]);
    } else {
      const newContacts = contacts?.data?.contacts.map((contact) => contact.id);
      setSelectedData(newContacts);
    }
  };

  const handleCheck = (id) => {
    const isExist = selectedData.some((selected) => selected === id);
    if (isExist) {
      setSelectedData((prevState) => prevState.filter((selected) => selected !== id));
    } else {
      setSelectedData((prevState) => ([
        ...prevState,
        id,
      ]));
    }
  };

  const handleClickDeleteButton = () => {
    if (selectedData.length < 1) {
      toast.error('Pilih minimal 1 data yang akan dihapus.');
      return;
    }
    setOpenedConfirmDelete(true);
  };

  return (
    <>
      <h4 className="mb-4">Contact</h4>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between flex-action-button">
          <div className="flex-action-button">
            <Button className="mr-2 px-4" onClick={() => setOpenedFormModal(true)}>
              Tambah
            </Button>
            <Button variant="danger" onClick={handleClickDeleteButton}>
              <i className="uil-trash-alt" />
            </Button>
          </div>
          <div className="d-flex align-items-center flex-action-button">
            <form>
              <InputField
                placeholder="Search"
                classNameContainer="mb-0 mr-2"
                value={searchValue}
                onChange={(event) => {
                  setSearchValue(event.target.value);
                  setLoadingSearch(true);
                }}
              />
            </form>
            <Button variant="info" className="px-4 mr-2" onClick={() => setOpenedImportModal(true)}>Import</Button>
            <Button
              variant="success"
              className="px-4 mr-2"
              isLoading={downloadFetching}
              onClick={handleDownload}
            >
              Export
            </Button>
          </div>
        </div>
        <div className="card-body">
          <Table
            totalData={contacts?.data?.meta?.total}
            isLoading={isFetching || loadingSearch}
            headRender={(
              <tr>
                <th aria-label="row" width="30px">
                  <Checkbox
                    id="checkAll"
                    checked={isCheckAll}
                    onChange={handleCheckAll}
                  />
                </th>
                <th>Nama</th>
                <th>No WhatsApp</th>
                <th>Label</th>
                <th>Customer Service</th>
                <th>Action</th>
              </tr>
            )}
            bodyRender={contacts?.data?.contacts?.map((contact) => (
              <tr key={contact.id}>
                <td aria-label="row">
                  <Checkbox
                    id={`checkItem-${contact.id}`}
                    checked={selectedData.includes(contact.id)}
                    onChange={() => handleCheck(contact.id)}
                  />
                </td>
                <td>{contact.name}</td>
                <td>{contact.whatsapp}</td>
                <td aria-label="label">
                  <BadgeLabel label={contact.label.name} color={contact.label.color} />
                </td>
                <td>{contact.cs_name}</td>
                <td width={100} aria-label="action">
                  <Link to={`/contact/${contact.id}`}>
                    <Button size="sm" className="px-3">Detail</Button>
                  </Link>
                </td>
              </tr>
            ))}
          />
        </div>
      </div>
      <FormContactModal
        show={openedFormModal}
        onClose={() => setOpenedFormModal(false)}
        onFetch={refetch}
      />
      <ImportModal
        show={openedImportModal}
        onClose={() => setOpenedImportModal(false)}
        onRefetch={refetch}
      />
      <AlertDialog
        isDanger
        show={openedConfirmDelete}
        title="Hapus Contact?"
        description="Apakah kamu yakin ingin menghapus contact yang dipilih?"
        labelCancel="Batal"
        labelConfirm="Ya, Hapus"
        isLoadingConfirm={isLoadingDelete}
        onClose={() => setOpenedConfirmDelete(false)}
        onConfirm={() => deleteMutate({ ids: selectedData })}
      />
    </>
  );
};

export default ContactPage;
