import React from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Form from './components/Form';
import formEndpoint from '../../config/service/endpoint/form';

const AddFormPage = () => {
  const navigate = useNavigate();

  const { mutate: saveMutate, isLoading: saveLoading } = useMutation(formEndpoint.insert, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil menambah form.');
      navigate('/form');
    },
  });

  return (
    <>
      <h4 className="mb-4">Form | Tambah Form</h4>
      <Form
        isLoading={saveLoading}
        onSubmit={saveMutate}
      />
    </>
  );
};

export default AddFormPage;
