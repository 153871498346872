import configApi from '../configApi';

const getAll = (params) => configApi({ endpoint: 'dashboard/product', params });
const getCategories = () => configApi({ endpoint: 'dashboard/product/categories' });
const getById = (id) => configApi({ endpoint: `dashboard/product/${id}` });
const insert = (payload) => configApi({ endpoint: 'dashboard/product', method: 'post', payload });
const update = ({ id, payload }) => configApi({ endpoint: `dashboard/product/${id}`, method: 'put', payload });
const deleteMultiple = (payload) => configApi({ endpoint: 'dashboard/product', method: 'delete', payload });

const endpoint = {
  getAll,
  getCategories,
  getById,
  insert,
  update,
  deleteMultiple,
};

export default endpoint;
