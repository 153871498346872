import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';

const OrderStatus = ({ status }) => {
  let label = '';
  let bg = '';

  switch (status) {
    case 'pending':
      label = 'Pending';
      bg = 'primary';
      break;
    case 'process':
      label = 'Process';
      bg = 'warning';
      break;
    case 'complete':
      label = 'Complete';
      bg = 'success';
      break;
    case 'cancel':
      label = 'Cancel';
      bg = 'danger';
      break;
    case 'return':
      label = 'Return';
      bg = 'secondary';
      break;
    case 'unpaid':
      label = 'Unpaid';
      bg = 'danger';
      break;
    case 'paid':
      label = 'Paid';
      bg = 'success';
      break;
    default:
      label = '';
      bg = '';
  }

  return (
    <Badge className="px-3 py-2 mb-1" bg={bg} text="white">{label}</Badge>
  );
};

OrderStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default OrderStatus;
