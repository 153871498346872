import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import reportEndpoint from '../../../config/service/endpoint/report';
import { Table } from '../../../components/elements';
import { toRupiah } from '../../../helpers';

const TopProduct = ({ dateFilter }) => {
  const { isFetching, data, refetch } = useQuery('report-top-product', () => reportEndpoint.getTopProduct({ start_date: dateFilter.startDate, end_date: dateFilter.endDate }), {
    onError: (error) => toast.error(error.message),
  });

  useEffect(() => {
    refetch();
  }, [dateFilter]);

  return (
    <div className="col-md-6">
      <div className="card">
        <div className="card-body">
          <strong className="mb-3 d-block">Top Product</strong>
          <Table
            isLoading={isFetching}
            totalData={data?.data?.length}
            perPage={data?.data?.length}
            headRender={(
              <tr>
                <th>Nama Product</th>
                <th>Order</th>
                <th>Omset</th>
              </tr>
            )}
            bodyRender={data?.data?.map((product) => (
              <tr key={product.product_id}>
                <td>{product.product_name}</td>
                <td>{product.total_order}</td>
                <td>{toRupiah(product.total_omset)}</td>
              </tr>
            ))}
          />
        </div>
      </div>
    </div>
  );
};

TopProduct.propTypes = {
  dateFilter: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
};

export default TopProduct;
