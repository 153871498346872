import configApi from '../configApi';

const getAll = (params) => configApi({ endpoint: 'dashboard/campaign', params });
const insert = (payload) => configApi({ endpoint: 'dashboard/campaign', method: 'post', payload });
const deleteMultiple = (payload) => configApi({ endpoint: 'dashboard/campaign', method: 'delete', payload });

const endpoint = {
  getAll,
  insert,
  deleteMultiple,
};

export default endpoint;
