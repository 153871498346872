import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const Activities = ({ data }) => data.map((item) => (
  <div key={item.id} className="card shadow-none mb-3">
    <div className="card-body">
      <div className="media">
        <div className="media-body">
          <p className="mb-1">
            <span className="font-weight-bold">{`${item.type === 'note' ? 'Note' : 'WhatsApp'} |`}</span>
            {' '}
            <span className="text-success">{moment(item.created_at).format('DD-MM-YYYY')}</span>
          </p>
          <p className="mb-0">{item.content}</p>
        </div>
        <div
          className="align-self-center rounded-pill bg-secondary text-light d-flex justify-content-center align-items-center"
          style={{
            width: 45, height: 45, fontSize: 25, opacity: 0.7,
          }}
        >
          <i className={item.type === 'note' ? 'uil-notes' : 'uil-whatsapp'} />
        </div>
      </div>
    </div>
  </div>
));

Activities.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    created_at: PropTypes.string,
    content: PropTypes.string,
  })),
};

Activities.defaultProps = {
  data: [],
};

export default Activities;
