import React from 'react';
import Skeleton from 'react-loading-skeleton';

const LoadingSubscription = () => (
  <div className="col-md-6">
    <div className="card">
      <div className="card-body">
        <div className="d-flex align-items-center">
          <Skeleton width={30} height={30} />
          <Skeleton width={100} height={18} className="ml-2" />
        </div>
        <div className="d-flex mt-3">
          <div style={{ width: 30 }} />
          <div className="ml-2">
            <Skeleton width={120} height={22} className="mb-1" />
            <Skeleton width={200} height={18} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default LoadingSubscription;
