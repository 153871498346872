import React from 'react';
import Skeleton from 'react-loading-skeleton';

const FormLoadingSkeleton = () => (
  <div className="row">
    <div className="col-md-6">
      <div className="card">
        <div className="card-body">
          <div className="form-group">
            <Skeleton width={100} height={22} className="mb-2" />
            <Skeleton height={40} />
          </div>
          <div className="form-group">
            <Skeleton width={100} height={22} className="mb-2" />
            <Skeleton height={80} />
          </div>
          <div className="form-group">
            <Skeleton width={100} height={22} className="mb-2" />
            <Skeleton height={40} />
          </div>
          <div className="form-group">
            <Skeleton width={100} height={22} className="mb-2" />
            <Skeleton height={40} />
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-6">
      <div className="card">
        <div className="card-body">
          <Skeleton width={50} height={22} />
          <div className="d-flex mt-2 mb-3">
            <Skeleton width={100} height={28} className="mr-2" />
            <Skeleton width={100} height={28} />
          </div>
          <Skeleton width={50} height={22} />
          <div className="d-flex mt-2 mb-3">
            <Skeleton width={100} height={28} className="mr-2" />
            <Skeleton width={100} height={28} />
          </div>
          <div className="form-group">
            <Skeleton width={100} height={22} className="mb-2" />
            <Skeleton height={40} />
          </div>
          <Skeleton width={120} height={40} />
          <div className="d-flex mt-3">
            <Skeleton width={100} height={40} className="mr-2" />
            <Skeleton width={90} height={40} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FormLoadingSkeleton;
