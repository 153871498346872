import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import CreateChooseTemplate, { templateMenus } from './components/CreateChooseTemplate';
import templateEndpoint from '../../config/service/endpoint/template';
import DetailLoadingSkeleton from './components/DetailSkeletonLoading';

const EditTemplatePage = () => {
  const [contents, setContents] = useState([]);

  const params = useParams();
  const navigate = useNavigate();

  const { isFetching } = useQuery(`content-${params.id}`, () => templateEndpoint.getById(params.id, true), {
    onError: (error) => {
      toast.error(error.message);
      navigate('/template');
    },
    onSuccess: (resData) => setContents(resData.data),
  });

  const { mutate: saveMutate, isLoading: saveLoading } = useMutation(templateEndpoint.update, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Template berhasil disimpan.');
      navigate('/template');
    },
  });

  const handleSetContent = (name, content) => {
    const isExist = contents.some((_content) => _content.name === name);
    const newContents = isExist
      ? contents.map((_content) => (
        _content.name === name ? { ..._content, content } : _content
      ))
      : [...contents, { name, content, is_show: true }];
    setContents(newContents);
  };

  const handleSetVisibility = (name) => {
    const newContents = contents.map((content) => (
      content.name === name ? { ...content, is_show: !content.is_show } : content
    ));
    setContents(newContents);
  };

  const handleSave = () => {
    if (contents.length <= 0) {
      toast.error('Minimal ada 1 template yang dipilih.');
    }
    const sortedResult = contents.sort((a, b) => templateMenus.findIndex((menu) => menu.value === a.name) - templateMenus.findIndex((menu) => menu.value === b.name));
    saveMutate({
      id: params.id,
      contents: sortedResult,
    });
  };

  const { data: categoriesData, isFetching: categoriesFetching } = useQuery('template-source', () => templateEndpoint.getSource({ limit: 1000 }), { onError: (error) => toast.error(error.message) });

  if (isFetching || categoriesFetching) return <DetailLoadingSkeleton />;

  const contentTemplate = categoriesData?.data?.templates?.find((template) => template.id === contents[0]?.category_id);

  return (
    <>
      <h4 className="mb-4">Edit Template</h4>
      <CreateChooseTemplate
        contents={contentTemplate}
        formikContents={contents}
        isLoadingSave={saveLoading}
        onSetContent={handleSetContent}
        onSetVisibility={handleSetVisibility}
        onBack={() => navigate('/template')}
        onSave={handleSave}
      />
    </>
  );
};

export default EditTemplatePage;
