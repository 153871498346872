import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';

import { CLIENT_INVOICE_STATUS } from '../../../helpers';

const StatusBadge = ({ status }) => {
  let variant = '';
  let label = '';

  switch (status) {
    case CLIENT_INVOICE_STATUS.NEW:
      variant = 'info';
      label = 'Order Baru';
      break;
    case CLIENT_INVOICE_STATUS.UNPAID:
      variant = 'warning';
      label = 'Belum Dibayar';
      break;
    case CLIENT_INVOICE_STATUS.PAID:
      variant = 'primary';
      label = 'Sudah Dibayar';
      break;
    case CLIENT_INVOICE_STATUS.EXPIRED:
      variant = 'secondary';
      label = 'Kadaluarsa';
      break;
    case CLIENT_INVOICE_STATUS.COMPLETED:
      variant = 'success';
      label = 'Selesai';
      break;
    case CLIENT_INVOICE_STATUS.CANCELED:
      variant = 'danger';
      label = 'Batal';
      break;
    default:
      variant = '';
      label = '';
  }

  return <Badge bg={variant} className="text-white">{label}</Badge>;
};

StatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusBadge;
