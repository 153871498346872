import React, { useState } from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import CreateForm from './components/CreateForm';
import CreateChooseTemplate from './components/CreateChooseTemplate';
import templateEndpoint from '../../config/service/endpoint/template';
import productEndpoint from '../../config/service/endpoint/product';
import teamCSEndpoint from '../../config/service/endpoint/teamCS';

const validationSchema = yup.object({
  name: yup.string().trim().required('Nama template harus diisi.').max(100, 'Nama template maksimal 100 karakter.'),
  category_id: yup.string().trim().required('Pilih kategori dulu.'),
  product_id: yup.string().trim().required('Pilih produk dulu.'),
  cs_id: yup.string().trim().required('Pilih cs dulu.'),
});

const CreateTemplatePage = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState(1);

  const { data: categoriesData } = useQuery('template-source', () => templateEndpoint.getSource({ limit: 1000 }), { onError: (error) => toast.error(error.message) });
  const { data: productsData } = useQuery('products', () => productEndpoint.getAll({ limit: 1000 }), { onError: (error) => toast.error(error.message) });
  const { data: teamCSData } = useQuery('teamcs', () => teamCSEndpoint.getAll({ limit: 1000 }), { onError: (error) => toast.error(error.message) });

  const { mutate: saveMutate, isLoading: saveLoading } = useMutation(templateEndpoint.insert, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Template berhasil disimpan.');
      navigate('/template');
    },
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      category_id: '',
      product_id: '',
      cs_id: '',
      contents: [],
    },
    validationSchema,
    onSubmit: () => setStep(2),
  });

  const handleSetContent = (name, content) => {
    const { values, setFieldValue } = formik;
    const { contents } = values;
    const isExist = contents.some((_content) => _content.name === name);
    const newContents = isExist
      ? contents.map((_content) => (
        _content.name === name ? { ..._content, content } : _content
      ))
      : [...contents, { name, content, is_show: true }];
    setFieldValue('contents', newContents);
  };

  const handleSetVisibility = (name) => {
    const { values, setFieldValue } = formik;
    const { contents } = values;
    const newContents = contents.map((content) => (
      content.name === name ? { ...content, is_show: !content.is_show } : content
    ));
    setFieldValue('contents', newContents);
  };

  const handleSave = () => {
    const {
      name, category_id: categoryId, product_id: productId, cs_id: csId, contents,
    } = formik.values;
    if (contents.length <= 0) {
      toast.error('Minimal ada 1 template yang dipilih.');
    }
    saveMutate({
      name,
      category_id: categoryId,
      product_id: productId,
      cs_id: csId,
      contents,
    });
  };

  const contentTemplate = categoriesData?.data?.templates?.find((template) => template.id === formik.values.category_id);

  return (
    <>
      <h4 className="mb-4">Template | Buat Baru</h4>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          {step === 1 && (
            <CreateForm
              formik={formik}
              categoriesData={categoriesData?.data?.templates}
              productsData={productsData?.data?.products}
              teamCS={teamCSData?.data?.teamcs}
            />
          )}
          {step === 2 && (
            <CreateChooseTemplate
              contents={contentTemplate}
              formikContents={formik.values.contents}
              isLoadingSave={saveLoading}
              onSetContent={handleSetContent}
              onSetVisibility={handleSetVisibility}
              onBack={() => setStep(1)}
              onSave={handleSave}
            />
          )}
        </form>
      </FormikProvider>
    </>
  );
};

export default CreateTemplatePage;
