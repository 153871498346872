import configApi from '../configApi';

const getAll = (params) => configApi({ endpoint: 'dashboard/contact', params });
const getById = (id) => configApi({ endpoint: `dashboard/contact/${id}` });
const getActivities = (contactId) => configApi({ endpoint: `dashboard/contact/activities/${contactId}` });
const getOrderHistory = (contactId) => configApi({ endpoint: `dashboard/contact/order-history/${contactId}` });
const getDownload = () => configApi({ endpoint: 'dashboard/contact/download' });
const insert = (payload) => configApi({ endpoint: 'dashboard/contact', method: 'post', payload });
const insertActivity = ({ contactId, payload }) => configApi({ endpoint: `dashboard/contact/activity/${contactId}`, method: 'post', payload });
const importContact = (payload) => configApi({ endpoint: 'dashboard/contact/import', method: 'post', payload });
const update = ({ id, payload }) => configApi({ endpoint: `dashboard/contact/${id}`, method: 'put', payload });
const deleteMultiple = (payload) => configApi({ endpoint: 'dashboard/contact', method: 'delete', payload });

const endpoint = {
  getAll,
  getById,
  getActivities,
  getDownload,
  getOrderHistory,
  insert,
  insertActivity,
  importContact,
  update,
  deleteMultiple,
};

export default endpoint;
