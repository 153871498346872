import React from 'react';
import toast from 'react-hot-toast';
import { Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';

import accountEndpoint from '../../../config/service/endpoint/account';
import { giftImage } from '../../../assets';

const AccountGiftPage = () => {
  const { isFetching, data } = useQuery('product-selected', accountEndpoint.productSelected, {
    onError: (error) => toast.error(error.message),
  });

  if (isFetching) {
    return (
      <div className="mt-5 pt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="text-center">
              <Spinner animation="border" role="status" />
              <p className="mb-0 mt-3">Loading...</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!data) {
    return <p>Gagal memuat data akun</p>;
  }

  return (
    <div className="my-5 pt-sm-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center">
              {!!data.data.bonus && (
                <div>
                  <div className="row justify-content-center">
                    <div className="col-sm-4">
                      <div className="error-img">
                        <img src={giftImage} alt="gift" className="img-fluid mx-auto d-block" style={{ width: 200 }} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {data.data.bonus ? (
                <>
                  <h4 className="text-uppercase mt-5">Wahh, kamu dapat bonus..</h4>
                  <p className="text-muted">{`Karena kamu telah membeli paket ${data.data.product_name}, maka kamu berhak mendapatkan bonus dari kami.`}</p>
                  <div className="mt-4">
                    <a className="btn btn-primary waves-effect waves-light" target="_blank" rel="noreferrer" href={data.data.bonus}>Dapatkan Bonus</a>
                  </div>
                </>
              ) : (
                <>
                  <h4 className="text-uppercase mt-5">Tidak ada bonus</h4>
                  <p className="text-muted">Mohon maaf kamu tidak mendapatkan bonus karena paket ini tidak menyediakan bonus.</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountGiftPage;
