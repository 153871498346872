import React, { Fragment } from 'react';
import { Popover } from 'react-bootstrap';

const statusList = [
  {
    label: 'Pending : Order baru',
    color: 'primary',
  },
  {
    label: 'Process : Order di proses',
    color: 'warning',
  },
  {
    label: 'Complete : Order telah selesai',
    color: 'success',
  },
  {
    label: 'Cancel : Order dibatalkan',
    color: 'danger',
  },
  {
    label: 'Return : Order dikembalikan',
    color: 'secondary',
  },
  {
    label: 'Unpaid : Order belum di bayar',
    color: 'danger',
  },
  {
    label: 'Paid : Order sudah di bayar',
    color: 'success',
  },
];

const followUpList = [
  {
    label: 'Belum',
    color: 'secondary',
  },
  {
    label: 'Segera',
    color: 'warning',
  },
  {
    label: 'Sudah',
    color: 'success',
  },
];

export const statusPopoverInfo = (
  <Popover id="popover-status-info">
    <Popover.Body className="border border-secondary rounded-lg p-3">
      {statusList.map((status, index) => (
        <Fragment key={status}>
          <p className={index + 1 === statusList.length ? 'mb-0' : 'mb-3'}>
            <span className={`badge rounded-pill bg-${status.color} mr-2`}>&nbsp;&nbsp;&nbsp;</span>
            {status.label}
          </p>
          {index === 4 && <hr className="my-2" />}
        </Fragment>
      ))}
    </Popover.Body>
  </Popover>
);

export const followUpPopoverInfo = (
  <Popover id="popover-followup-info">
    <Popover.Body className="border border-secondary rounded-lg p-3">
      {followUpList.map((followUp, index) => (
        <p key={followUp.label} className={index + 1 === followUpList.length ? 'mb-0' : 'mb-2'}>
          <span className={`badge rounded-pill bg-${followUp.color} mr-2`}>&nbsp;&nbsp;&nbsp;</span>
          {followUp.label}
        </p>
      ))}
    </Popover.Body>
  </Popover>
);
