import configApi from '../configApi';

const getAll = (params) => configApi({ endpoint: 'dashboard/label', params });
const getById = (id) => configApi({ endpoint: `dashboard/label/${id}` });
const insert = (payload) => configApi({ endpoint: 'dashboard/label', method: 'post', payload });
const update = ({ id, payload }) => configApi({ endpoint: `dashboard/label/${id}`, method: 'put', payload });
const deleteMultiple = (payload) => configApi({ endpoint: 'dashboard/label', method: 'delete', payload });

const endpoint = {
  getAll,
  getById,
  insert,
  update,
  deleteMultiple,
};

export default endpoint;
