import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useQuery } from 'react-query';

import productEndpoint from '../../../config/service/endpoint/product';
import { Button, InputField, Select } from '../../../components/core';
import { workspaceOfficeImage } from '../../../assets';

const validationSchema = yup.object({
  product_name: yup.string().trim().required('Nama produk harus diisi.').max(100, 'Nama produk maksimal 100 karakter.'),
  category_id: yup.string().required('Pilih kategori terlebih dahulu'),
  price: yup.number().typeError('Harga harus berupa angka.').required('Harga harus diisi.').min(0, 'Harga minimal 0.'),
  sku: yup.string().trim().required('SKU harus diisi.').max(30, 'SKU maksimal 30 karakter.'),
});

const Form = ({
  isLoading, initialValues, onSubmit,
}) => {
  const { isLoading: loadingCategories, data: categoriesData } = useQuery('categories', productEndpoint.getCategories, {
    onError: (error) => toast.error(error.message),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const convertCategoriesData = categoriesData?.data?.templates?.map((template) => ({
    label: template.category_name,
    value: template.id,
  }));

  return (
    <div className="row">
      <div className="col-md-6">
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <InputField
              name="product_name"
              id="productName"
              label="Nama Produk"
              value={formik.values.product_name}
              error={formik.touched.product_name && formik.errors.product_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Select
              name="category_id"
              id="category_id"
              label="Pilih Kategori Produk"
              placeholder={loadingCategories ? 'Loading...' : '-- Pilih salah satu --'}
              disabled={loadingCategories}
              error={formik.touched.category_id && formik.errors.category_id}
              data={convertCategoriesData}
              value={formik.values.category_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <InputField
              name="price"
              id="price"
              label="Harga"
              value={formik.values.price}
              error={formik.touched.price && formik.errors.price}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <InputField
              name="sku"
              id="sku"
              label="SKU"
              value={formik.values.sku}
              error={formik.touched.sku && formik.errors.sku}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Button type="submit" isLoading={isLoading} className="px-4 mt-2">SIMPAN</Button>
          </form>
        </FormikProvider>
      </div>
      <div className="col-md-6 d-flex align-items-center justify-content-center">
        <img src={workspaceOfficeImage} alt="workspace office" className="add-product-image" />
      </div>
    </div>
  );
};

Form.propTypes = {
  isLoading: PropTypes.bool,
  initialValues: PropTypes.shape({
    product_name: PropTypes.string,
    category_id: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sku: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  isLoading: false,
  initialValues: {
    product_name: '',
    category_id: '',
    price: '',
    sku: '',
  },
  onSubmit: () => {},
};

export default Form;
