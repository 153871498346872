import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import Form from './components/Form';
import FormLoadingSkeleton from './components/FormLoadingSkeleton';
import formEndpoint from '../../config/service/endpoint/form';

const EditFormPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    product_id: '',
    payment: '',
    message: '',
    tracking_type: '',
    tracking_id: '',
    type: 'direct',
    distribution: 'equal',
    team_cs: [{
      id: Date.now(),
      cs_id: '',
      percentage: 0,
    }],
    label_id: '',
  });

  const { isLoading } = useQuery(`form-${params.id}`, () => formEndpoint.getById(params.id), {
    onError: (error) => {
      toast.error(error.message);
      navigate('/form');
    },
    onSuccess: (response) => {
      const formData = response.data;
      setInitialValues({
        product_id: formData.product_id,
        payment: formData.payment,
        message: formData.message,
        tracking_type: formData.tracking_type,
        tracking_id: formData.tracking_id,
        type: formData.type,
        distribution: formData.distribution,
        team_cs: formData.teamCS || [],
        label_id: formData.label_id,
      });
    },
  });

  const { mutate: saveMutate, isLoading: saveLoading } = useMutation(formEndpoint.update, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil mengubah form.');
      navigate('/form');
    },
  });

  return (
    <>
      <h4 className="mb-4">Form | Edit Form</h4>
      {isLoading ? <FormLoadingSkeleton /> : (
        <Form
          initialValues={initialValues}
          isLoading={saveLoading}
          onSubmit={(payload) => saveMutate({ id: params.id, payload })}
        />
      )}
    </>
  );
};

export default EditFormPage;
