import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useMutation } from 'react-query';

import labelEndpoint from '../../../config/service/endpoint/label';
import { Modal } from '../../../components/elements';
import { Button, InputField } from '../../../components/core';

const FormModal = ({
  initialValues, show, onClose, onFetch,
}) => {
  const { mutate, isLoading } = useMutation(initialValues.id ? labelEndpoint.update : labelEndpoint.insert, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success(initialValues.id ? 'Berhasil mengubah label.' : 'Berhasil menambah label.');
      onClose();
      onFetch();
    },
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      name: '',
      color: '#000000',
    },
    validationSchema: yup.object().shape({
      name: yup.string().trim().required('Nama label harus diisi.').max(30, 'Nama label maksimal 30 karakter.'),
      color: yup.string().trim().required('Warna label harus diisi.').max(30, 'Warna label maksimal 30 karakter.'),
    }),
    onSubmit: (values) => {
      if (initialValues.id) {
        mutate({
          id: values.id,
          payload: {
            name: values.name,
            color: values.color,
          },
        });
      } else {
        mutate({
          name: values.name,
          color: values.color,
        });
      }
    },
  });

  useEffect(() => {
    formik.setValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (!show) formik.resetForm();
  }, [show]);

  return (
    <Modal
      show={show}
      title="Tambah Label"
      onClose={onClose}
    >
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <InputField
            id="name"
            name="name"
            label="Nama Label"
            value={formik.values.name}
            error={formik.touched.name && formik.errors.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <InputField
            id="color"
            name="color"
            type="color"
            label="Warna"
            value={formik.values.color}
            error={formik.touched.color && formik.errors.color}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className="d-flex justify-content-end mt-4">
            <Button isLoading={isLoading} type="submit" className="px-4">SIMPAN</Button>
          </div>
        </form>
      </FormikProvider>
    </Modal>
  );
};

FormModal.propTypes = {
  initialValues: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
  }),
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onFetch: PropTypes.func,
};

FormModal.defaultProps = {
  initialValues: {
    id: '',
    name: '',
    color: '#000000',
  },
  show: false,
  onClose: () => {},
  onFetch: () => {},
};

export default FormModal;
