import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { useQuery } from 'react-query';

import MainProfile from './components/Detail/MainProfile';
import DetailProfile from './components/Detail/DetailProfile';
import Form from './components/Detail/Form';
import Activities from './components/Detail/Activities';
import FormContactModal from './components/FormContactModal';
import DetailLoadingSkeleton from './components/LoadingDetailSkeleton';
import contactEndpoint from '../../config/service/endpoint/contact';
import HistoryOrderModal from './components/HistoryOrderModal';

const DetailContactPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [openedEditModal, setOpenedEditModal] = useState(false);
  const [openedHistoryModal, setOpenedHistoryModal] = useState(false);

  const { data, isLoading, refetch } = useQuery(`contact-${params.id}`, () => contactEndpoint.getById(params.id), {
    onError: (error) => {
      toast.error(error.message);
      navigate('/contact');
    },
  });

  const { isLoading: activitiesLoading, data: activitiesData, refetch: activitiesRefetch } = useQuery(`activities-${params.id}`, () => contactEndpoint.getActivities(params.id), {
    onError: (error) => toast.error(error.message),
  });

  const contactData = data?.data;

  return (
    <>
      <h4 className="mb-4">Contact | Detail</h4>
      {isLoading || activitiesLoading ? <DetailLoadingSkeleton /> : (
        <div className="row">
          <div className="col-md-5">
            <MainProfile
              name={contactData.name}
              totalPurchase={contactData.total_purchase}
              onEdit={() => setOpenedEditModal(true)}
              onOpenHistory={() => setOpenedHistoryModal(true)}
            />
            <DetailProfile {...contactData} />
          </div>
          <div className="col-md-7">
            <Form contactId={params.id} onFetch={activitiesRefetch} />
            <p className="font-weight-bold mb-2">Aktivitas</p>
            {activitiesData?.data?.length <= 0 && (
              <Alert variant="warning">Belum ada aktivitas</Alert>
            )}
            <Activities data={activitiesData?.data} />
          </div>
        </div>
      )}
      <FormContactModal
        contactId={contactData?.id}
        show={openedEditModal}
        onClose={() => setOpenedEditModal(false)}
        onFetch={refetch}
      />
      <HistoryOrderModal
        contactId={contactData?.id}
        show={openedHistoryModal}
        onClose={() => setOpenedHistoryModal(false)}
      />
    </>
  );
};

export default DetailContactPage;
