import React from 'react';
import { Link } from 'react-router-dom';

import { Button, InputField } from '../../components/core';

const ForgotPasswordPage = () => (
  <div className="account-pages my-5 pt-sm-5">
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-md-8 col-lg-6 col-xl-5">
          <div className="card">
            <div className="card-body p-4">
              <div className="text-center mt-2">
                <img src={`${process.env.REACT_APP_SERVICE_URL_API}uploads/special-logo.png`} width="180" alt="Logo" />
              </div>
              <div className="p-2 mt-4">
                <form>
                  <InputField label="Alamat Email" placeholder="Masukkan Email" />
                  <Button className="w-100" size="lg">Reset Password</Button>
                  <div className="d-flex justify-content-end mt-3">
                    <Link to="/" className="text-secondary">
                      Kembali ke Login
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="mt-5 text-center">
            <p>© Mesin CS.</p>
          </div>

        </div>
      </div>
    </div>
  </div>
);

export default ForgotPasswordPage;
