import React from 'react';
import Skeleton from 'react-loading-skeleton';

const FormGroupSkeleton = () => (
  <div className="form-group">
    <Skeleton width={100} height={22} className="mb-2" />
    <Skeleton height={40} />
  </div>
);

const FormLoadingSkeleton = () => (
  <>
    <div className="row">
      <div className="col-md-6">
        <FormGroupSkeleton />
      </div>
      <div className="col-md-6">
        <FormGroupSkeleton />
      </div>
      <div className="col-md-6">
        <FormGroupSkeleton />
      </div>
      <div className="col-md-6">
        <FormGroupSkeleton />
      </div>
      <div className="col-12">
        <FormGroupSkeleton />
      </div>
      <div className="col-12">
        <FormGroupSkeleton />
      </div>
      <div className="col-md-6">
        <FormGroupSkeleton />
      </div>
      <div className="col-md-6">
        <FormGroupSkeleton />
      </div>
      <div className="col-md-6">
        <FormGroupSkeleton />
      </div>
      <div className="col-md-6">
        <FormGroupSkeleton />
      </div>
    </div>
    <Skeleton width={100} height={40} />
  </>
);

export default FormLoadingSkeleton;
