import configApi from '../configApi';

const getOrderSummary = (params) => configApi({ endpoint: 'dashboard/dashboard/order-summary', params });
const getSubscription = () => configApi({ endpoint: 'dashboard/dashboard/subscription' });
const getChart = () => configApi({ endpoint: 'dashboard/dashboard/chart' });

const endpoint = {
  getOrderSummary,
  getSubscription,
  getChart,
};

export default endpoint;
