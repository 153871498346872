import React, { useState } from 'react';
import toast from 'react-hot-toast';
import ReactSelect from 'react-select';
import * as yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import AddProductModal from './components/create/AddProductModal';
import FormContactModal from '../Contact/components/FormContactModal';
import teamCSEndpoint from '../../config/service/endpoint/teamCS';
import contactEndpoint from '../../config/service/endpoint/contact';
import orderEndpoint from '../../config/service/endpoint/order';
import { toRupiah } from '../../helpers/converter';
import {
  Button, InputField, Radio, Select,
} from '../../components/core';

const validationSchema = yup.object({
  cs_id: yup.string().required('Pilih cs terlebih dahulu'),
  contact_id: yup.object().required('Pilih kontak terlebih dahulu'),
  products: yup.array().min(1, 'Pilih minimal 1 produk'),
  discount: yup.number().typeError('Diskon harus berupa angka').min(0, 'Diskon minimal 0'),
});

const CreateOrderPage = () => {
  const navigate = useNavigate();

  const [openedFormModal, setOpenedFormModal] = useState(false);
  const [openedAddProduct, setOpenedAddProduct] = useState(false);

  const { data: teamCS, isLoading: teamCSLoading } = useQuery('team-cs', () => teamCSEndpoint.getAll({ limit: 1000 }), { onError: (error) => toast.error(error.message) });
  const { data: contacts, isLoading: contactLoading, refetch: contactsRefetch } = useQuery('contacts', () => contactEndpoint.getAll({ limit: 1000 }), { onError: (error) => toast.error(error.message) });

  const { mutate: createOrderMutate, isLoading: createOrderLoading } = useMutation(orderEndpoint.insert, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil membuat order.');
      navigate('/order');
    },
  });

  const formik = useFormik({
    initialValues: {
      cs_id: '',
      contact_id: null,
      products: [],
      payment: 'transfer',
      discount: 0,
    },
    validationSchema,
    onSubmit: (values) => {
      if (values.products.reduce((a, product) => a + (product.price * product.qty), 0) < values.discount) {
        toast.error('Diskon tidak boleh melebihi total harga.');
        return;
      }
      createOrderMutate({
        cs_id: values.cs_id,
        contact_id: values.contact_id.value,
        products: values.products.map((product) => ({
          product_id: product.id,
          qty: product.qty,
        })),
        payment: values.payment,
        discount: values.discount,
      });
    },
  });

  const handleRemoveProduct = (id) => {
    formik.setFieldValue('products', formik.values.products.filter((product) => product.id !== id));
  };

  const totalPayment = formik.values.products.reduce((a, product) => a + (product.price * product.qty), 0) - (Number(formik.values.discount) || 0);

  return (
    <>
      <h4 className="mb-4">Order | Order Baru</h4>
      <FormikProvider value={formik}>
        <div className="row">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <Select
                  name="cs_id"
                  id="cs_id"
                  placeholder={teamCSLoading ? 'Loading...' : 'Pilih CS'}
                  data={teamCS?.data?.teamcs?.map((_cs) => ({ label: _cs.name, value: _cs.id }))}
                  value={formik.values.cs_id}
                  error={formik.touched.cs_id && formik.errors.cs_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className="form-group mb-3">
                  <label htmlFor="contact_id">Pesanan</label>
                  <div className="input-group">
                    <span className="input-group-btn input-group-prepend" style={{ width: 30 }}>
                      <Button style={{ height: 39 }} onClick={() => setOpenedFormModal(true)}>
                        <i className="bx bx-user-plus" style={{ fontSize: 18 }} />
                      </Button>
                    </span>
                    <div style={{ width: 'calc(100% - 50px)', position: 'relative', left: 15 }}>
                      <ReactSelect
                        styles={{ input: (base) => ({ ...base, height: 29 }), control: (base) => ({ ...base, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }) }}
                        id="contact_id"
                        name="contact_id"
                        placeholder={contactLoading ? 'Loading...' : 'Pilih kontak'}
                        value={formik.values.contact_id}
                        options={contacts?.data?.contacts?.map((contact) => ({ label: `${contact.name} - ${contact.whatsapp}`, value: contact.id }))}
                        onBlur={() => formik.setFieldTouched('contact_id', true)}
                        onChange={(assiged) => formik.setFieldValue('contact_id', assiged)}
                      />
                      {formik.touched.contact_id && formik.errors.contact_id && (
                        <small className="text-danger">{formik.errors.contact_id}</small>
                      )}
                    </div>
                  </div>
                </div>
                <Button variant="outline-primary" className="w-100" onClick={() => setOpenedAddProduct(true)}>
                  <i className="fa fa-plus mr-2" />
                  Pilih Produk
                </Button>
                {formik.touched.products && formik.errors.products && (
                  <small className="text-danger">{formik.errors.products}</small>
                )}
                {formik.values.products.length > 0 && (
                  <table className="table table-borderless table-sm mt-3">
                    <tr>
                      <th>Produk</th>
                      <th>Harga</th>
                      <th>Qty</th>
                      <th>Sub Total</th>
                      <th>Hapus</th>
                    </tr>
                    {formik.values.products.map((product) => (
                      <tr key={product.id}>
                        <td>{product.product_name}</td>
                        <td>{toRupiah(product.price)}</td>
                        <td>{product.qty}</td>
                        <td>{toRupiah(product.price * product.qty)}</td>
                        <td aria-label="delete">
                          <Button
                            onClick={() => handleRemoveProduct(product.id)}
                            variant="outline-danger"
                            size="sm"
                          >
                            <i className="fa fa-times" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </table>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <label>Pembayaran</label>
                  <div className="d-flex" style={{ gap: 16 }}>
                    <Radio
                      name="payment"
                      id="paymentTransfer"
                      label="Transfer"
                      checked={formik.values.payment === 'transfer'}
                      onChange={() => formik.setFieldValue('payment', 'transfer')}
                    />
                    <Radio
                      name="paymebt"
                      id="paymentCOD"
                      label="COD"
                      checked={formik.values.payment === 'cod'}
                      onChange={() => formik.setFieldValue('payment', 'cod')}
                    />
                  </div>
                </div>
                <InputField
                  name="discount"
                  id="discount"
                  label="Diskon"
                  placeholder="Dalam rupiah"
                  value={formik.values.discount}
                  error={formik.touched.discount && formik.errors.discount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <p>Rincian</p>
                <div className="card shadow-none">
                  <div className="card-body rounded-lg border border-muted" style={{ backgroundColor: '#FFF2CC' }}>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>Sub Total</span>
                      <span>{toRupiah(totalPayment)}</span>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-2">
                      <strong>Total</strong>
                      <strong>{toRupiah(totalPayment)}</strong>
                    </div>
                  </div>
                </div>
                <Button
                  className="px-4"
                  isLoading={createOrderLoading}
                  onClick={formik.handleSubmit}
                >
                  Simpan
                </Button>
              </div>
            </div>
          </div>
        </div>
      </FormikProvider>
      <AddProductModal
        initSelected={formik.values.products}
        show={openedAddProduct}
        onClose={() => setOpenedAddProduct(false)}
        onAddToCart={(selected) => formik.setFieldValue('products', selected)}
      />
      <FormContactModal
        show={openedFormModal}
        onClose={() => setOpenedFormModal(false)}
        onFetch={(contactId) => {
          contactsRefetch();
          formik.setFieldValue('contact_id', contactId);
        }}
      />
    </>
  );
};

export default CreateOrderPage;
