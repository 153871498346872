import React from 'react';
import PropTypes from 'prop-types';

const BadgeLabel = ({ label, color, className }) => (
  <div className={`badge text-white px-2 pt-1 ${className}`} style={{ backgroundColor: color }}>
    {label}
  </div>
);

BadgeLabel.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  className: PropTypes.string,
};

BadgeLabel.defaultProps = {
  className: '',
};

export default BadgeLabel;
