import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import settingEndpoint from '../config/service/endpoint/setting';

const initialStateContext = {
  setting: {
    businessName: '',
    businessWhatsApp: '',
  },
  state: {
    settingLoading: true,
    settingError: null,
  },
};

export const AppContext = createContext(initialStateContext);

const AppProvider = ({ children }) => {
  const [setting, setSetting] = useState(initialStateContext.setting);

  const { isLoading: settingLoading, error: settingError } = useQuery('setting', settingEndpoint.getSetting, {
    onError: (error) => toast.error(error.message),
    onSuccess: (data) => {
      const { data: settingData } = data;
      setSetting({
        businessName: settingData.business_name,
        businessWhatsApp: settingData.business_whatsapp,
      });
    },
  });

  const values = useMemo(() => ({
    setting,
    state: {
      settingLoading,
      settingError,
    },
  }), [setting]);

  return (
    <AppContext.Provider value={values}>
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
