import React from 'react';
import Lottie from 'react-lottie';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { Outlet } from 'react-router-dom';

import accountEndpoint from '../service/endpoint/account';
import { loadingLottie } from '../../assets';

const ActiveAccountRoute = () => {
  const { data, isFetching } = useQuery('profile', accountEndpoint.getProfile, {
    onError: (error) => toast.error(error.message),
  });

  if (isFetching) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 100,
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loadingLottie,
          }}
          style={{
            height: 180,
          }}
        />
      </div>
    );
  }

  if (data?.data?.is_expired) {
    window.location.href = '/akun/profil';
    return null;
  }
  return <Outlet />;
};

export default ActiveAccountRoute;
