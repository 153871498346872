import React, { useState } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import StatusBadge from '../components/StatusBadge';
import DetailModal from './DetailModal';
import ProductsModal from './ProductsModal';
import accountEndpoint from '../../../config/service/endpoint/account';
import { toRupiah } from '../../../helpers/converter';
import { Button } from '../../../components/core';
import { Table } from '../../../components/elements';

const AccountInvoicePage = () => {
  const [detailModal, setDetailModal] = useState(null);
  const [openedProduct, setOpenedProduct] = useState(false);

  const { data, isFetching } = useQuery('invoice', accountEndpoint.getInvoice, {
    onError: (error) => toast.error(error.message),
  });

  return (
    <>
      <h4 className="mb-4">
        Akun |
        {' '}
        <span className="text-primary">Invoice</span>
      </h4>
      <div className="card">
        <div className="card-header">
          <Button className="px-3" onClick={() => setOpenedProduct(true)}>Perpanjang Paket</Button>
        </div>
        <div className="card-body">
          <Table
            totalData={data?.data?.meta?.total}
            isLoading={isFetching}
            headRender={(
              <tr>
                <th>Waktu</th>
                <th>Invoice</th>
                <th>Produk</th>
                <th>Status</th>
                <th>Nominal</th>
                <th>Aksi</th>
              </tr>
            )}
            bodyRender={data?.data?.invoices.map((item) => (
              <tr key={item.id}>
                <td>{moment(item.date).format('DD-MM-YYYY')}</td>
                <td>{item.invoice_id}</td>
                <td>{item.product_name}</td>
                <td aria-label="status"><StatusBadge status={item.status} /></td>
                <td>{toRupiah(item.total_bill)}</td>
                <td width={100} aria-label="action">
                  <Button size="sm" onClick={() => setDetailModal(item)}>
                    Detail
                  </Button>
                </td>
              </tr>
            ))}
          />
        </div>
      </div>
      <DetailModal
        show={!!detailModal}
        order={detailModal}
        onClose={() => setDetailModal(null)}
      />
      <ProductsModal
        show={openedProduct}
        onClose={() => setOpenedProduct(false)}
      />
    </>
  );
};

export default AccountInvoicePage;
