import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { Alert } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { FormikProvider, useFormik } from 'formik';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';

import authEndpoint from '../../config/service/endpoint/auth';
import { Button, InputField } from '../../components/core';
import { MENU_LIST, TOP_BAR_MENU, setLocalStorage } from '../../helpers';
import { useAppContext } from '../../hooks';

const LoginPage = ({ isCS }) => {
  const { setting } = useAppContext();
  const [searchParams] = useSearchParams();

  const sourceSearch = searchParams.get('source');
  const slugSearch = searchParams.get('slug');

  const handleSuccessLogin = (data) => {
    toast.success('Berhasil login.');
    setLocalStorage('token', data.data.token);
    setLocalStorage('user', JSON.stringify({
      name: data.data.name,
    }));
    const nameMenuList = MENU_LIST.map((menu) => menu.name);
    const nameTopBarMenuList = data.data.affiliate_only ? ['profile', 'affiliate'] : TOP_BAR_MENU.map((menu) => menu.name);
    const newMenuList = data.data.affiliate_only ? nameTopBarMenuList.toString() : [...nameMenuList, ...nameTopBarMenuList].toString();
    const accessMenuList = data.data.cs_id ? data.data.access_menu : newMenuList;
    setLocalStorage('token_access', JSON.stringify(accessMenuList));
    setLocalStorage('token_type', data.data.affiliate_only ? 'affiliate_only' : 'general');
    if (sourceSearch && slugSearch && isCS) {
      toast.error('Login sebagai CS tidak diperbolehkan.');
    } else if (sourceSearch && slugSearch) {
      window.location.href = `${process.env.REACT_APP_CHECKOUT_URL}${slugSearch}?client_id=${data.data.id}&token=${data.data.token}`;
    } else if (data.data.affiliate_only) {
      window.location.href = '/akun/profil';
    } else {
      window.location.href = accessMenuList.includes('dashboard') ? '/dashboard' : `/${accessMenuList.split(',')[0]}`;
    }
  };

  const { mutate, error, isLoading } = useMutation(authEndpoint.login, {
    onSuccess: handleSuccessLogin,
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup.string().required('Email harus diisi.'),
      password: yup.string().required('Password harus diisi.'),
    }),
    onSubmit: (values) => {
      mutate({
        type: isCS ? 'cs' : 'client',
        email: values.email,
        password: values.password,
      });
    },
  });

  return (
    <>
      <Helmet>
        <title>{`Login ${isCS ? 'CS' : ''} - ${setting.businessName}`}</title>
      </Helmet>
      <div className="account-pages my-5 pt-sm-5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <img src={`${process.env.REACT_APP_SERVICE_URL_API}uploads/special-logo.png`} width="180" alt="Logo" />
                  </div>
                  <div className="p-2 mt-4">
                    {isCS && <h5 className="mt-2 mb-3">Login sebagai CS</h5>}
                    {error && (
                      <Alert variant="danger">
                        {error.message}
                      </Alert>
                    )}
                    <FormikProvider value={formik}>
                      <form onSubmit={formik.handleSubmit}>
                        <InputField
                          name="email"
                          id="email"
                          label="Email"
                          placeholder="Masukkan Email"
                          error={formik.touched.email && formik.errors.email}
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <InputField
                          name="password"
                          id="password"
                          label="Password"
                          type="password"
                          placeholder="Masukkan Password"
                          error={formik.touched.password && formik.errors.password}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <Button
                          type="submit"
                          className="w-100 mt-3"
                          size="lg"
                          isLoading={isLoading}
                        >
                          Login
                        </Button>
                      </form>
                    </FormikProvider>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <p>© Mesin CS.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

LoginPage.propTypes = {
  isCS: PropTypes.bool,
};

LoginPage.defaultProps = {
  isCS: false,
};

export default LoginPage;
