import React from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useQuery } from 'react-query';

import LoadingSubscription from './LoadingSubscription';
import dashboardEndpoint from '../../../config/service/endpoint/dashboard';
import { subscriptionImage } from '../../../assets';

const Subscription = () => {
  const { data, isFetching } = useQuery('dashboard-subscription', dashboardEndpoint.getSubscription, {
    onError: (error) => toast.error(error.message),
  });

  if (isFetching) return <LoadingSubscription />;

  return (
    <div className="col-md-6">
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <img src={subscriptionImage} alt="subscription" style={{ width: 30 }} />
            <h6 className="mb-0 ml-2">Subscription</h6>
          </div>
          <div className="d-flex mt-3">
            <div style={{ width: 30 }} />
            <div className="ml-2">
              <h6 className="mb-1">{data?.data?.product_name}</h6>
              <p className="text-muted mb-0">
                {`Langganan Anda akan berakhir pada ${moment(data?.data?.expired_date).format('DD MMM YYYY')}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
