import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Menu = ({ activeMenu }) => (
  <ul className="nav nav-pills">
    <li className="nav-item">
      <Link to="/akun/affiliate/link" className={`nav-link ${activeMenu === 'link' ? 'active' : ''}`}>LINK</Link>
    </li>
    <li className="nav-item">
      <Link to="/akun/affiliate/order" className={`nav-link ${activeMenu === 'order' ? 'active' : ''}`}>ORDER</Link>
    </li>
    <li className="nav-item">
      <Link to="/akun/affiliate/komisi" className={`nav-link ${activeMenu === 'komisi' ? 'active' : ''}`}>KOMISI</Link>
    </li>
  </ul>
);

Menu.propTypes = {
  activeMenu: PropTypes.string.isRequired,
};

export default Menu;
