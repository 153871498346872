import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useQuery } from 'react-query';

import productEndpoint from '../../../config/service/endpoint/product';
import teamCSEndpoint from '../../../config/service/endpoint/teamCS';
import reportEndpoint from '../../../config/service/endpoint/report';
import { Table } from '../../../components/elements';
import { toRupiah } from '../../../helpers';
import { Select } from '../../../components/core';

const MonthlyOrder = () => {
  const [productId, setProductId] = useState('');
  const [csId, setCsId] = useState('');

  const { isFetching: loadingProducts, data: products } = useQuery('products', () => productEndpoint.getAll({ limit: 1000 }), { onError: (error) => toast.error(error.message) });
  const { isFetching: loadingTeamCS, data: teamCS } = useQuery('team-cs', () => teamCSEndpoint.getAll({ limit: 1000 }), { onError: (error) => toast.error(error.message) });
  const { isFetching: loadingOrders, data: orders, refetch: orderRefetch } = useQuery('report-monthly-order', () => reportEndpoint.getMonthlyOrder({
    product_id: productId,
    cs_id: csId,
  }), {
    onError: (error) => toast.error(error.message),
  });

  useEffect(() => {
    orderRefetch();
  }, [productId, csId]);

  const newListProducts = [
    { value: '', label: 'Semua Produk' },
    ...(products ? products.data.products.map((product) => ({ value: product.id, label: product.product_name })) : []),
  ];

  const newListTeamCS = [
    { value: '', label: 'Semua CS' },
    ...(teamCS ? teamCS.data.teamcs.map((cs) => ({ value: cs.id, label: cs.name })) : []),
  ];

  return (
    <div className="card">
      <div className="card-header">
        <div className="d-flex">
          <Select
            placeholder={loadingProducts ? 'Loading...' : ''}
            classNameContainer="mr-3 mb-0"
            data={newListProducts}
            value={productId}
            onChange={(event) => setProductId(event.target.value)}
          />
          <Select
            placeholder={loadingTeamCS ? 'Loading...' : ''}
            classNameContainer="mb-0"
            data={newListTeamCS}
            value={csId}
            onChange={(event) => setCsId(event.target.value)}
          />
        </div>
      </div>
      <div className="card-body">
        <Table
          isLoading={loadingOrders}
          totalData={orders?.data?.length}
          perPage={orders?.data?.length}
          headRender={(
            <tr>
              <th>Waktu</th>
              <th>Lead</th>
              <th>Order</th>
              <th>Closing Rate</th>
              <th>Omset</th>
            </tr>
          )}
          bodyRender={orders?.data?.map((order) => (
            <tr key={order.id}>
              <td>{moment(order.date).format('DD-MM-YYYY')}</td>
              <td>{order.lead}</td>
              <td>{order.order}</td>
              <td>{order.closing_rate}</td>
              <td>{toRupiah(order.omset)}</td>
            </tr>
          ))}
        />
      </div>
    </div>
  );
};

export default MonthlyOrder;
