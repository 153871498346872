import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import Form from './components/Form';
import FormLoadingSkeleton from './components/FormLoadingSkeleton';
import productEndpoint from '../../config/service/endpoint/product';
import { Button } from '../../components/core';

const EditProductPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    product_name: '',
    category_id: '',
    price: '',
    sku: '',
  });

  const { isFetching } = useQuery(`product-${params.id}`, () => productEndpoint.getById(params.id), {
    onError: (error) => {
      toast.error(error.message);
      navigate('/product');
    },
    onSuccess: (data) => {
      const { data: productData } = data;
      setInitialValues({
        product_name: productData.product_name,
        category_id: productData.category_id,
        price: productData.price,
        sku: productData.sku,
      });
    },
  });

  const { mutate, isLoading } = useMutation(productEndpoint.update, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil mengubah produk.');
      navigate('/product');
    },
  });

  const handleUpdate = (values) => {
    const payload = {
      product_name: values.product_name,
      category_id: values.category_id,
      price: values.price,
      sku: values.sku,
    };
    mutate({
      id: params.id,
      payload,
    });
  };

  return (
    <>
      <h4 className="mb-4">Product | Edit Produk</h4>
      <div className="card">
        <div className="card-header">
          <Link to="/product">
            <Button variant="secondary">
              <i className="uil-arrow-left mr-1" />
              Kembali
            </Button>
          </Link>
        </div>
        <div className="card-body">
          {isFetching ? <FormLoadingSkeleton /> : (
            <Form
              isLoading={isLoading}
              initialValues={initialValues}
              onSubmit={handleUpdate}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EditProductPage;
