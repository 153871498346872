import React from 'react';
import Skeleton from 'react-loading-skeleton';

const LoadingSkeleton = () => (
  <>
    <div className="row mt-4">
      <div className="col-md-6">
        <Skeleton height={300} />
      </div>
    </div>
    <Skeleton width={100} height={40} className="mt-3" />
  </>
);

export default LoadingSkeleton;
