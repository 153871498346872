import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({
  id, name, label, className, error, checked, onChange, onBlur,
}) => (
  <div className={`custom-control custom-checkbox ${className}`}>
    <input name={name} type="checkbox" onChange={onChange} onBlur={onBlur} className="custom-control-input" id={id} checked={checked} />
    <label className="custom-control-label" htmlFor={id}>{label}</label>
    {error && <small className="text-danger mt-1 d-block">{error}</small>}
  </div>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

Checkbox.defaultProps = {
  label: '',
  name: '',
  className: '',
  error: '',
  checked: false,
  onChange: () => {},
  onBlur: () => {},
};

export default Checkbox;
