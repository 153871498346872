import React from 'react';
import toast from 'react-hot-toast';
import CopyToClipboard from 'react-copy-to-clipboard';
import nl2br from 'react-nl2br';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import DetailLoadingSkeleton from './components/DetailSkeletonLoading';
import templateEndpoint from '../../config/service/endpoint/template';
import { TEMPLATE_NAME } from '../../helpers';

const DetailTemplatePage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { isFetching, data: contents } = useQuery(`content-${params.id}`, () => templateEndpoint.getById(params.id), {
    onError: (error) => {
      toast.error(error.message);
      navigate('/template');
    },
  });

  if (isFetching) return <DetailLoadingSkeleton />;

  return (
    <>
      <h4 className="mb-4">Template | Detail</h4>
      <div className="row">
        {contents?.data?.length <= 0 && (
          <div className="col-md-12">
            <div className="alert alert-warning">Tidak ada template.</div>
          </div>
        )}
        {contents?.data?.map((content) => (
          <div key={content.id} className="col-12">
            <div className="card">
              <div className="card-body">
                <strong>{TEMPLATE_NAME[content.name]}</strong>
                <div className="p-3 mt-3 rounded-lg" style={{ backgroundColor: '#FFF3CF' }}>
                  {nl2br(content.content)}
                  <div className="d-flex justify-content-end mt-3">
                    <CopyToClipboard text={content.content} onCopy={() => toast.success('Berhasil menyalin')}>
                      <i className="uil-copy" style={{ fontSize: 20, cursor: 'pointer' }} />
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default DetailTemplatePage;
